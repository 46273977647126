import React from 'react';
import Template from '../../template'


export class SheetTemplate extends React.Component {
    render() {
        return (
            <Template type={'sheet'} id={this.props.match.params['id']}/>
        )
    }
}

export class CellTemplate extends React.Component {
    render() {
        return (
            <Template type={'cell'} id={this.props.match.params['id']}/>
        )
    }
}

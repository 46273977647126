import React from 'react';
import styles from "./styles.module.scss";

class Dialog extends React.Component{

    render() {
        return !this.props.shown ? null : <div className={styles.modal} onMouseDown={this.handleOutsideClick} data-close-modal={"true"}>
            <div className={styles.modalContent} onKeyDown={this.handleKeyDown}>
                <div className={styles.modalTitle}><span>{this.props.title}</span>
                <span className={styles.closeButton} onClick={this.handleClose}>×</span></div>
                <div className={styles.modalChildren}>
                {this.props.children}
                </div>
                <div className={styles.modalButtons}>
                    {this.props.buttons.map((btn, i) => btn && <button key={i} className={styles.button} onClick={this.handleClick(btn.data)}
                    autoFocus={!!btn.autofocus} disabled={!!btn.disabled}>
                        {btn.text}</button>)}
                </div>
            </div>
        </div>
    }

    handleClick = data => event => {
        this.props.onClose(data);
    };

    handleClose = event => {
        this.props.onClose();
    };

    handleOutsideClick = event => {
        if (event.button === 0 && event.target.getAttribute('data-close-modal')) {
            this.props.onClose();
        }
    };

    handleKeyDown = event => {
        if (event.keyCode === 27) {
            this.props.onClose();
        }
    };
}


export default Dialog;

import * as qs from "qs";

export const DEFAULT_SEARCH = {
    query: '',
    grade_from: '',
    grade_to: '',
    year_from: '',
    year_to: '',
    difficulty_from: '',
    difficulty_to: '',
    authors: '',
    labels: '',
    sources: '',
    directory: '',
    labels_or: '',
    with_answer: '',
    order: '',
};

const fillParams = (params, result) => {
    for (let param in DEFAULT_SEARCH) {
        if ((param === 'query' || params[param]) && param !== 'directory') {
            result[param] = params[param];
        }
    }
    return result;
};

export const buildSearchParams = (type, page, params) => {
    return fillParams(params, {page});
};

export const buildSearchUrl = (params, searchMode) => {
    if (!searchMode) {
        return params.directory ? (params.directory + '/') : '';
    }
    return (params.directory ? params.directory + '/?' : '?') + qs.stringify(fillParams(params, {}));
};


export const areParamsSame = (params1, params2) => {
    if (params1.searchMode !== params2.searchMode) {
        return false;
    }
    for (let param in DEFAULT_SEARCH) {
        if ((params1[param] || params2[param]) && params1[param] !== params2[param]) {
            return false;
        }
    }
    return true;
};

export const parseSearchParams = url => {
    const q = qs.parse(url.search.substr(1));
    let res = {};
    for (let param in DEFAULT_SEARCH) {
        res[param] = q[param] || '';
    }
    res.directory = url.pathname.substr(3).replace('/', '');
    res.searchMode = 'query' in q;
    res.order = res.order || 'time_desc';
    return res;
};

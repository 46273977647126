import React from 'react';
import styles from './styles.module.scss';
import {Link} from "react-router-dom";

export class Footer extends React.Component {
    render() {
        return <div className={styles.footer}>
            <Link to={'/about'} className={styles.about}>
                О НАС
            </Link>
        </div>
    }
}

import styles from "../styles.module.scss";
import http from "utils/axios";
import React, {useState} from "react";

const ForgotPasswordBox = () => {
    const [username, setUsername] = useState("");
    const [error, setError] = useState({});
    const [success, setSuccess] = useState(false);

    const recoverPassword = e => {
        setError({});
        e.preventDefault();
        http.post('auth/recoverPassword', {username}).then(
            () => setSuccess(true)
        ).catch(
            err => setError(err.response.data)
        )
    };

    return <div className={styles.innerContainer}>
      <div className={styles.header}>
      Восстановление пароля
      </div>
        {success ? <p>Ссылка для восстановления пароля отправлена на почту</p> :
            <form className={styles.box}>
                <div className={styles.inputGroup}>
                    <label>Имя пользователя или email</label>
                    <input type="text" name="username" value={username} className={styles.loginInput}
                           onChange={e => setUsername(e.target.value)}/>
                    {error.username && <small className={styles.dangerError}>{error.username[0]}</small>}
                </div>
                <button type="submit" className={styles.loginBtn} onClick={recoverPassword}>Восстановить пароль</button>
            </form>
        }
    </div>
};

export default ForgotPasswordBox;

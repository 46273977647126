import React from 'react';
import columnStyles from '../columns.module.scss';
import http from '../../../utils/axios';
import {withRouter} from 'react-router';
import ProblemForm from "../../problemForm";

const DIRECTORY_FORM_CONFIG = {
    solution: {},
    author: {},
    email: {},
    grade: {},
    difficulty: {label: "Предполагаемый номер задачи"},
    labels: {},
};

class DirectoryForm extends React.Component {
    state = {
        fixedTitle: undefined,
        error: {},
        dirname: '',
        disclaimer: '',
        isLoading: true,
        isError: false,
    };

    loadForm = () => {
        this.setState({isLoading: true});
        http.get(`forms/${this.props.match.params.token}/`)
            .then(({data}) => this.setState({isLoading: false, dirname: data.name, disclaimer: data.disclaimer, fixedTitle: data.title}))
            .catch(() => this.setState({isLoading: false, isError: true}));
    };

    componentDidMount() {
        this.loadForm();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.token !== this.props.match.params.token) {
            this.loadForm();
        }
    }

    submitForm = (formData) => {
        http.post(`forms/${this.props.match.params.token}/submit`, {...formData, labels: undefined})
            .then(({data}) => {
                if (!formData.labels) {
                    return data;
                }
                return http.post(`problems/${data.id}/setTags`, {labels: formData.labels.join(",")})
                    .then(() => data);
            })
            .then(data => {this.props.history.push('/problem/' + data.id)})
            .catch(err => {
                this.setState({error: err.response.data});
            });
    };

    render() {
        return (
            <div className={columnStyles.mainWrapper}>
                <div className={columnStyles.leftColumn}/>
                <div className={columnStyles.centralWrapper}>
                    {this.state.isLoading ? <p>Загрузка...</p> :
                        this.state.isError ? <p>Такой формы не существует</p> :
                            <ProblemForm dirname={this.state.dirname} disclaimer={this.state.disclaimer}
                                         fixedTitle={this.state.fixedTitle} error={this.state.error}
                                         onSubmit={this.submitForm} config={DIRECTORY_FORM_CONFIG}/>
                    }
                </div>
                <div className={columnStyles.rightColumn}/>
            </div>);
    }
}

export default withRouter(DirectoryForm);

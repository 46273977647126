import React from 'react';
import HideIconImage from 'assets/media/hide_solution_icon.png';
import ShowIconImage from 'assets/media/show_solution_icon.png';
import styles from './styles.module.scss';
import classnames from "../../utils/classnames";
import {connect} from "react-redux";

@connect(
    state => ({forceOpenNodes: state.main.forceOpenNodes}),
    dispatch => ({dispatch}),
)
class Tree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isOpen: props.isOpen || false};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.forceOpenNodes.nodes.includes(this.props.data.id) &&
            this.props.forceOpenNodes.version > prevProps.forceOpenNodes.version) {
            this.setState({isOpen: true});
        }
    }

    toggleOpen = () => {
        if (!this.state.isOpen && this.props.onOpen) {
            this.props.onOpen();
        }
        this.setState({isOpen: !this.state.isOpen});
    };

    select = () => {
        if (!this.state.isOpen && this.props.onOpen) {
            this.props.onOpen();
        }
        if (this.props.onSelected) {
            this.props.onSelected();
        }
        this.setState({isOpen: true});
    };

    render() {
        return <div>
            <div className={styles.treeHeader}><div className={styles.openIcon} onClick={this.toggleOpen}>
                {React.Children.count(this.props.children) > 0 && (this.state.isOpen ?
                <img src={HideIconImage} alt={""} width={24} height={8}/> :
                <img src={ShowIconImage} alt={""} width={8} height={24}/>)
            }</div>
                    <span className={classnames(styles.treeContent, this.props.selected && styles.selectedContent)}
                     onClick={this.select}>{this.props.content}</span>
            </div>
            <div className={styles.treeChildren} hidden={!this.state.isOpen}>
            {this.props.children}
            </div>
        </div>
    }
}

export {Tree};

const buildTreeNode = (item, dirs, treeState, onDataNeeded, onSelect) => {
    const nodeData = {id: item.id};
    return <Tree content={item.name} isOpen={!!item.children} onOpen={item.children ? null : onDataNeeded(item.id)}
            onSelected={onSelect(item.id)} selected={treeState.selected === item.id} key={item.id} data={nodeData}>
        {item.children ? item.children.map(c => buildTreeNode(dirs[c], dirs, treeState, onDataNeeded, onSelect)) : "..."}
    </Tree>;
};

export const buildTree = (data, treeState, onDataNeeded, onSelected) => {
    const dataCallback = id => () => onDataNeeded(id);
    const selectCallback = id => () => onSelected(id);
    const treeStateExplicitSelection = {...treeState, selected: treeState.selected || data.public || ""};
    return <>
        {data.public && <Tree content={"Публичная база"} isOpen={true} selected={treeStateExplicitSelection.selected === data.public}
            onSelected={selectCallback(data.public)} key={"public"}
            data={{id: data.public}}>
            {data.dirs[data.public].children.map(d => buildTreeNode(data.dirs[d], data.dirs, treeStateExplicitSelection, dataCallback, selectCallback))}
        </Tree>}
        {data.my && <Tree content={"Мои задачи"} isOpen={true} selected={treeStateExplicitSelection.selected === data.my}
            onSelected={selectCallback(data.my)} key={"my"}
            data={{id: data.my}}>
            {data.dirs[data.my].children.map(d => buildTreeNode(data.dirs[d], data.dirs, treeStateExplicitSelection, dataCallback, selectCallback))}
        </Tree>}
        {data.bookmarks && treeState.authorized && <Tree content={"Закладки"} isOpen={true} key={"bookmarks"}
            data={{id: null}}>
            {data.bookmarks.length === 0 ? "Нет закладок" :
                data.bookmarks.map(d => buildTreeNode(data.dirs[d], data.dirs, treeStateExplicitSelection, dataCallback, selectCallback))}
        </Tree>}
        {data.available && data.available.length > 0 && <Tree content={"Доступные"} isOpen={true} key={"available"}
            data={{id: null}}>
            {data.available.map(d => buildTreeNode(data.dirs[d], data.dirs, treeStateExplicitSelection, dataCallback, selectCallback))}
        </Tree>}
    </>;
};

const isSelectionReachableInNode = (item, dirs, selection) => {
    if (item.id === selection) {
        return true;
    }
    if (item.children) {
        return item.children.some(c => isSelectionReachableInNode(dirs[c], dirs, selection));
    }
    return false;
};

export const isSelectionReachable = (data, treeState) => {
    let result = false;
    const selected = treeState.selected || data.public || "";
    if (data.public) {
        result = result || isSelectionReachableInNode(data.dirs[data.public], data.dirs, selected);
    }
    if (data.my) {
        result = result || isSelectionReachableInNode(data.dirs[data.my], data.dirs, selected);
    }
    if (data.bookmarks && treeState.authorized) {
        result = result || data.bookmarks.some(c => isSelectionReachableInNode(data.dirs[c], data.dirs, selected));
    }
    if (data.available) {
        result = result || data.available.some(c => isSelectionReachableInNode(data.dirs[c], data.dirs, selected));
    }
    return result;
};

import React, {useCallback, useEffect, useState} from "react";
import confirmEditingIconImage from 'assets/media/confirm_editing_icon.svg';
import rejectEditingIconImage from 'assets/media/reject_editing_icon.svg';
import {useLocation} from "react-router";
import * as qs from "qs";
import tableStyles from "../../table.module.scss";
import {Link} from "react-router-dom";
import http from "../../../../../utils/axios";
import styles from "./styles.module.scss";
import {useSelector} from "react-redux";

const Pages = ({pages, pageNum}) => {
    if (pages === 1) {
        return <p/>;
    }
    return <p className={styles.pageLinks}>Страницы:&nbsp;
        {pageNum === 1 ? <span>{"<"}</span> : <Link to={`?page=${pageNum - 1}`}>{"<"}</Link>}
        &nbsp;
        {[...new Array(pages)].map((x, i) =>
            pageNum === i + 1 ? <span key={i}>{i + 1}</span> : <Link key={i} to={`?page=${i + 1}`}>{i + 1}</Link>
        )}
        &nbsp;
        {pageNum === pages ? <span>{">"}</span> : <Link to={`?page=${pageNum + 1}`}>{">"}</Link>}
    </p>
}

const EditablePoints = ({points, maxPoints, onChange}) => {
    const [editValue, setEditValue] = useState(null);
    const onInput = useCallback(e => setEditValue(e.target.value), []);
    const handleClick = useCallback(e => {
        e.preventDefault();
        setEditValue(points);
    }, [points]);
    const accept = () => onChange(editValue).then(() => setEditValue(null));
    const reject = () => setEditValue(null);
    const onKeyDown = e => {
        if (e.keyCode === 27) {  // esc
            reject();
        }
        if (e.keyCode === 13) {  // enter
            accept();
        }
    };
    if (editValue !== null) {
        return <span className={styles.editContainer}>
            <input type="number" value={editValue} min={0} max={maxPoints} onChange={onInput}
                onKeyDown={onKeyDown} autoFocus={true}/>
            <img alt="confirm" src={confirmEditingIconImage} className={styles.editIcon} onClick={accept}/>
            <img alt="cancel" src={rejectEditingIconImage} className={styles.editIcon} onClick={reject}/>
        </span>
    }
    return <a href="" onClick={handleClick}>{points}</a>
}

const ContestSubmissions = ({group, contest}) => {
    const location = useLocation();
    const pageNum = +qs.parse(location.search.substr(1)).page || 1;
    const [data, setData] = useState({submissions: [], pages: 1});
    const [curPage, setCurPage] = useState(null);
    const problems = useSelector(state => {
        const d = {};
        for(const p of state.contest.contest.problems) {
            d[p.number] = p;
        }
        return d;
    });
    useEffect(() => {
        if (pageNum === curPage) {
            return;
        }
        setCurPage(pageNum);
        http.get(`contests/groups/${group}/contests/${contest}/submissions/?page=${pageNum}`)
            .then(({data}) => setData(data));
    }, [pageNum, curPage]);
    const changePoints = (id, points) => {
        return http.patch(`contests/groups/${group}/contests/${contest}/submissions/${id}`, {points})
            .then(resp => setData(data => ({
                pages: data.pages,
                submissions: data.submissions.map(s => s.id === id ? resp.data : s),
            })));
    };
    return <>
        <Pages pages={data.pages} pageNum={pageNum}/>
        <table className={tableStyles.table}>
        <thead><tr>
            <th>Дата</th>
            <th>Участник</th>
            <th>Задача</th>
            <th>Ответ</th>
            <th className={styles.pointsHeader}>Баллы</th>
        </tr></thead>
        <tbody>
        {data.submissions.map(x => <tr key={x.id}>
            <td>{new Date(x.time).toLocaleString()}</td>
            <td><Link to={`/user/${x.author.username}`}>{x.author.first_name} {x.author.last_name}</Link></td>
            <td>{x.problem_number}</td>
            <td>{x.text}</td>
            <td><EditablePoints points={x.points} maxPoints={problems[x.problem_number].max_points}
                onChange={p => changePoints(x.id, p)}/></td>
        </tr>)}
        </tbody>
    </table>
    <Pages pages={data.pages} pageNum={pageNum}/>
    </>;
};

export default ContestSubmissions;

import React from 'react';

import styles from '../styles.module.scss';
import {connect} from "react-redux";
import {performLogin} from "../../../../reducers/auth";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";


@connect(
    state => state.auth,
    dispatch => ({
        doLogin(username, password, remember) {
            return performLogin(dispatch, username, password, remember);
        }
    })
)
class LoginBox extends React.Component {

    constructor(props) {
      super(props);
      this.state = {username: "", password: "", remember: false, error: null};
    }
    
    showValidationErr = (msg) => {
      this.setState({error: msg});
    };
  
    submitLogin = (e) => {
      e.preventDefault();
      if (!this.state.username || !this.state.password) {
          return;
      }
      this.showValidationErr(null);
    
      this.props.doLogin(this.state.username, this.state.password, this.state.remember)
          .then(() => {
              const search = this.props.location.search;
              let next = "/";
              if (search.startsWith('?next=')) {
                  next = decodeURIComponent(search.substring(6));
              }
              this.props.history.push(next);
          })
          .catch(() => {
              this.showValidationErr("Неправильный логин или пароль");
          });
      return true;
    };

    onUsernameChange = (e) => {
      this.setState({
        username: e.target.value
      });
    };
  
    onPasswordChange = (e) => {
      this.setState({
        password: e.target.value
      });
    };

    onRememberChange = (e) => {
        this.setState({remember: e.target.checked});
    };

    render() {
      return (
        <div className={styles.innerContainer}>
          <div className={styles.header}>
          Войти
          </div>
          <form className={styles.box}>
              <span className={styles.dangerErrorGlobal}>{this.state.error || ""}</span>
          <div className={styles.inputGroup}>
              <label>Имя пользователя</label>
              <input type="text" name="username" className={styles.loginInput} onChange={this.onUsernameChange}/>
            </div>
            <div className={styles.inputGroup}>
              <label>Пароль</label>
              <input type="password" name="password" className={styles.loginInput} onChange={this.onPasswordChange}/>
            </div>
              <label className={styles.rememberMe}>
                  <input type="checkbox" checked={this.state.remember} onChange={this.onRememberChange}/> запомнить меня
              </label>
            <button type="submit" className={styles.loginBtn} onClick={this.submitLogin}>Войти</button>
          </form>
            <Link className={styles.forgotPasswordLink} to={"/forgotPassword"}>не помню пароль</Link>
        </div>
      );
    }
}
  
export default withRouter(LoginBox);

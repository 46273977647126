import React from 'react';
import columnStyles from '../columns.module.scss';
import http from '../../../utils/axios';
import {Redirect, withRouter} from 'react-router';
import {connect} from "react-redux";
import {clearNeighborCache} from "../../../reducers/task";
import ProblemForm from "../../problemForm";


const CREATE_PROBLEM_CONFIG = {
    answer: {},
    solution: {},
    source: {},
    grade: {},
    difficulty: {},
    year: {},
    labels: {},
};

@connect(
    store => ({isLogged: store.auth.authorized})
)
class CreateProblem extends React.Component {
    state = {
        fixedTitle: undefined,
        dirname: undefined,
        error: {},
    };

    componentDidMount() {
        const dir = this.props.location.pathname.split('/').pop();
        http.get(`tree/${dir}/createProblem`).then(({data}) => {
            this.setState({fixedTitle: data.title, dirname: data.directory});
        })
    }

    handleCreateClick = (formData) => {
        const dir = this.props.location.pathname.split('/').pop();
        http.post(`/tree/${dir}/${this.props.suggest ? 'suggestProblem' : 'createProblem'}`,
            {...formData, labels: undefined})
            .then(({data}) => {
                if (!formData.labels) {
                    return data;
                }
                return http.post(`problems/${data.id}/setTags`, {labels: formData.labels.join(",")})
                    .then(() => data);
            })
            .then(data => {
                clearNeighborCache(this.props.dispatch);
                this.props.history.push('/problem/' + data.id);
            })
            .catch((error) => {
                let errorStatus = error.response.status;
                if (errorStatus === 400) {
                    this.setState({error: error.response.data});
                }
            })
    };

    render() {
        if (this.props.isLogged === false) {
            return <Redirect to={'/login'}/>;
        }
        return (
            <div className={columnStyles.mainWrapper}>
                <div className={columnStyles.leftColumn}/>
                <div className={columnStyles.centralWrapper}>
                    <ProblemForm dirname={this.state.dirname} fixedTitle={this.state.fixedTitle}
                                 error={this.state.error} onSubmit={this.handleCreateClick}
                                 config={CREATE_PROBLEM_CONFIG}/>
                </div>
                <div className={columnStyles.rightColumn}/>
            </div>);
    }
}

export default withRouter(CreateProblem);

import http from "../utils/axios";
import {timeIn} from "../utils/timeIn";

const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';
const SET_LOGGED_OUT_DIALOG = 'auth/SET_LOGGED_OUT_DIALOG';
export const ON_USER_CHANGED = 'auth/ON_USER_CHANGED';


export const fetchCurrentUser = (dispatch, showDialogIfExpired) => {
    return http.get('auth/test')
        .then(({data}) => {
            dispatch({type: SET_CURRENT_USER, payload: {...data, showDialogIfExpired}});
            return data.username;
        })
};

export const checkSessionExpiration = dispatch => {
    fetchCurrentUser(dispatch, true);
};

export const setLoggedOutDialog = (dispatch, show) => {
    dispatch({type: SET_LOGGED_OUT_DIALOG, payload: show});
};

export const performLogin = (dispatch, username, password, remember) => {
    return http.post('auth/login', {username, password, remember})
      .then(({data}) => {
          localStorage.setItem('sessionExpires', timeIn(data.expires_in - 180).toISOString());
          dispatch({type: ON_USER_CHANGED});
          return fetchCurrentUser(dispatch);
      });
};

export const performRegister = (dispatch, params) => {
    return http.post('auth/register', params)
      .then(({data}) => {
          localStorage.setItem('sessionExpires', timeIn(data.expires_in - 180).toISOString());
          dispatch({type: ON_USER_CHANGED});
          return fetchCurrentUser(dispatch);
      });
};

export const performLogout = (dispatch) => {
    return http.post('auth/logout')
      .then(() => {
          dispatch({type: ON_USER_CHANGED});
          return fetchCurrentUser(dispatch);
      });
};

const authReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                username: action.payload.username,
                avatar: action.payload.avatar,
                authorized: action.payload.username !== null || (!!state.authorized && !!action.payload.showDialogIfExpired),
                loggedOutDialog: (action.payload.showDialogIfExpired && !action.payload.username && state.username) || state.loggedOutDialog,
            };
        case SET_LOGGED_OUT_DIALOG:
            return {
                ...state,
                loggedOutDialog: action.payload,
            };
        default:
            return state;
    }
};

export default authReducer;

import React, {useState, useEffect} from "react";
import styles from "../problemForm/styles.module.scss";
import TextAreaAutoSize from "react-textarea-autosize";
import Select from "react-dropdown-select"
import {useDispatch, useSelector} from "react-redux";
import {loadTemplateList} from "../../reducers/templateList";
import {Checkbox} from '@material-ui/core';
import templateStyles from "./styles.module.scss"
import {Link} from "react-router-dom";

const firstItem = s => s ? s.length ? s[0] : null : null;

const TemplateForm = ({initialState, type, id, onSubmit, error}) => {
    const templateList = useSelector(state => state.templateList)
    const dispatch = useDispatch()

    const [hasChanges, setChanges] = useState(false)
    const [data, setData] = useState(initialState ? initialState : {});
    const setField = name => event => {
        setChanges(true)
        setData({...data, [name]: event.target.value});
    }

    if (type === 'sheet') {
        useEffect(() => {
            loadTemplateList(dispatch,'cell')
        }, [id])
    }

    const doSubmit = () => {
        onSubmit(data)
        setChanges(false)
    }

    const onCellTemplateSelect = (values) => {
        setChanges(true)
        const item = firstItem(values)

        setData({
            ...data,
            cell_template: item ? item.id : null,
        })
    }

    const onRawFlagChange = event => {
        setChanges(true)
        setData({...data, raw: event.target.checked})
    }

    const currentCellTemplateValues = () => {
        return templateList.cells.filter(cell => cell.id === data.cell_template)
    }

    const dropdownItemRenderer = (type) => ({ item, itemIndex, props, state, methods }) => {
        return <div className={state.values.filter(selected => selected.id === item.id).length === 1
            ? templateStyles.dropdownItemSelected
            : templateStyles.dropdownItem}
                    onClick={() => {
                        methods.clearAll()
                        methods.addItem(item)
                    }}
        >
            <Link to={`/templates/${type}/${item.id}`}>{item.title}</Link>
        </div>
    }

    return (
        <>
            <div className={styles.fieldContainer}>
                Название шаблона:
                <input className={styles.field} value={data.title || ""}
                       onChange={setField('title')}/>
            </div>
            {type === 'sheet' &&
                <div>
                    <label>
                        <Checkbox onChange={onRawFlagChange} checked={data.raw}/>
                        "Сырой" шаблон
                    </label>
                </div>
            }
            {type === 'sheet' && templateList.areCellsLoading === false &&
                <div>
                    Шаблон задач:
                    <Select options={templateList.cells} labelField="title" valueField="id" closeOnSelect={true}
                            sortBy="title" searchable={false} placeholder="Выберите шаблон..."
                            clearable={true}
                            onChange={onCellTemplateSelect} values={currentCellTemplateValues()}
                            noDataRenderer={({ props, state, methods }) => <div className={templateStyles.dropdownNoData}>
                                Нет доступных шаблонов
                            </div>}
                            itemRenderer={dropdownItemRenderer("cell")}
                    />
                    <small className={styles.dangerError}>{firstItem(error.cell_template)}</small>
                </div>
            }
            {(type === 'cell' || data.raw !== true) &&
            <div className={styles.fieldContainer}>
                Преамбула:
                <TextAreaAutoSize className={styles.field} value={data.preamble || ""}
                                  onChange={setField('preamble')}/>
                <small className={styles.dangerError}>{firstItem(error.preamble)}</small>
            </div>}
            <div className={styles.fieldContainer}>
                Шаблон:
                <TextAreaAutoSize className={styles.field} value={data.text || ""}
                                  onChange={setField('text')}/>
                <small className={styles.dangerError}>{firstItem(error.text)}</small>
            </div>
            <button className={templateStyles.saveButton} onClick={doSubmit} disabled={!hasChanges}>Сохранить</button>
        </>
    );
}

export default TemplateForm;
import styles from './styles.module.scss';
import React from 'react';
import miptLogo from '../../../assets/media/mipt_rus_text.png';
import fpmiLogo from '../../../assets/media/fpmi_logo.png';
import fckLogo from '../../../assets/media/fck_logo.png';
import ilabLogo from '../../../assets/media/ilab_logo.jpeg';


const About = () => {
    return <div className={styles.aboutPage}>
            <h1>О нас</h1>
        <p className={styles.aboutText}>Наш проект Mathmaker – это удобная база математических задач с некоторыми дополнительными возможностями.
            Здесь вы сможете хранить свои задачи и быстро искать нужные, настраивать к ним ограниченный доступ и создавать листочки.
            Проект предназначен для преподавателей олимпиадной математики, для школьных учителей и для всех,
            кто хоть раз сталкивался с проблемой отсутствия удобного места для хранения большого количества задач.</p>
        <h1>Наши партнеры</h1>
        <div className={styles.logosContainer}>
            <img alt="МФТИ" src={miptLogo} width={250} height={112}/>
            <img alt="ФПМИ" src={fpmiLogo} width={253} height={50}/>
            <img alt="Фонд целевого капитала МФТИ" src={fckLogo} width={281} height={139}/>
            <img alt="Лаборатория инноватики" src={ilabLogo} width={200} height={53}/>
        </div>
        </div>
};

export default About;

import React, {useEffect, useState} from "react";
import http from '../../../../../utils/axios';
import styles from './styles.module.scss';
import tableStyles from '../../table.module.scss';
import {Link} from "react-router-dom";


const ContestStandings = ({group, contest}) => {
    const [standings, setStandings] = useState({});
    const [error, setError] = useState(false);
    useEffect(() => {
        setError(false);
        http.get(`contests/groups/${group}/contests/${contest}/standings`)
            .then(({data}) => setStandings(data))
            .catch(() => setError(true));
    }, [group, contest]);
    if (error) {
        return <p>Положение недоступно</p>;
    }
    if (!standings.problems) {
        return <p>Загрузка...</p>;
    }
    return <div className={styles.standingsContainer}><table className={tableStyles.table}>
        <thead><tr>
            <th>№</th>
            <th className={tableStyles.alignLeft}>Участник</th>
            <th>Сумма</th>
            {standings.problems.map(x => <th key={x}>{x}</th>)}
        </tr></thead>
        <tbody>
        {standings.standings.map(x => <tr key={x.username}>
            <td>{x.rank}</td>
            <td className={tableStyles.alignLeft}><Link to={'/user/' + x.username}>{x.first_name} {x.last_name}</Link></td>
            <td>{x.sum}</td>
            {x.points.map((p, i) => <td key={i}>{p ?? "-"}</td>)}
        </tr>)}
        </tbody>
    </table></div>
};

export default ContestStandings;

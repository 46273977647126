import React, {useState} from "react";
import MenuIcon from "../../../../menuIcon";
import {Item, Menu} from "react-contexify";
import http from "../../../../../utils/axios";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {loadGroups} from "../../../../../reducers/contest";
import {GroupAclDialog, RenameGroupDialog} from "../../dialogs";

const GroupActions = ({groupId}) => {

    const [dialogShown, setDialogShown] = useState(null);
    const data = useSelector(state => state.contest.group);
    const history = useHistory();
    const dispatch = useDispatch();
    const handleDelete = () => {
        http.delete(`contests/groups/${groupId}`)
            .then(() => {
                loadGroups(dispatch);
                history.push('/contests/public/');
            });
    };

    return <>
        <MenuIcon menuId="contest_group_actions"/>
        <Menu id="contest_group_actions">
            <Item onClick={() => setDialogShown('rename')}>Переименовать</Item>
            {data.contests && data.contests.length === 0 && <Item onClick={handleDelete}>Удалить</Item>}
            {data.can_manage === 2 && <Item onClick={() => setDialogShown('acl')}>Права доступа</Item>}
        </Menu>
        {dialogShown === 'rename' && <RenameGroupDialog onClose={() => setDialogShown(null)}
                                                        groupId={groupId} name={data.name}/>}
        {dialogShown === 'acl' && <GroupAclDialog onClose={() => setDialogShown(null)} groupId={groupId}/>}
        </>;
};

export default GroupActions;

import {Link, useLocation} from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

export const LoginButton = () => {
    const location = useLocation();
    const next = location.pathname === '/login' ? location.pathname + location.search :
        '/login?next=' + encodeURIComponent(location.pathname + location.search);
    return <Link to={next} className={styles.loginButton}>Войти</Link>;
};

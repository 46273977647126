import Autosuggest from "react-autosuggest";
import React, {useCallback, useState} from "react";
import http from "../../utils/axios";

const AUTOSUGGEST_THEME = {
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 31,
    width: '100%',
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    zIndex: 2,
    boxSizing: 'border-box',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    overflow: 'hidden',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '5px 8px'
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd'
  }
};

const USER_AUTOSUGGEST_THEME = {
    ...AUTOSUGGEST_THEME,
    suggestionsContainerOpen: {...AUTOSUGGEST_THEME.suggestionsContainerOpen, top: 21},
    suggestion: {...AUTOSUGGEST_THEME.suggestion, padding: '3px 4px'},
};

export const LabelAutosuggest = ({inputProps, suggestions, fetchRequested, clearRequested}) => {
    return <Autosuggest inputProps={inputProps}
                    suggestions={suggestions}
                    renderSuggestion={(x) => `${x.label} (${x.count})`}
                    onSuggestionsFetchRequested={fetchRequested}
                    onSuggestionsClearRequested={clearRequested}
                    getSuggestionValue={x => x.label}
                    shouldRenderSuggestions={() => true}
                    theme={AUTOSUGGEST_THEME}/>;
};

export const UserAutosuggest = ({inputProps, onChange}) => {
    const [suggestions, setSuggestions] = useState([]);
    const clearSuggestions = useCallback(() => setSuggestions([]), []);
    const fetchSuggestions = useCallback(value => {
        http.get(`users/autocomplete?prefix=${encodeURIComponent(value.value)}`)
            .then(({data}) => setSuggestions(data));
    }, []);
    const handleChange = (e, data) => {
        if (data) {
            onChange(data.newValue);
        } else {
            onChange(e.target.value);
        }
    }
    return <Autosuggest inputProps={{...inputProps, onChange: handleChange}}
                    suggestions={suggestions}
                    renderSuggestion={x => x}
                    onSuggestionsFetchRequested={fetchSuggestions}
                    onSuggestionsClearRequested={clearSuggestions}
                    getSuggestionValue={x => x}
                    theme={USER_AUTOSUGGEST_THEME}/>;
};

import React from 'react';
import columnStyles from '../columns.module.scss';
import TaskContent from "./taskContent";
import TaskAttributes from "./taskAttribures/attributesView";
import TaskComments from "./taskComments";
import ProblemNeighbors from "./neighbors";


class Task extends React.Component {
    render() {
        return <div className={columnStyles.mainWrapper}>
            <div className={columnStyles.leftColumn}>
                <ProblemNeighbors id={this.props.match.params['id']}/>
            </div>
            <div className={columnStyles.centralWrapper}>
                <TaskContent id={this.props.match.params['id']}/>
                <TaskComments id={this.props.match.params['id']}/>
            </div>
            <div className={columnStyles.rightColumn}>
                <TaskAttributes id={this.props.match.params['id']}/>
            </div>
        </div>
    }
}

export default Task;

import React, {useState, useEffect} from 'react';
import styles from "./styles.module.scss";
import leftArrowImage from "assets/media/left_arrow.svg";
import folderImage from "assets/media/folder.svg";
import Dialog from "../../dialog";
import http from "../../../utils/axios";
import {updateDirs} from "../../../reducers/main";


const fetchData = (uuid, setData, setUuid, onChangeDir) => {
    if (uuid !== undefined) {
        http.get(`tree/${uuid}/children?parent`).then(({data}) => {
            setUuid(uuid);
            setData({parent: data.parent, dirs: updateDirs({}, data.dirs)});
            if (onChangeDir) {
                onChangeDir();
            }
        });
    }
};

const getDir = (uuid, data) => {
    if (uuid === undefined) {
        return {name: "...", children: null};
    }
    if (uuid === null) {
        return {name: "", children: null};
    }
    if (data.dirs && uuid in data.dirs) {
        return data.dirs[uuid];
    }
    return {name: "...", children: null};
};

export const MoveDialog = ({startId, onClose, error, onChangeDir, title, buttonText, allowStart, extraMarkup}) => {
    const [uuid, setUuid] = useState(undefined);
    const [data, setData] = useState({});
    useEffect(() => {
        setUuid(startId);
        setData({});
        fetchData(startId, setData, setUuid);
    }, [startId]);
    const dir = getDir(uuid, data);
    return <Dialog title={title || "Перемещение"} shown={true} onClose={onClose}
                   buttons={[{text: buttonText || 'Переместить сюда',
                       disabled: !uuid || (!allowStart && uuid === startId) || !dir.can_write, data: uuid},
                       {text: 'Закрыть'}]}>
        <div className={styles.header}> {dir.id === 'home' || <img src={leftArrowImage} title="Назад" alt="Назад" className={styles.backImage} width={16} height={16}
            onClick={() => fetchData(data.parent, setData, setUuid, onChangeDir)}/>}
            <b>{dir.name}</b></div>
        <div>
            {dir.children &&
                (dir.children.length ?
                    dir.children.map(id => <div className={styles.directory} key={id} onClick={() => fetchData(id, setData, setUuid, onChangeDir)}>
                        <img src={folderImage} width={16} height={16} alt="" /> {getDir(id, data).name}</div>) :
                    "")}
            <p className={styles.error}>{error}</p>
            {extraMarkup}
        </div>
    </Dialog>
};

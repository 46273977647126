import React from 'react';
import styles from './styles.module.scss';
import HideIconImage from 'assets/media/hide_solution_icon.png';

class HideSolution extends React.Component {
    render() {
        return <div className={styles.hideSolution}>
            <img src={HideIconImage} className={styles.hideIcon} alt='hide icon'/>
            Скрыть решение
        </div>
    }
}

export default HideSolution;
import React from "react";
import {connect} from "react-redux";
import styles from "./styles.module.scss";
import {withRouter} from "react-router";


const ORDERS = [
    ['time_asc', 'Создано ⇑'],
    ['time_desc', 'Создано ⇓'],
    ['title_asc', 'Название ⇑'],
    ['title_desc', 'Название ⇓'],
    ['grade_asc', 'Класс ⇑'],
    ['grade_desc', 'Класс ⇓'],
    ['difficulty_asc', 'Сложность ⇑'],
    ['difficulty_desc', 'Сложность ⇓'],
    ['year_asc', 'Год ⇑'],
    ['year_desc', 'Год ⇓'],
    ['comments_asc', 'Комментарии ⇑'],
    ['comments_desc', 'Комментарии ⇓'],
];

@connect(
    state => ({order: state.main.selectedQuery.order}),
)
class SearchOrder extends React.Component {

    handleChange = (event) => {
        this.props.updateOrder(event.target.value);
    };

    render() {
        return <div className={styles.orderBar}>
            Порядок сортировки: <select value={this.props.order} onChange={this.handleChange}>
            {ORDERS.map(([value, name]) => <option key={value} value={value}>{name}</option>)}
            </select>
        </div>
    }
}

export default withRouter(SearchOrder);

import React, {memo} from 'react';
import Linkify from 'react-linkify';


const componentDecorator = (href, text, key) => {
    return <a href={href} target="_blank" rel="noopener" key={key}>{text}</a>
};

const TextWithLinks = memo(({children}) => {
    return <Linkify componentDecorator={componentDecorator}>{children}</Linkify>;
});

export default TextWithLinks;

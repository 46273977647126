import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import DateTimePicker from 'react-datetime-picker';
import Dialog from "../../../dialog";
import styles from "../../../dialogs/styles.module.scss";
import {createGroup, editContest, renameGroup} from "../../../../reducers/contest";
import {useHistory} from "react-router";
import http from "../../../../utils/axios";
import {enterHandler} from "../../../dialogs";
import {Checkbox} from "@material-ui/core";
import {UserListDialog} from "./userListDialog";
import TextareaAutosize from "react-textarea-autosize";

export const CreateGroupDialog = ({onClose}) => {
    const [name, setName] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const handleCreate = (data) => {
        if (data) {
            createGroup(dispatch, name).then(id => {
                onClose(true);
                history.push(`/contests/${id}`);
            });
        } else {
            onClose(false);
        }
    };
    return <Dialog title="Создание группы соревнований" shown={true}
            onClose={handleCreate} buttons={[{text: 'Создать', data: true, disabled: !name.trim()}, {text: 'Отмена'}]}>
            Название:
            <input className={styles.dialogInput} value={name} autoFocus={true}
                   onKeyPress={enterHandler(handleCreate, true)}
                   onChange={e => setName(e.target.value)}/>
    </Dialog>
};

const toParticipantList = ({data}) => data.participants.map(x => x.username);

export const ContestParticipantsDialog = ({onClose, groupId, contestId}) => {
    const getList = useCallback(
        () => {
            return http.get(`contests/groups/${groupId}/contests/${contestId}/participants`)
                .then(toParticipantList);
        }, [groupId, contestId]
    )
    const onAdd = useCallback(
        user => {
            return http.post(`contests/groups/${groupId}/contests/${contestId}/participants/add`, {user})
                .then(toParticipantList);
        }, [groupId, contestId]
    )
    const onDelete = useCallback(
        user => {
            return http.post(`contests/groups/${groupId}/contests/${contestId}/participants/delete`, {user})
                .then(toParticipantList);
        }, [groupId, contestId]
    )
    return <UserListDialog onClose={onClose} getList={getList} onAdd={onAdd} onDelete={onDelete}
                           title="Участники соревнования" emptyText="Участников нет" nonEmptyText="Участники"/>
};

export const CreateEditContestDialog = ({onClose, groupId, contestId, defaults}) => {
    const [name, setName] = useState(contestId ? defaults.name : "");
    const [description, setDescription] = useState(contestId ? defaults.description : "");
    const [startTime, setStartTime] = useState((contestId && defaults.start_time) ? new Date(defaults.start_time) : null);
    const [duration, setDuration] = useState(contestId ? (defaults.duration ?? 120) : 120);
    const [infinite, setInfinite] = useState(contestId ? (defaults.duration === null) : false);
    const [closed, setClosed] = useState(contestId ? defaults.is_closed : false);
    const [showParticipants, setShowParticipants] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const handleCreate = (data) => {
        if (data) {
            if (contestId) {
                editContest(dispatch, groupId, contestId,
                    {name, description, start_time: startTime, duration: infinite ? null : duration, is_closed: closed})
                    .then(() => onClose(true));
            } else {
                http.post(`contests/groups/${groupId}/contests/`,
                    {name, description, start_time: startTime, duration: infinite ? null : duration, is_closed: closed})
                    .then(({data}) => {
                        onClose(true);
                        history.push(`/contests/${groupId}/${data.number}`);
                    });
            }
        } else {
            onClose(false);
        }
    };
    if (showParticipants) {
        return <ContestParticipantsDialog onClose={onClose} groupId={groupId} contestId={contestId}/>
    }
    return <Dialog title={contestId ? "Редактирование соревнования" : "Создание соревнования"} shown={true}
            onClose={handleCreate} buttons={[
                    {text: contestId ? 'Сохранить' : 'Создать', data: true, disabled: !name.trim()},
                    {text: 'Отмена'}
                ]}>
                <p className={styles.inputGroup}>
                Название <br/>
                <input className={styles.wideField} value={name} autoFocus={true}
                       onChange={e => setName(e.target.value)}/>
                </p>
                <p className={styles.inputGroup}>
                Описание <br/>
                <TextareaAutosize className={styles.dialogTextarea} value={description}
                       onChange={e => setDescription(e.target.value)}/>
                </p>
                <p className={styles.inputGroup}>
                Время начала <br/>
                <DateTimePicker value={startTime} onChange={setStartTime} disableClock={true} format="dd.MM.y HH:mm"
                                minDetail="year"/>
                </p>
                <p className={styles.inputGroup}>
                Продолжительность в минутах <br/>
                <input type="number" min={1} className={styles.field} value={duration} disabled={infinite}
                       onChange={e => setDuration(e.target.value)}/><br/>
                </p>
                <label className={styles.shrinkAbove}>
                            <Checkbox className={styles.checkBox} checked={!!infinite}
                          onChange={e => setInfinite(e.target.checked)}/>
                <span className={styles.checkboxLabel}>Бесконечное соревнование</span>
                </label>
                <label>
                        <Checkbox className={styles.checkBox} checked={!!closed}
                                  onChange={e => setClosed(e.target.checked)}/>
                        <span className={styles.checkboxLabel}>Ручное подтверждение участников</span>
                </label>
                {closed && !!contestId && <p className={styles.inputGroup}><a className={styles.link} onClick={e => {
                    e.preventDefault();
                    setShowParticipants(true);
                }} href=""> Список участников</a></p>}
    </Dialog>
};

export const PublishContestDialog = ({onClose, groupId, contestId}) => {
    const dispatch = useDispatch();
    const handleClose = (data) => {
        if (data) {
            editContest(dispatch, groupId, contestId, {visible: true})
                .then(() =>onClose(true));
        } else {
            onClose(false);
        }
    };
    return <Dialog title="Публикация соревнования" shown={true}
            onClose={handleClose} buttons={[{text: 'Да', data: true}, {text: 'Нет'}]}>
                <p>Вы уверены, что хотите опубликовать соревнование?</p>
    </Dialog>;
};

export const RenameGroupDialog = ({onClose, groupId, name}) => {
    const dispatch = useDispatch();
    const [newName, setNewName] = useState(name);
    const handleClose = data => {
        if (data) {
            renameGroup(dispatch, groupId, newName).then(() => onClose(true));
        } else {
            onClose(false);
        }
    };
    return <Dialog title="Переименование группы" shown={true} onClose={handleClose}
            buttons={[{text: 'Сохранить', data: true}, {text: 'Отмена'}]}>
            Новое имя:
            <input className={styles.dialogInput} value={newName} autoFocus={true}
                   onChange={e => setNewName(e.target.value)}
                   onKeyPress={enterHandler(handleClose, true)}/>
    </Dialog>;
};

export const GroupAclDialog = ({onClose, groupId}) => {
    const getList = useCallback(
        () => {
            return http.get(`contests/groups/${groupId}/managers/`)
                .then(({data}) => data.managers);
        }, [groupId]
    )
    const onAdd = useCallback(
        user => {
            return http.post(`contests/groups/${groupId}/managers/add`, {user})
                .then(({data}) => data.managers);
        }, [groupId]
    )
    const onDelete = useCallback(
        user => {
            return http.post(`contests/groups/${groupId}/managers/delete`, {user})
                .then(({data}) => data.managers);
        }, [groupId]
    )
    return <UserListDialog onClose={onClose} getList={getList} onAdd={onAdd} onDelete={onDelete}
                           title="Права доступа" emptyText="Менеджеров нет" nonEmptyText="Менеджеры"/>
};

export const ContestRestrictionsDialog = ({onClose, groupId, contestId}) => {
    const handleClose = data => {
        if (data) {
            http.post(`contests/groups/${groupId}/contests/${contestId}/restrictions`, state)
                .then(() => onClose(true));
        } else {
            onClose(false);
        }
    };
    const [state, setState] = useState({});
    useEffect(() => {
        http.get(`contests/groups/${groupId}/contests/${contestId}/restrictions`)
            .then(({data}) => setState(data));
    }, [groupId, contestId]);
    return <Dialog title="Ограничения" shown={true} onClose={handleClose}
            buttons={[{text: 'Сохранить', data: true}, {text: 'Отмена'}]}>
        <p className={styles.inputGroup}>
            Максимальное число посылок по задаче <br/>
            <input type="number" min={1} className={styles.field} value={state.max_submissions ?? ""}
                   onChange={e => setState({...state, max_submissions: e.target.value})}/>
        </p>
        <p className={styles.inputGroup}>
            Интервал между посылками, секунд <br/>
            <input type="number" min={0} className={styles.field} value={state.submission_interval ?? ""}
                   onChange={e => setState({...state, submission_interval: e.target.value})}/>
        </p>
        <label>
                <Checkbox className={styles.checkBox} checked={!!state.show_standings}
                          onChange={e => setState({...state, show_standings: e.target.checked})}/>
                <span className={styles.checkboxLabel}>Показывать положение участникам</span>
        </label>
    </Dialog>;
};

export const ProblemRestrictionsDialog = ({onClose, groupId, contestId, problemId}) => {
    const handleClose = data => {
        if (data) {
            http.post(`contests/groups/${groupId}/contests/${contestId}/problems/${problemId}/restrictions`,
                {...state, contest: undefined})
                .then(() => onClose(true));
        } else {
            onClose(false);
        }
    };
    const [state, setState] = useState({});
    useEffect(() => {
        http.get(`contests/groups/${groupId}/contests/${contestId}/problems/${problemId}/restrictions`)
            .then(({data}) => setState(data));
    }, [groupId, contestId, problemId]);
    return <Dialog title="Настройки задачи" shown={true} onClose={handleClose}
            buttons={[{text: 'Сохранить', data: true}, {text: 'Отмена'}]}>
        <p className={styles.inputGroup}>
            Баллы за задачу <br/>
            <input type="number" required min={0} className={styles.field} value={state.points ?? ""}
                   onChange={e => setState({...state, points: e.target.value})}/>
        </p>
        <p className={styles.inputGroup}>
            Максимальное число посылок по задаче <br/>
            <input type="number" min={1} className={styles.field} value={state.max_submissions ?? ""}
                   onChange={e => setState({...state, max_submissions: e.target.value})}/>
            {state.contest?.max_submissions !== undefined &&
            <small className={styles.note}>По умолчанию используется параметр соревнования ({state.contest.max_submissions})</small>}
        </p>
        <p className={styles.inputGroup}>
            Интервал между посылками, секунд <br/>
            <input type="number" min={0} className={styles.field} value={state.submission_interval ?? ""}
                   onChange={e => setState({...state, submission_interval: e.target.value})}/>
           {state.contest?.submission_interval !== undefined &&
            <small className={styles.note}>По умолчанию используется параметр соревнования ({state.contest.submission_interval})</small>}
        </p>
    </Dialog>;
};

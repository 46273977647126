import React, {useEffect, useState} from 'react';
import styles from "./styles.module.scss";
import TextWithLinks from "../../utils/TextWithLinks";
import TextAreaAutoSize from "react-textarea-autosize";
import {ListEditor} from "../listEditor";

const firstItem = s => s ? s[0] : null;

const FORM_DATA_KEY = 'directoryFormData';

const ProblemForm = ({dirname, disclaimer, fixedTitle, error, onSubmit, config}) => {
    const [data, setData] = useState({});
    useEffect(() => {
        const stored = JSON.parse(sessionStorage.getItem(FORM_DATA_KEY) || "{}");
        const newData = {};
        for (const [k, v] of Object.entries(stored)) {
            if (config[k]) {
                newData[k] = v;
            }
        }
        setData(newData);
    }, []);
    const doSubmit = () => {
        onSubmit(data);
        const stored = JSON.parse(sessionStorage.getItem(FORM_DATA_KEY) || "{}");
        for (const key of ['source', 'author', 'email']) {
            if (config[key]) {
                stored[key] = data[key];
            }
        }
        sessionStorage.setItem(FORM_DATA_KEY, JSON.stringify(stored));
    };
    const setField = name => event => setData({...data, [name]: event.target.value});
    return (<>
                <h2>{dirname}</h2>
                {disclaimer && <p className={styles.disclaimer}><TextWithLinks>{disclaimer}</TextWithLinks></p>}
                {fixedTitle ? <div className={styles.fieldContainer}><b>Название: </b>{fixedTitle}</div> :
                    fixedTitle === null ?
                    <div className={styles.fieldContainer}>
                        Название задачи:
                        <input className={styles.field} value={data.title || ""}
                               onChange={setField('title')}/>
                        <small className={styles.dangerError}>{firstItem(error.title)}</small>
                    </div> : null}
                <div className={styles.fieldContainer}>
                    Условие задачи:
                    <TextAreaAutoSize className={styles.field} value={data.text || ""}
                                      onChange={setField('text')}/>
                    <small className={styles.dangerError}>{firstItem(error.text)}</small>
                </div>
                {config.answer && <div className={styles.fieldContainer}>
                    {config.answer.label || "Ответ"}:
                    <input className={styles.field} value={data.answer || ""}
                           onChange={setField('answer')}/>
                    <small className={styles.dangerError}>{firstItem(error.answer)}</small>
                </div>}
                {config.solution && <div className={styles.fieldContainer}>
                    {config.solution.label || "Решение задачи"}:
                    <TextAreaAutoSize className={styles.field} value={data.solution || ""}
                                      onChange={setField('solution')}/>
                    <small className={styles.dangerError}>{firstItem(error.solution)}</small>
                </div>}
                {config.source && <div className={styles.fieldContainer}>
                    {config.source.label || "Источник задачи"}:
                    <input className={styles.field} value={data.source || ""}
                           onChange={setField('source')}/>
                    <small className={styles.dangerError}>{firstItem(error.source)}</small>
                </div>}
                {config.author && <div className={styles.fieldContainer}>
                    {config.author.label || "Автор задачи"}:
                    <input className={styles.field} value={data.author || ""}
                           onChange={setField('author')}/>
                    <small className={styles.dangerError}>{firstItem(error.author)}</small>
                </div>}
                {config.email && <div className={styles.fieldContainer}>
                    {config.email.label || "Email для связи"}:
                    <input className={styles.field} value={data.email || ""} type="email"
                           onChange={setField('email')}/>
                    <small className={styles.dangerError}>{firstItem(error.email)}</small>
                </div>}
                {config.grade && <div className={styles.gradeContainer}>
                    {config.grade.label || "Класс"}:
                    <input type="number" min={1} max={15} className={styles.shortField}
                           value={data.grade_low || ""}
                           placeholder="От"
                           onChange={setField('grade_low')}/>
                    <small className={styles.dangerError}>{firstItem(error.grade_low)}</small>
                    &mdash;
                    <input type="number" min={1} max={15} className={styles.shortField}
                           value={data.grade_high || ""}
                           placeholder={'До'}
                           onChange={setField('grade_high')}/>
                    <small className={styles.dangerError}>{firstItem(error.grade_high)}</small>
                </div>}
                {config.difficulty && <div className={styles.gradeContainer}>
                    {config.difficulty.label || "Сложность"}:
                    <input type="number" min={1} max={10} className={styles.shortField} value={data.difficulty_low || ""}
                           placeholder="От"
                           onChange={setField('difficulty_low')}/>
                    <small className={styles.dangerError}>{firstItem(error.difficulty_low)}</small>
                    &mdash;
                    <input type="number" min={1} max={10} className={styles.shortField} value={data.difficulty_high || ""}
                           placeholder="До"
                           onChange={setField('difficulty_high')}/>
                    <small className={styles.dangerError}>{firstItem(error.difficulty_high)}</small>
                </div>}
                {config.year && <div className={styles.gradeContainer}>
                    {config.year.label || "Год"}:
                    <input type="number" min={1600} max={2100} className={styles.shortField} value={data.year || ""}
                           onChange={setField('year')}/>
                    <small className={styles.dangerError}>{firstItem(error.year)}</small>
                </div>}

                {config.labels && <div className={styles.tagsContainer}>
                    <div className={styles.labels}>{config.labels.label || "Метки"}:
                    <ListEditor objects={data.labels || []}
                                onDelete={(tag) => {
                                    const filteredLabels = (data.labels || []).filter(x => x !== tag);
                                    setData({...data, labels: filteredLabels});
                                }}
                                onAdd={(tag) => {
                                    const newLabels = (data.labels || []).concat([tag]);
                                    setData({...data, labels: newLabels});
                        }}/></div>
                </div>}
                <button className={styles.saveButton} onClick={doSubmit}>Сохранить</button>
            </>);
};

export default ProblemForm;

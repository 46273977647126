import React from 'react';

class OutsideClickHandler extends React.Component {
	constructor(props) {
		super(props);
		this.element = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = event => {
		if (this.element.current && !this.element.current.contains(event.target)) {
			this.props.onClickOutside();
		}
	};

	render() {
        return <div ref={this.element} style={this.props.style}>{this.props.children}</div>;
    }
}

export default OutsideClickHandler;

import React, {useState} from "react";
import {Item, Menu, Submenu} from "react-contexify";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from 'react-redux';
import {getDirectoryInfo} from "../../../../utils/dirInfo";
import {
    CreateDirectoryDialog,
    DeleteDirectoryDialog, DirectoryAttributeSuggestionDialog, DirectoryEnumDialog,
    DirectoryFormDialog, DirectoryMoveDialog, DirectoryNumerationDialog,
    RenameDirectoryDialog
} from "../../../dialogs";
import {bookmarkDirectory, loadPath} from "../../../../reducers/main";
import {AclDialog} from "../aclDialog";
import MenuIcon from "../../../menuIcon";

const DirectoryActions = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const directory = useSelector(state => state.main.selectedQuery.directory);
    const tree = useSelector(state => state.main.tree);
    const dirInfo = getDirectoryInfo(directory, tree);

    const [curDialog, setCurDialog] = useState(null);
    const isAuthenticated = useSelector(state => !!state.auth.authorized);
    if (!isAuthenticated || !dirInfo) {
        return null;
    }

    return <>
        <MenuIcon menuId="dir_actions"/>
        <Menu id="dir_actions">
        {dirInfo.canWrite && <Item onClick={() => history.push('/createProblem/' + dirInfo.id)}>Создать задачу</Item>}
        {dirInfo.canWrite && <Item onClick={() => setCurDialog('createDir')}>Создать папку</Item>}
        {dirInfo.canWrite && !dirInfo.persistent && <Item onClick={() => setCurDialog('renameDir')}>Переименовать</Item>}
        {dirInfo.canWrite && !dirInfo.persistent && <Item onClick={() => setCurDialog('moveDir')}>Переместить</Item>}
        {dirInfo.canWrite && !dirInfo.persistent && <Item onClick={() => setCurDialog('deleteDir')}>Удалить</Item>}
        {dirInfo.canBookmark && (!dirInfo.bookmarked ?
            <Item onClick={() => bookmarkDirectory(dispatch, dirInfo.id, false)}>Добавить в закладки</Item> :
            <Item onClick={() => bookmarkDirectory(dispatch, dirInfo.id, true)}>Удалить из закладок</Item>)
        }
        {dirInfo.canWrite && <Submenu label="Параметры">
            {dirInfo.canAdmin && <Item onClick={() => setCurDialog('acl')}>Права доступа</Item>}
            <Item onClick={() => setCurDialog('dirForm')}>Форма</Item>
            <Item onClick={() => setCurDialog('numeration')}>Нумерация</Item>
            <Item onClick={() => setCurDialog('attributeSuggestion')}>Краудсорсинг</Item>
            <Item onClick={() => setCurDialog('enums')}>Фиксированные метки</Item>
        </Submenu>}
        </Menu>

        {curDialog === 'createDir' && <CreateDirectoryDialog directory={dirInfo.id} onClose={() => setCurDialog(null)}/>}
        {curDialog === 'renameDir' && <RenameDirectoryDialog directory={dirInfo.id} oldName={dirInfo.name} onClose={(resp) => {
            setCurDialog(null);
            if (resp) {
                loadPath(dispatch, dirInfo.id);
            }
        }}/>}
        {curDialog === 'moveDir' && <DirectoryMoveDialog directory={dirInfo.id} onClose={() => {
            setCurDialog(null);
        }}/>}
        {curDialog === 'deleteDir' && <DeleteDirectoryDialog directory={dirInfo.id} onClose={(resp) => {
            setCurDialog(null);
            if (resp) {
                history.push('/q/' + (resp.parent || ''));
            }
        }}/>}
        {curDialog === 'acl' && <AclDialog id={dirInfo.id} name={dirInfo.name} onClose={() => setCurDialog(null)}/>}
        {curDialog === 'dirForm' && <DirectoryFormDialog directory={dirInfo.id} onClose={() => setCurDialog(null)}/>}
        {curDialog === 'numeration' && <DirectoryNumerationDialog directory={dirInfo.id} onClose={() => setCurDialog(null)}/>}
        {curDialog === 'attributeSuggestion' && <DirectoryAttributeSuggestionDialog directory={dirInfo.id} onClose={() => setCurDialog(null)}/>}
        {curDialog === 'enums' && <DirectoryEnumDialog directory={dirInfo.id} onClose={() => setCurDialog(null)}/>}
    </>
};

export default DirectoryActions;

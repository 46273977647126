import React from 'react';
import styles from './styles.module.scss';

import http from 'utils/axios';
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router";
import {loadUserPage} from "../../../../reducers/user";


@connect(
    state => ({auth: state.auth, user: state.user})
)
class EditPasswordForm extends React.Component {
    state = {
        oldPassword: null,
        password: null,
        passwordRepeat: null,
        error: {},
        passwordError: {},
    };

    componentDidMount() {
        loadUserPage(this.props.dispatch, "me");
    }


    changeInput = name => e => {
        const data = {};
        data[name] = e.target.value;
        this.setState(data);
    };

    submitPassword = e => {
        e.preventDefault();
        this.setState({passwordError: {}});
        http.post('auth/changePassword', {old_password: this.state.oldPassword, password: this.state.password})
        .then(() => {
            this.props.history.push('/user/' + this.props.auth.username);
        })
        .catch(err => {
            this.setState({passwordError: err.response.data})
        });
    };

    render() {
        if (this.props.auth.authorized === false) {
            return <Redirect to={'/login'}/>;
        }
        return <div className={styles.fullPageContainer}>
            <div className={styles.form}>
                <form>
                    <br/>
                    <label htmlFor='oldPassword'>Старый пароль</label>
                    <input type='password' name='oldPassword' className={styles.input}
                           value={this.state.oldPassword}
                           onChange={this.changeInput('oldPassword')}/>
                    {this.state.passwordError.old_password && <small className={styles.error}>{this.state.passwordError.old_password[0]}</small>}
                    <label htmlFor='password'>Новый пароль</label>
                    <input type='password' name='password' className={styles.input}
                           value={this.state.password}
                           onChange={this.changeInput('password')}/>
                    {this.state.passwordError.password && <small className={styles.error}>{this.state.passwordError.password[0]}</small>}
                    <label htmlFor='passwordRepeat'>Подтверждение пароля</label>
                    <input type='password' name='passwordRepeat' className={styles.input}
                           value={this.state.passwordRepeat}
                           onChange={this.changeInput('passwordRepeat')}/>
                     {this.state.password && this.state.password !== this.state.passwordRepeat && <small className={styles.error}>Пароли не совпадают</small>}
                    <button disabled={!this.state.oldPassword || !this.state.password || this.state.password !== this.state.passwordRepeat}
                            type='submit' className={styles.submitButton} onClick={this.submitPassword}>Изменить пароль</button>
                </form>
            </div>
        </div>
    }
}

export default withRouter(EditPasswordForm);

import React from 'react';
import styles from './styles.module.scss';
import InfoItem from './infoItem';
import Username from "./username";
import http from 'utils/axios';


const coalesce = (a, b) => a === null ? b : a;


class UserInfo extends React.Component {
    state = {
        first_name: null,
        last_name: null,
        about: null,
        organization: null,
        city: null,
    };

    changeInput = name => e => {
        const data = {};
        data[name] = e.target.value;
        this.setState(data);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isEditing === true && this.props.isEditing === false) {
            this.submitChange();
        }
    };

    submitChange = () => {
        let obj = {};
        if (this.state.first_name !== null) {
            obj.first_name = this.state.first_name;
        }
        if (this.state.last_name !== null) {
            obj.last_name = this.state.last_name;
        }
        if (this.state.city !== null) {
            obj.city = this.state.city;
        }
        if (this.state.organization !== null) {
            obj.organization = this.state.organization;
        }
        if (this.state.about !== null) {
            obj.about = this.state.about;
        }
        this.setState({error: {}});
        http.patch('users/editProfile', {
            ...obj
        })
        .then(() => {
            window.location.reload();
        })
        .catch(err => {
            this.setState({error: err.response.data})
        });
    };

    render() {
        return <div className={styles.wrapper}>
            <Username username={this.props.profile.username}/>
            <InfoItem itemKey='Имя' itemValue={this.props.isEditing
                ? <input value={coalesce(this.state.first_name, this.props.profile.first_name)}
                         className={styles.inputField}
                         onChange={this.changeInput('first_name')}/>
                : this.props.profile.first_name}/>
            <InfoItem itemKey='Фамилия' itemValue={this.props.isEditing
                ? <input value={coalesce(this.state.last_name, this.props.profile.last_name)}
                         className={styles.inputField}
                         onChange={this.changeInput('last_name')}/>
                : this.props.profile.last_name}/>
            <InfoItem itemKey='Город' itemValue={this.props.isEditing
                ? <input value={coalesce(this.state.city, this.props.profile.city)}
                         className={styles.inputField}
                         onChange={this.changeInput('city')}/>
                : this.props.profile.city}/>
            <InfoItem itemKey='Организация' itemValue={this.props.isEditing
                ? <input value={coalesce(this.state.organization, this.props.profile.organization)}
                         className={styles.inputField}
                         onChange={this.changeInput('organization')}/>
                : this.props.profile.organization}/>
            <InfoItem itemKey='О себе' itemValue={this.props.isEditing
                ? <input value={coalesce(this.state.about, this.props.profile.about)}
                         className={styles.inputField}
                         onChange={this.changeInput('about')}/>
                : this.props.profile.about}/>
            {Object.keys(this.props.profile.contacts || {}).map(key =>
                <InfoItem key={key} itemKey={key} itemValue={this.props.profile.contacts[key]}/>)}
        </div>;
    }
}

export default UserInfo;

import React, {useEffect, useState} from 'react';
import styles from "./styles.module.scss";
import Dialog from "../../../dialog";
import http from "../../../../utils/axios"
import confirmEditingIconImage from "../../../../assets/media/confirm_editing_icon.svg";
import rejectEditingIconImage from "../../../../assets/media/reject_editing_icon.svg";
import {Checkbox} from "@material-ui/core";
import {UserAutosuggest} from "../../../labelAutosuggest";

const AclRow = ({user, level, onDelete}) => {
    return <tr>
        <td>{user || <i>по ссылке</i>}</td>
        <td>{["просмотр", "редактирование", "полный доступ"][level]}</td>
        <td><span onClick={onDelete} className={styles.deleteButton} title="Удалить">×</span></td>
    </tr>
};

const LevelSelect = ({selected, onSelect, forProblem}) => {
    return <select onChange={(e) => onSelect(e.target.value)}>
        <option value={0} selected={selected === 0}>просмотр</option>
        <option value={1} selected={selected === 1}>редактирование</option>
        {forProblem || <option value={2} selected={selected === 2}>полный доступ</option>}
    </select>;
};

export const AclDialog = ({id, name, onClose, forProblem}) => {
    const [data, setData] = useState(null);
    const [adder, setAdder] = useState(null);
    const [error, setError] = useState("");
    useEffect(() => {
        http.get(`${forProblem ? 'problems' : 'tree'}/${id}/acl`).then(({data}) => setData(data));
    }, []);
    const submitCheckbox = name => event => {
        http.patch(`${forProblem ? 'problems' : 'tree'}/${id}/acl`, {[name]: event.target.checked}).then(({data}) => setData(data));
    };
    return <Dialog title={"Права доступа: " + name} shown={true} onClose={onClose} buttons={[{text: 'Закрыть'}]}>
        {data === null ? "Загрузка..." :
            <div>
                <p><b>Владелец:</b> {data.owner}</p>
                <table className={styles.aclTable}>
                    <thead><tr><th>Пользователь</th><th>Уровень</th><th/></tr></thead>
                <tbody>
                {data.acl.map(x => <AclRow user={x.user} level={x.level} onDelete={() => {
                    http.post(`${forProblem ? 'problems' : 'tree'}/${id}/acl/delete`, {user: x.user}).then(({data}) => setData(data))
                }}/>)}
                {adder === null || <tr>
                    <td className={styles.userContainer}>
                        <UserAutosuggest inputProps={{value: adder.user, disabled: adder.any, className: styles.userInput}} onChange={user => setAdder({...adder, user})}/>
                        <label><input type="checkbox" checked={adder.any} onChange={e => setAdder({...adder, any: e.target.checked})}/>по ссылке</label></td>
                    <td><LevelSelect selected={adder.level} onSelect={x => setAdder({...adder, level: x})} forProblem={forProblem}/></td>
                    <td><img src={confirmEditingIconImage} className={styles.editIcon}
                         onClick={() => {
                             setError("");
                             http.post(`${forProblem ? 'problems' : 'tree'}/${id}/acl/add`, {level: adder.level, user: adder.any ? null : adder.user})
                                 .then(({data}) => {setData(data);setAdder(null)})
                                 .catch(({response}) => response.data && setError(response.data.detail))
                         }} alt='save'/>
                        <img src={rejectEditingIconImage} className={styles.editIcon}
                         onClick={() => {setAdder(null);setError("")}} alt='cancel'/></td>
                </tr>}
                </tbody>
                </table>
                {!!error && <span className={styles.errorMessage}>{error}</span>}
                {adder === null && <span className={styles.addButton}
                                         onClick={() => setAdder({user: '', level: 0, any: false})}>Добавить</span>}
                <div className={styles.checkboxArea}>
                     <label>
                        <Checkbox disabled={data.hidden_readonly} className={styles.checkBox}
                                  onChange={submitCheckbox('solution_hidden')} checked={data.solution_hidden}/>
                        <span className={styles.checkboxLabel}>Показывать решение только тем, кто может редактировать задачу</span>
                     </label>
                     <label>
                        <Checkbox disabled={data.hidden_readonly} className={styles.checkBox}
                                  onChange={submitCheckbox('source_hidden')} checked={data.source_hidden}/>
                        <span className={styles.checkboxLabel}>Показывать источник только тем, кто может редактировать задачу</span>
                     </label>
                </div>

            </div>
        }
    </Dialog>
};

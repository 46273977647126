import React from 'react';

import styles from '../styles.module.scss';
import {connect} from "react-redux";
import {performRegister} from "../../../../reducers/auth";
import {withRouter} from "react-router";

@connect(
    state => state.auth,
    dispatch => ({
        doRegister(params) {
            return performRegister(dispatch, params);
        }
    })
)
class RegisterBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
        username:"",
        email:"", 
        password:"", 
        confirmPassword:"", 
        firstName:"",
        lastName:"",
        error: {}
    };
  }

  showValidationErr = (elm, msg) => {
    let error = this.state.error;
    error[elm] = msg;
    this.setState({error});
  };

  validationErrorHappened = () => {
    if (this.state.confirmPassword !== this.state.password) {
      this.setState({error: {...this.state.error, confirmPassword: ["Пароли не совпадают"]}});
      return true;
    }
    return false;
  };

  submitRegister = (e) => {
    e.preventDefault();
    
    if (this.validationErrorHappened()) {
      return false;
    }

    this.props.doRegister({
        username: this.state.username,
        password: this.state.password,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email
      })
        .then(() => {
          this.props.history.push('/');
        })
        .catch(err => {
          this.setState({error: err.response.data});
        });
    return true;
    
  };

  onUsernameChange = (e) => {
    this.setState({
      username: e.target.value
    });
    this.showValidationErr("username", null);
  };

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value
    });
    this.showValidationErr("password", null);
  };

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value
    });
    this.showValidationErr("email", null);
  };

  onFirstNameChange = (e) => {
    this.setState({
      firstName: e.target.value
    });
    this.showValidationErr("first_name", null);
  };

  onLastNameChange = (e) => {
    this.setState({
      lastName: e.target.value
    });
    this.showValidationErr("last_name", null);
  };

  onConfirmPasswordChange = (e) => {
    this.setState({
      confirmPassword : e.target.value
    });
    this.showValidationErr("confirmPassword", null);
  };

  render() {

    return (
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          Зарегистрироваться
        </div>
        <form className={styles.box}>
        <div className={styles.inputGroup}>
            <label>Логин</label>
            <input type="text" value={this.state.username} name="username" className={styles.loginInput}
                   onChange={this.onUsernameChange}/>
            <small className={styles.dangerError}>{this.state.error.username ? this.state.error.username[0] : ""}</small>
          </div>
          
          <div className={styles.inputGroup}>
            <label>Имя</label>
            <input type="text" value={this.state.firstName} name="firstName" className={styles.loginInput}
                   onChange={this.onFirstNameChange}/>
            <small className={styles.dangerError}>{this.state.error.first_name ? this.state.error.first_name[0] : ""}</small>
          </div>
          <div className={styles.inputGroup}>
            <label>Фамилия</label>
            <input type="text" value={this.state.lastName} name="lastName" className={styles.loginInput}
                   onChange={this.onLastNameChange}/>
            <small className={styles.dangerError}>{this.state.error.last_name ? this.state.error.last_name[0] : ""}</small>
          </div>

          <div className={styles.inputGroup}>
            <label>Email (виден только вам)</label>
            <input type="email" value={this.state.email} name="email" className={styles.loginInput}
                   onChange={this.onEmailChange}/>
            <small className={styles.dangerError}>{this.state.error.email ? this.state.error.email[0] : ""}</small>
          </div>
          <div className={styles.inputGroup}>
            <label>Пароль</label>
            <input type="password" name="password" value={this.state.password} className={styles.loginInput}
                   onChange={this.onPasswordChange}/>
            <small className={styles.dangerError}>{this.state.error.password ? this.state.error.password[0] : ""}</small>
          </div>
          <div className={styles.inputGroup}>
            <label>Подтверждение пароля</label>
            <input type="password" name="confirmPassword" value={this.state.confirmPassword} className={styles.loginInput}
                   onChange={this.onConfirmPasswordChange}/>
            <small className={styles.dangerError}>{this.state.error.confirmPassword ? this.state.error.confirmPassword[0] : ""}</small>
          </div>
          <button 
            type="submit" 
            className={styles.loginBtn} 
            onClick={this.submitRegister}>Зарегистрироваться</button>
        </form>
      </div>
    );
  }
}

export default withRouter(RegisterBox);

import React, {useState} from "react";
import styles from "./styles.module.scss";
import globalStyles from "components/global.module.scss";
import settingsIcon from "assets/media/settings.svg";
import {useDispatch, useSelector} from "react-redux";
import {addProblems, deleteProblem, loadContest} from "../../../../../reducers/contest";
import {Link} from "react-router-dom";
import {ProblemRestrictionsDialog} from "../../dialogs";


const ProblemList = ({problems, groupId, contestId, canDelete}) => {
    const dispatch = useDispatch();
    const [settingsOpen, setSettingsOpen] = useState(null);
    const settingsClosed = data => {
        if (data) {
            loadContest(dispatch, groupId, contestId, true);
        }
        setSettingsOpen(null);
    };
    return <div>
        {problems.map(p =>
            <div className={styles.problemContainer} key={p.number}>
                <div className={styles.problemWrapper}>
                <p>{p.number}. {!!p.origin ? <Link className={globalStyles.link}
                                       to={`/problem/${p.origin}`}>{p.title || 'Задача'}</Link> :
                    (p.title || 'Задача')} <b>({p.max_points})</b></p>
                <img src={settingsIcon} className={styles.problemSettings} alt="Настройки" title="Настройки задачи"
                     onClick={() => setSettingsOpen(p.number)}/>
                    {canDelete && <span title="Удалить задачу" className={styles.deleteProblem} onClick={() => {
                        deleteProblem(dispatch, groupId, contestId, p.number);
                    }}>✕</span>}
                </div>
                {p.no_answer && <small className={styles.noAnswer}>Не задан ответ</small>}
            </div>
        )}
        {settingsOpen !== null && <ProblemRestrictionsDialog groupId={groupId} contestId={contestId}
                                                             problemId={settingsOpen}
                                                             onClose={settingsClosed}/>}
    </div>;
};

const EditProblems = ({groupId, contestId}) => {
    const dispatch = useDispatch();
    const contest = useSelector(state => state.contest.contest);
    const selection = useSelector(state => state.selectionBar.items);
    return <div>
        <ProblemList problems={contest.problems || []} groupId={groupId} contestId={contestId} canDelete={!contest.visible}/>
        {contest.visible ? <p>Для редактирования списка задач скройте соревнование</p> : <p><a href="" onClick={e => {
            e.preventDefault();
            addProblems(dispatch, groupId, contestId, selection.map(x => x.id));
        }}>Добавить задачи из подборки</a></p>}
    </div>;
};

export default EditProblems;

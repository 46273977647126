import http from "../utils/axios";

const START_LOADING_SHEET_TEMPLATE_LIST = "template/START_LOADING_SHEET_TEMPLATE_LIST"
const START_LOADING_CELL_TEMPLATE_LIST = "template/START_LOADING_CELL_TEMPLATE_LIST"
const LOAD_SHEET_TEMPLATE_LIST = "template/LOAD_SHEET_TEMPLATE_LIST"
const LOAD_CELL_TEMPLATE_LIST = "template/LOAD_CELL_TEMPLATE_LIST"

const START_LOADING_DEFAULT_SHEET_TEMPLATE_LIST = "template/START_LOADING_DEFAULT_SHEET_TEMPLATE_LIST"
const START_LOADING_DEFAULT_CELL_TEMPLATE_LIST = "template/START_LOADING_DEFAULT_CELL_TEMPLATE_LIST"
const LOAD_DEFAULT_SHEET_TEMPLATE_LIST = "template/LOAD_DEFAULT_SHEET_TEMPLATE_LIST"
const LOAD_DEFAULT_CELL_TEMPLATE_LIST = "template/LOAD_DEFAULT_CELL_TEMPLATE_LIST"

export const loadTemplateList = (dispatch, type) => {
    const startType = (type === 'sheet' ? START_LOADING_SHEET_TEMPLATE_LIST : START_LOADING_CELL_TEMPLATE_LIST)
    const loadType = (type === 'sheet' ? LOAD_SHEET_TEMPLATE_LIST : LOAD_CELL_TEMPLATE_LIST)

    dispatch({type: startType, payload: {}})
    http.get('templates/' + type)
        .then(({data}) => {
            dispatch({type: loadType, payload: data})
        })
        .catch(error => {
            dispatch({type: loadType, payload: {error: error.response.status}})
        })
}

export const loadDefaultTemplateList = (dispatch, type) => {
    const startType = (type === 'sheet' ? START_LOADING_DEFAULT_SHEET_TEMPLATE_LIST : START_LOADING_DEFAULT_CELL_TEMPLATE_LIST)
    const loadType = (type === 'sheet' ? LOAD_DEFAULT_SHEET_TEMPLATE_LIST : LOAD_DEFAULT_CELL_TEMPLATE_LIST)

    dispatch({type: startType, payload: {}})
    http.get('templates/' + type + "-default/")
        .then(({data}) => {
            dispatch({type: loadType, payload: data})
        })
        .catch(error => {
            dispatch({type: loadType, payload: {error: error.response.status}})
        })
}

const sheetReducer = (state = {}, action) => {
    switch (action.type) {
        case START_LOADING_SHEET_TEMPLATE_LIST:
            return {
                ...state,
                areSheetsLoading: true,
            }
        case START_LOADING_CELL_TEMPLATE_LIST:
            return {
                ...state,
                areCellsLoading: true,
            }
        case LOAD_SHEET_TEMPLATE_LIST:
            return {
                ...state,
                sheets: action.payload,
                areSheetsLoading: false,
            }
        case LOAD_CELL_TEMPLATE_LIST:
            return {
                ...state,
                cells: action.payload,
                areCellsLoading: false,
            }
        case START_LOADING_DEFAULT_SHEET_TEMPLATE_LIST:
            return {
                ...state,
                areDefaultSheetsLoading: true,
            }
        case START_LOADING_DEFAULT_CELL_TEMPLATE_LIST:
            return {
                ...state,
                areDefaultCellsLoading: true,
            }
        case LOAD_DEFAULT_SHEET_TEMPLATE_LIST:
            return {
                ...state,
                defaultSheets: action.payload,
                areDefaultSheetsLoading: false,
            }
        case LOAD_DEFAULT_CELL_TEMPLATE_LIST:
            return {
                ...state,
                defaultCells: action.payload,
                areDefaultCellsLoading: false,
            }
        default:
            return state
    }
}

export default sheetReducer;
import React, {useEffect} from "react";
import {useSelector, useStore} from "react-redux";
import {checkSessionExpiration, setLoggedOutDialog} from "../../../../reducers/auth";
import Dialog from "../../../dialog";


export const AuthWatcher = () => {
    const store = useStore();
    useEffect(() => {
        const interval = setInterval(() => {
            const {username} = store.getState().auth;
            if (!username) {
                return;
            }
            const expiration = localStorage.getItem('sessionExpires');
            if (expiration) {
                const expirationDate = new Date(expiration);
                if (new Date() < expirationDate) {
                    return;
                }
            }
            checkSessionExpiration(store.dispatch);
        }, 60000);
        return () => clearInterval(interval);
    }, []);
    const showDialog = useSelector(state => state.auth.loggedOutDialog);
    return <Dialog shown={showDialog} onClose={() => setLoggedOutDialog(store.dispatch, false)}
                   title="Mathmaker"
                   buttons={[{text: 'Закрыть'}]}>
        Время вашей сессии истекло. Для продолжения работы, пожалуйста, войдите в систему еще раз.
    </Dialog>
};

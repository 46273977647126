import http from "../utils/axios";
import {
    areParamsSame,
    buildSearchParams,
    buildSearchUrl,
    DEFAULT_SEARCH,
    parseSearchParams
} from "../utils/searchParams";
import {ON_USER_CHANGED} from "./auth";

const LOAD_TASKS = 'main/LOAD_TASKS';
const SWITCH_LOADING_TYPE = 'main/SWITCH_LOADING_TYPE';
const CANCEL_SEARCH = 'main/CANCEL_SEARCH';
const SWITCH_LOADING_STATUS = 'main/SWITCH_LOADING_STATUS';
const SWITCH_ALL_PAGES_LOADED = 'main/SWITCH_ALL_PAGES_LOADED';
const UPDATE_SEARCH_QUERY = 'main/UPDATE_SEARCH_QUERY';
const LOAD_TREE = 'main/LOAD_TREE';
const LOAD_PATH = 'main/LOAD_PATH';
const UPDATE_TREE = 'main/UPDATE_TREE';
const SELECT_DIR = 'main/SELECT_DIR';
const OPEN_NODE = 'main/OPEN_NODE';
const START_LOADING_CHILDREN = 'main/START_LOADING_CHILDREN';


const findParents = (dirs, selected) => {
    const parents = {};
    for (const dir of Object.keys(dirs)) {
        for (const child of dirs[dir].children || []) {
            parents[child] = dir;
        }
    }
    if (!parents[selected]) {
        return null;
    }
    const result = [selected];
    while (parents[selected]) {
        selected = parents[selected];
        result.push(selected);
    }
    return result;
}

export const doSearch = (dispatch, props, force = false) => {
    const searchParams = parseSearchParams(props.location);
    const actualParams = {...props.query, searchMode: props.searchMode};
    if (!force && areParamsSame(searchParams, actualParams)) {
        return;
    }
    const parents = findParents(props.dirs, searchParams.directory);
    dispatch({type: OPEN_NODE, payload: {
        nodes: parents || [searchParams.directory],
        forceParents: parents === null ? searchParams.directory : null,  // will open it when its parents are loaded
        version: props.forceOpenNodes.version + 1,
    }});
    if (!searchParams.searchMode) {
        dispatch({type: CANCEL_SEARCH, payload: {directory: searchParams.directory}});
        loadDirProblems(dispatch, searchParams.directory);
        if (searchParams.directory in props.dirs && props.dirs[searchParams.directory].children === undefined) {
            updateTree(dispatch, searchParams.directory);
        }
    } else {
        dispatch(updateSearchQuery(searchParams));
        switchLoadingType(dispatch, 'search', searchParams);
        loadPath(dispatch, searchParams.directory);
    }
};

export const updateLocationAndSearch = (dispatch, props, searchMode) => {
    props.history.push('/q/' + buildSearchUrl(props.selectedQuery, searchMode));
};

export const loadMoreProblems = (dispatch, loadingType, query, page) => {
    if (!loadingType) {
        return;
    }
    dispatch({type: SWITCH_LOADING_STATUS, payload: {isLoading: true}});
    http.get(`tree/${query.directory || 'default'}/search`, {params: buildSearchParams(loadingType, page, query)})
        .then(({data}) => {
            dispatch(loadTasks(data, false));
            if (!data.more) {
                dispatch(switchAllPagesLoaded(true));
            }
        })
        .catch(
            error => dispatch(loadTasksError(error.response.status))
        )
        .finally(
            () => dispatch({type: SWITCH_LOADING_STATUS, payload: {isLoading: false}})
        )
};

export const loadTasks = (data, clearFlag = true) => ({
    type: LOAD_TASKS,
    payload: {tasks: data.problems, path: data.path, clearFlag}
});

export const loadDirTasks = (tasks, path, canSuggest) => ({
    type: LOAD_TASKS,
    payload: {tasks, path, canSuggest, clearFlag: true}
});

const loadTasksError = (code) => ({
    type: LOAD_TASKS,
    payload: {tasks: [], tasksError: code},
});

export const loadDirProblems = (dispatch, directory) => {
    dispatch({type: SWITCH_LOADING_STATUS, payload: {isLoading: true}});
    http.get(`tree/${directory || 'default'}/problems`).then(({data}) => {
        dispatch(loadDirTasks(data.problems, data.path, data.can_suggest));
    }).catch(error => dispatch(loadTasksError(error.response?.status ?? "unknown")))
      .finally(() => {
        dispatch({type: SWITCH_LOADING_STATUS, payload: {isLoading: false}});
    });
};

export const switchLoadingType = (dispatch, loadingType, query) => {
    dispatch({type: SWITCH_LOADING_TYPE, payload: {loadingType, query}});
    loadMoreProblems(dispatch, loadingType, query, 1);
};

export const switchAllPagesLoaded = (allPagesLoaded) => ({
    type: SWITCH_ALL_PAGES_LOADED,
    payload: {allPagesLoaded}
});

export const updateSearchQuery = (query) => ({
    type: UPDATE_SEARCH_QUERY,
    payload: {query},
});

export const loadPath = (dispatch, dir) => {
    return http.get(`tree/${dir || 'default'}/path`).then(({data}) => {
        dispatch({type: LOAD_PATH, payload: data});
    })
};

export const loadTree = (dispatch, dir) => {
    return http.get('tree/default', {params: {open: dir}}).then(({data}) => {
        dispatch({type: LOAD_TREE, payload: data});
    })
};

export const updateTree = (dispatch, dir) => {
    dispatch({type: START_LOADING_CHILDREN, payload: {dir}});
    return http.get(`tree/${dir}/children`).then(({data}) => {
        dispatch({type: UPDATE_TREE, payload: data});
    })
};

export const updateSelection = (dispatch, dir, props) => {
    dispatch({type: SELECT_DIR, payload: dir});
    const newProps = {...props, selectedQuery: {...props.selectedQuery, directory: dir}};
    updateLocationAndSearch(dispatch, newProps, props.searchMode);
};

export const updateOrder = (dispatch, order, props) => {
    dispatch(updateSearchQuery({order}));
    const newProps = {...props, selectedQuery: {...props.selectedQuery, order}};
    updateLocationAndSearch(dispatch, newProps, props.searchMode);
};

export const createDirectory = (dispatch, parent, name) => {
    return http.post(`tree/${parent}/createChild`, {name}).then(({data}) => {
        dispatch({type: UPDATE_TREE, payload: data});
        return data;
    });
};

export const moveDirectory = (dispatch, dir, to) => {
    return http.post(`tree/${dir}/move`, {to}).then(({data}) => {
        dispatch({type: UPDATE_TREE, payload: data});
        dispatch({type: LOAD_PATH, payload: data});
        return data;
    });
};

export const renameDirectory = (dispatch, id, name) => {
    return http.post(`tree/${id}/rename`, {name}).then(({data}) => {
        dispatch({type: UPDATE_TREE, payload: data});
        return data;
    });
};

export const bookmarkDirectory = (dispatch, id, remove) => {
    return http.post(`tree/${id}/bookmark`, remove ? {remove: true} : {}).then(({data}) => {
        dispatch({type: UPDATE_TREE, payload: data});
        return data;
    });
};

export const deleteDirectory = (dispatch, id) => {
    return http.post(`tree/${id}/delete`, {}).then(({data}) => {
        dispatch({type: UPDATE_TREE, payload: data});
        return data;
    });
};

export const deleteAvailable = (dispatch, id) => {
    return http.post(`tree/${id}/deleteAvailable`, {}).then(({data}) => {
        dispatch({type: UPDATE_TREE, payload: data});
        return data;
    });
};

export const saveSelection = (dispatch, id, selection, mode, renumerate) => {
    return http.post(`tree/${id}/saveProblems`, {problems: selection.map(p => p.id), mode, renumerate}).then(({data}) => {
        return data;
    })
};

export const updateDirs = (oldDirs, newDirs) => {
    const dirs = Object.assign({}, oldDirs);
    newDirs.forEach(d => {
        if (!dirs[d.id]) {
            dirs[d.id] = d;
        } else {
            if (dirs[d.id].children !== undefined && !d.children) {
                d.children = dirs[d.id].children;
            }
            dirs[d.id] = d;
        }
    });
    return dirs;
};


const initialState = {
    tasks: [],
    path: [],
    isLoading: false,
    lastPageLoaded: 1,
    allPagesLoaded: false,
    loadingType: '',
    query: DEFAULT_SEARCH,
    selectedQuery: DEFAULT_SEARCH,
    searchMode: null,
    forceOpenNodes: {nodes: [], version: 0, forceParents: null},
};


const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_TASKS:
            return {
                ...state,
                tasks: action.payload.clearFlag ? action.payload.tasks : [...state.tasks, ...action.payload.tasks],
                path: action.payload.path || state.path,
                canSuggest: action.payload.canSuggest,
                lastPageLoaded: state.lastPageLoaded + 1,
                tasksError: action.payload.tasksError,
                allPagesLoaded: !!action.payload.tasksError || state.allPagesLoaded,
            };
        case SWITCH_LOADING_TYPE:
            return {
                ...state,
                loadingType: action.payload.loadingType,
                lastPageLoaded: 1,
                query: action.payload.loadingType === 'search' ? action.payload.query : DEFAULT_SEARCH,
                selectedQuery: action.payload.loadingType === 'search' ? action.payload.query : DEFAULT_SEARCH,
                tasks: [],
                allPagesLoaded: false,
                searchMode: true,
            };
        case CANCEL_SEARCH:
            return {
                ...state,
                searchMode: false,
                selectedQuery: {...DEFAULT_SEARCH, directory: action.payload.directory},
                query: {...DEFAULT_SEARCH, directory: action.payload.directory},
                tasks: [],
                allPagesLoaded: true,
            };
        case SWITCH_LOADING_STATUS:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };
        case SWITCH_ALL_PAGES_LOADED:
            return {
                ...state,
                allPagesLoaded: action.payload.allPagesLoaded,
            };
        case UPDATE_SEARCH_QUERY:
            return {
                ...state,
                selectedQuery: {
                    ...state.selectedQuery,
                    ...action.payload.query
                },
            };

        case LOAD_TREE:
            const newState = {
                ...state,
                tree: {
                    ...action.payload,
                    dirs: updateDirs(state.tree?.dirs || {}, action.payload.dirs),
                },
            };
            if (state.forceOpenNodes.forceParents && action.payload.dirs.some(d => d.id === state.forceOpenNodes.forceParents)) {
                newState.forceOpenNodes = {
                    version: state.forceOpenNodes.version + 1,
                    nodes: findParents(newState.tree.dirs, state.forceOpenNodes.forceParents) || [],
                    forceParents: null
                };
            }
            return newState;
        case LOAD_PATH:
            return {
                ...state,
                path: action.payload.path,
            };
        case UPDATE_TREE:
            return {
                ...state,
                tree: {
                    public: action.payload.public || state.tree.public,
                    my: action.payload.my || state.tree.my,
                    bookmarks: action.payload.bookmarks || state.tree.bookmarks,
                    available: action.payload.available || state.tree.available,
                    dirs: updateDirs(state.tree.dirs, action.payload.dirs),
                },
            };
        case SELECT_DIR:
            return {
                ...state,
                selectedQuery: {...state.selectedQuery, directory: action.payload},
            };
        case ON_USER_CHANGED:
            return {
                ...state,
                tree: undefined,
            };
        case OPEN_NODE:
            return {
                ...state,
                forceOpenNodes: action.payload,
            };
        case START_LOADING_CHILDREN:
            return {
                ...state,
                tree: {
                    ...state.tree,
                    dirs: updateDirs(state.tree.dirs, [{...state.tree.dirs[action.payload.dir], children: null}]),
                }
            };
        default:
            return state;
    }
};

export default mainReducer;

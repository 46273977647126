import columnStyles from "../columns.module.scss";
import React, {useEffect, useState} from "react";
import http from "../../../utils/axios";
import {MathText} from "../../../utils/MathText";
import TextWithLinks from "../../../utils/TextWithLinks";

const PreviewContent = ({title, text, solution, answer, verdict}) => {
    return <>
        <h2>{title}</h2>
        <div><MathText text={text}/></div>
        <br/>
        <h3>Решение</h3>
        {!!answer && <p><b>Ответ:</b> {answer}</p>}
        <div><MathText text={solution}/></div>
        <br/>
        {!!verdict && <p><b>Вердикт:</b> <TextWithLinks>{verdict}</TextWithLinks></p>}
    </>;
}

const TaskPreview = ({match}) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);
    useEffect(() => {
        setError(false);
        setData(null);
        http.get(`preview/${match.params.id}/${match.params.token}`)
            .then(({data}) => setData(data))
            .catch(() => setError(true));
    }, [match.params.id])
    return <div className={columnStyles.mainWrapper}>
            <div className={columnStyles.leftColumn}>
            </div>
            <div className={columnStyles.centralWrapper}>
                {error ? <p>Такой задачи нет</p> : !data ? <p>Заргузка...</p> : <PreviewContent {...data}/>}
            </div>
            <div className={columnStyles.rightColumn}>
            </div>
        </div>
};

export default TaskPreview;

import styles from "./styles.module.scss";
import React from "react";
import {connect} from "react-redux";
import {addToSelection, deleteFromSelection} from "../../reducers/selectionBar";

@connect(
    store => store.selectionBar,
    dispatch => ({
        addProblemToSelection(id, title) {
            addToSelection(dispatch, id, title)
        },
        removeProblemFromSelection(id) {
            deleteFromSelection(dispatch, id);
        }
    })
)
class AddToSelectionButton extends React.Component {

    handleClickAddToSelection = () => {
        this.props.addProblemToSelection(this.props.id, this.props.title || 'Задача');
    };

    handleClickRemoveFromSelection = () => {
        this.props.removeProblemFromSelection(this.props.id);
    };

    isSelected = () => {
        let filterResult = this.props.items.filter(x => x.id === this.props.id);
        return filterResult.length !== 0;
    };

    render() {
        return this.isSelected() ?
            <span className={styles.removeFromSelectionButton} title={"Удалить из подборки"}
                 onClick={this.handleClickRemoveFromSelection}>
                ✕
            </span> :
            <span className={styles.addToSelectionButton} title={"Добавить в подборку"}
                 onClick={this.handleClickAddToSelection}>
                +
            </span>;
    }
}

export default AddToSelectionButton;

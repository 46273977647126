import React, {memo, useState} from "react";
import styles from './styles.module.scss';
import folderImage from "assets/media/folder.svg";
import {connect, useSelector} from "react-redux";
import {Loading} from "../../../../utils/Loading";
import {TaskList} from "../../../taskList";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import {CreateDirectoryDialog} from "../../../dialogs";
import {getDirectoryError} from "../directoryErrors";
import {updateTree} from "../../../../reducers/main";

export const DirPath = memo(({data, small, children}) => {
    return <div className={small ? styles.dirPathSmall : styles.dirPath}>
        <span className={styles.dirPathLine}>
            {data.flatMap((x, i) => [i ? <span key={"sep" + x.id} className={styles.separator}> &gt; </span> : null, <Link key={x.id} to={'/q/' + x.id}>{x.name}</Link>])}
        </span>
        {children}
    </div>
});

const DirList = ({data}) => {
    return <div className={styles.dirList}>
        {data.map(x => <Link key={x.id} className={styles.directory} to={'/q/' + x.id}><img src={folderImage} alt="dir"/> {x.name}</Link>)}
    </div>
};

const DirButtons = ({id, canWrite, canSuggest}) => {
    const history = useHistory();
    const isAuthenticated = useSelector(state => state.auth.authorized);
    const [dirDialogShown, setDirDialogShown] = useState(false);
    return <div className={styles.dirButtonsContainer}>
        {canWrite ? <>
            <button onClick={() => setDirDialogShown(true)}>Создать папку</button>
            <button onClick={() => history.push('/createProblem/' + id)}>Создать задачу</button>
            {dirDialogShown && <CreateDirectoryDialog directory={id} onClose={() => setDirDialogShown(false)}/>}
        </> : (canSuggest && isAuthenticated) ?
            <button onClick={() => history.push('/suggestProblem/' + id)}>Предложить задачу</button>
            : null
        }
    </div>
};


@connect(
    state => ({tasks: state.main.tasks, dir: state.main.selectedQuery.directory,
               canSuggest: state.main.canSuggest,
               dirs: state.main.tree ? state.main.tree.dirs : {},
               public: state.main.tree ? state.main.tree.public : "",
               tasksError: state.main.tasksError,})
)
class Navigation extends React.Component {

    componentDidMount() {
        const dirData = this.props.dirs[this.props.dir || this.props.public];
        if (dirData === undefined) {
            this.queryDir();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.dir !== prevProps.dir || this.props.public !== prevProps.public) {
            const dirData = this.props.dirs[this.props.dir || this.props.public];
            if (dirData === undefined) {
                this.queryDir();
            }
        }
    }

    queryDir = () => {
        if (this.props.dir && this.props.public) {
            updateTree(this.props.dispatch, this.props.dir);
        }
    };

    render() {
        const dirData = this.props.dirs[this.props.dir || this.props.public];
        const children = dirData ? dirData.children : [];
        const dirs = (children || []).map(id => this.props.dirs[id]);
        return this.props.tasksError ? <p>{getDirectoryError(this.props.tasksError)}</p> : <>
            {dirData && <DirButtons id={dirData.id} canWrite={dirData.can_write} canSuggest={this.props.canSuggest}/>}
            {this.props.isLoading ? <Loading/> : <>
                <DirList data={dirs}/>
                <TaskList data={this.props.tasks}/>
                {!dirs.length && !this.props.tasks.length && <p>Здесь ничего нет</p>}
            </>}
        </>;
    }
}

export default Navigation;

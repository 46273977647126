import http from "../utils/axios";

const START_LOADING_USER = 'user/START_LOADING_USER';
const LOAD_USER = 'user/LOAD_USER';
const USER_NOT_FOUND = 'user/NUSER_NOT_FOUND';


export const loadUserPage = (dispatch, username) => {
    dispatch({type: START_LOADING_USER, payload: null});
    return http.get(`users/profile/${username}`)
        .then(request => dispatch({type: LOAD_USER, payload: request.data}))
        .catch(() => dispatch({type: USER_NOT_FOUND, payload: null}));
};

const initialState = {
    profile: {},
    isLoading: false,
    notFound: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING_USER:
            return {
                ...state,
                isLoading: true,
                notFound: false,
            };
        case LOAD_USER:
            return {
                ...state,
                profile: action.payload,
                isLoading: false,
            };
        case USER_NOT_FOUND:
            return {
                ...state,
                isLoading: false,
                notFound: true,
            };
        default:
            return state;
    }
};

export default userReducer;

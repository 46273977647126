import React from "react";
import styles from './styles.module.scss';
import menuIcon from "../../assets/media/menu.svg";
import {contextMenu} from "react-contexify";


const MenuIcon = ({menuId}) => <img src={menuIcon} alt="Действия" className={styles.actionIcon} onClick={e => {
            const r = e.target.getBoundingClientRect();
            console.log(e.target);
            console.log(r);
            const newEvent = {clientX: r.left, clientY: r.top, stopPropagation: () => e.nativeEvent.stopPropagation()};
            contextMenu.show({id: menuId, event: newEvent})
        }}/>;

export default MenuIcon;

import {useEffect, useState} from "react";

export const useCountdown = (endTime, onZero) => {
    const [now, setNow] = useState(new Date());
    useEffect(() => {
        if (endTime > now) {
            const i = setInterval(() => setNow(new Date()), 1000);
            return () => clearInterval(i);
        } else if (onZero) {
            onZero();
        }
    }, [onZero, endTime, endTime > now]);
    return Math.max(0, Math.floor((endTime - new Date()) / 1000));
};

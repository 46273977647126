import React, {useEffect, useState} from "react";
import Dialog from "../../../../dialog";
import styles from "../../../../dialogs/styles.module.scss";
import confirmEditingIconImage from "../../../../../assets/media/confirm_editing_icon.svg";
import rejectEditingIconImage from "../../../../../assets/media/reject_editing_icon.svg";
import {UserAutosuggest} from "../../../../labelAutosuggest";

export const UserListDialog = ({onClose, getList, onAdd, onDelete, title, emptyText, nonEmptyText}) => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [addingText, setAddingText] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        setLoading(true);
        getList().then(list => {
            setList(list);
            setLoading(false);
        })
    }, [getList]);
    const handleEnterPress = e => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };
    const handleSubmit = () => {
         setError(null);
         onAdd(addingText)
             .then(list => {
                 setList(list);
                 setAddingText(null);
             })
            .catch(e => setError(e.response ? e.response.data.detail : "Ошибка"));
    };
    const handleCancel = () => {
        setAddingText(null);
        setError(false);
    };
    const handleDelete = username => {
        onDelete(username)
            .then(list => setList(list));
    };
    return <Dialog title={title} shown={true} onClose={onClose}
                   buttons={[{text: 'Закрыть'}]}>
        <p className={styles.section}><b>{!loading && list.length === 0 ? emptyText : nonEmptyText}</b></p>
        {loading ? <p>Загрузка...</p> :
            list.map(x => <div key={x} className={styles.listItem}>
                <span onClick={() => handleDelete(x)} className={styles.deleteButton} title="Удалить">×</span>
                {x}</div>)
        }
        {addingText !== null &&
            <div>
                <div className={styles.rowContainer}>
                    <UserAutosuggest inputProps={{value: addingText, placeholder: "имя пользователя", autoFocus: true, onKeyPress: handleEnterPress}}
                                      onChange={setAddingText}/>
                    <img src={confirmEditingIconImage} className={styles.editIcon}
                         onClick={handleSubmit} alt='save'/>
                     <img src={rejectEditingIconImage} className={styles.editIcon}
                         onClick={handleCancel} alt='cancel'/>
                </div>
                {!!error && <small className={styles.error}>{error}</small>}
            </div>
        }
        {addingText === null && <p className={styles.section}><a className={styles.link} href="" onClick={e => {
            e.preventDefault();
            setAddingText("");
        }}>Добавить</a></p>}
    </Dialog>
};

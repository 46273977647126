import React from 'react';
import styles from './styles.module.scss';
import userEmptyIconImage from "../../../assets/media/user_empty_icon.png";
import arrowIcon from "../../../assets/media/header_arrow_icon.svg";
import {Link} from "react-router-dom";
import OutsideClickHandler from "../../../utils/OutsideClickHandler";


const UserDropdown = ({username, onLogout, onLinkClick})=> {
    return <div className={styles.dropdown}>
        <Link to={"/user/"+username} onClick={onLinkClick}>Профиль</Link><br/>
        <hr/>
        <Link to={"/"} onClick={() => {onLinkClick();onLogout();}}>Выйти</Link>
    </div>;
};

class UserIndicator extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {dropdownShown: false};
    }

    render() {
        return (
        <OutsideClickHandler style={{position: "relative"}} onClickOutside={this.hideDropdown}>
        <div className={styles.userIndicator} onClick={this.toggleDropdown}>
            <span className={styles.username}>{this.props.username}</span>
            <img className={styles.miniAvatar} width={32} height={32} src={this.props.avatar ? this.props.avatar : userEmptyIconImage}
                 alt={"avatar"}/>
            <img src={arrowIcon} alt={""} className={styles.arrow} width={12}/>
        </div>
        {this.state.dropdownShown && <UserDropdown username={this.props.username} onLogout={this.props.onLogout}
            onLinkClick={this.hideDropdown}/>}
        </OutsideClickHandler>);
    }

    toggleDropdown = () => {
        this.setState({dropdownShown: !this.state.dropdownShown});
    };

    hideDropdown = () => {
        this.setState({dropdownShown: false});
    }
}

export default UserIndicator;

import React from 'react';
import userEmptyIconImage from 'assets/media/user_empty_icon.png';
import styles from './styles.module.scss';
import FormData from "form-data";
import http from 'utils/axios';


class UserPhoto extends React.Component {
    state = {
        isEditing: false,
        newAvatar: null,
    };

    uploadImage = (e) => {
        this.setState({
            newAvatar: e.target.files[0]
        });
    };

    sendImage = (e) => {
        e.preventDefault();
        if (this.state.newAvatar !== null) {
            let data = new FormData();
            data.append('avatar', this.state.newAvatar, this.state.newAvatar.name);

            http.patch('users/editProfile', data)
            .then(() => {
                window.location.reload();
            })
            .catch(err => {
                console.log(err.response);
            })
        }
    };

    render() {
        return <div className={styles.wrapper}>
            <img src={(this.props.isLoading || !this.props.avatar) ? userEmptyIconImage : this.props.avatar} className={styles.userIconImage} alt='user_icon'/>
            {this.props.isMe && (!this.state.isEditing
                ? <div className={styles.editButton} onClick={() => {this.setState({isEditing: true})}}>Изменить аватар</div>
                : <div className={styles.editPhotoForm}>
                    <input type='file' onChange={this.uploadImage}/>
                    <div type='submit'
                            className={styles.editButton}
                            onClick={this.sendImage}>Принять изменения</div>
                    <div className={styles.editButton} onClick={() => {this.setState({isEditing: false})}}>Отменить</div>
                </div>)
            }
        </div>;
    }
}

export default UserPhoto;

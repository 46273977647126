import React from 'react';
import styles from './styles.module.scss';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {updateSearchQuery} from "../../../reducers/main";


@connect(
    null,
    dispatch => ({
        showMainPage() {
            dispatch(updateSearchQuery(''));
        }
    })
)
class FirmLogoIcon extends React.Component {
    render() {
        return <div className={styles.firmLogoIconContainer}>
            <div className={styles.logoText}>
                <Link to="/q/" onClick={this.props.showMainPage}>MATHMAKER</Link>
            </div>
            <div className={styles.logoTextSmall}>
                <Link to="/q/" onClick={this.props.showMainPage}>M</Link>
            </div>
        </div>
    }
}

export {FirmLogoIcon};

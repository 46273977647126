import React from 'react';
import styles from "./styles.module.scss";
import editIconImage from 'assets/media/edit_icon.svg';
import confirmEditingIconImage from 'assets/media/confirm_editing_icon.svg';
import rejectEditingIconImage from 'assets/media/reject_editing_icon.svg';
import {MathText} from "../../utils/MathText";
import TextAreaAutoSize from 'react-textarea-autosize';
import TextWithLinks from "../../utils/TextWithLinks";
import CopyButton from "../copyButton";



class EditableTextArea extends React.Component {
    state = {
        tmpArea: 'Загрузка...',
        numberArea: '',
        areaIsEditing: false,
    };

    changeTmpArea = (event) => {
        this.setState({
            tmpArea: event.target.value,
        })
    };

    changeNumberArea = (event) => {
        this.setState({
            numberArea: event.target.value,
        })
    };

    handleClickEditArea = () => {
        this.setState({
            areaIsEditing: true,
            tmpArea: this.props.value,
            numberArea: this.props.number,
        })
    };

    handleClickRejectEditingArea = () => {
        this.setState({
            areaIsEditing: false,
        })
    };

    handleCtrlEnterPress = (event) => {
        if (event.key === 'Enter' && event.ctrlKey) {
            this.handleClickConfirmEditingArea()
        }
    };

    handleClickConfirmEditingArea = () => {
        this.props.onEdit(this.state.tmpArea, () => this.setState({areaIsEditing: false}), this.state.numberArea);
    };

    render() {
        const hasNumber = this.props.number !== undefined;
        const displayText = (this.props.number ? this.props.number + ". " : "") + this.props.value;
        return <div className={this.props.small ? styles.smallArea : ""}>
            {this.state.areaIsEditing
                ? <span style={this.props.editingAreaStyle}>
                    {hasNumber &&
                    <input type="number" value={this.state.numberArea} className={styles.numberArea} min={1}
                       placeholder="№"
                       onKeyPress={this.handleCtrlEnterPress}
                       onChange={this.changeNumberArea}/>}
                    <TextAreaAutoSize value={this.state.tmpArea} style={this.props.editingAreaInputFieldStyle}
                              onKeyPress={this.handleCtrlEnterPress}
                              onChange={this.changeTmpArea}/>
                    <img src={confirmEditingIconImage} className={styles.confirmEditingIcon}
                         onClick={this.handleClickConfirmEditingArea} alt='confirm editing icon'/>
                    <img src={rejectEditingIconImage} className={styles.rejectEditingIcon}
                         onClick={this.handleClickRejectEditingArea} alt='reject editing icon'/>
                </span>
                : <span style={this.props.areaStyle}>
                    <div>
                    {this.props.canEdit && <img src={editIconImage} className={styles.editIcon}
                         onClick={this.handleClickEditArea} alt='edit icon'/>}
                    </div>
                    <div>
                    {this.props.useMathJax
                        ? <MathText text={displayText}/>
                        : <TextWithLinks>{displayText}</TextWithLinks>}
                    </div>
                    {this.props.copyable &&
                        <CopyButton text={this.props.value}/>}
                </span>}
        </div>
    }
}

export default EditableTextArea;

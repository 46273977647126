export const getDirectoryInfo = (id, tree) => {
    if (!tree) {
        return {};
    }
    const treeItem = tree.dirs[id || tree.public];
    if (!treeItem) {
        return {};
    }
    return {id: treeItem.id, canWrite: !!treeItem.can_write, canAdmin: treeItem.can_write >= 2,
        canBookmark: true, bookmarked: treeItem.bookmarked, name: treeItem.name,
        persistent: treeItem.id === tree.public || treeItem.id === tree.my};
};

import React, {useEffect, useState} from "react";
import styles from "./styles.module.scss";
import Dialog from "../dialog";
import {useDispatch, useSelector} from "react-redux";
import {
    createDirectory,
    deleteDirectory,
    loadTree,
    moveDirectory,
    renameDirectory,
    saveSelection
} from "../../reducers/main";
import http from "../../utils/axios";
import {Checkbox} from "@material-ui/core";
import {MoveDialog} from "./moveDialog";
import {useHistory} from "react-router";
import {clearNeighborCache} from "../../reducers/task";
import tagStyles from "../views/tags.module.scss";
import editIconImage from "../../assets/media/edit_icon.svg";
import trashCanImage from "../../assets/media/trash_can.svg";
import confirmEditingIconImage from "../../assets/media/confirm_editing_icon.svg";
import rejectEditingIconImage from "../../assets/media/reject_editing_icon.svg";


export const enterHandler = (callback, data) => e => {
    if (e.key === 'Enter') {
        callback(data);
    }
};

export const CreateDirectoryDialog = ({directory, onClose}) => {
    const [dirName, setDirName] = useState('Новая папка');
    const dispatch = useDispatch();
    const handleCreate = (data) => {
        if (data) {
            createDirectory(dispatch, directory, dirName).then(onClose);
        } else {
            onClose(false);
        }
    };
    return <Dialog title="Создание папки" shown={true}
                    onClose={handleCreate}
                    buttons={[{text: 'Создать', data: true}, {text: 'Отмена'}]}>
                Имя папки:
                <input className={styles.dialogInput} value={dirName} autoFocus={true}
                       onChange={e => setDirName(e.target.value)}
                       onKeyPress={enterHandler(handleCreate, true)}
                />
            </Dialog>
};


export const RenameDirectoryDialog = ({directory, oldName, onClose}) => {
    const [dirName, setDirName] = useState(oldName);
    const dispatch = useDispatch();
    const handleRename = (data) => {
        if (data) {
            renameDirectory(dispatch, directory, dirName).then(onClose);
        } else {
            onClose(false);
        }
    };
    return <Dialog title="Переименование папки" shown={true}
            onClose={handleRename} buttons={[{text: 'Сохранить', data: true}, {text: 'Отмена'}]}>
            Новое имя:
            <input className={styles.dialogInput} value={dirName} autoFocus={true}
                   onChange={e => setDirName(e.target.value)}
                   onKeyPress={enterHandler(handleRename, true)}/>
    </Dialog>
};


export const DeleteDirectoryDialog = ({directory, onClose}) => {
    const dispatch = useDispatch();
    const handleDelete = (data) => {
        if (data) {
            deleteDirectory(dispatch, directory).then(onClose);
        } else {
            onClose(false);
        }
    };
    return <Dialog title="Удаление папки" shown={true}
            onClose={handleDelete}
            buttons={[{text: 'Да', data: true, autofocus: true}, {text: 'Нет'}]}>
        Вы уверены, что хотите удалить папку?
    </Dialog>
};


export const DirectoryFormDialog = ({directory, onClose}) => {
    const [formState, setFormState] = useState({});
    const [disclaimer, setDisclaimer] = useState('');
    const [edit, setEdit] = useState(false);
    const [deleting, setDeleting] = useState(false);
    useEffect(() => {
        http.get(`tree/${directory}/form`).then(({data}) => {
            if (data.token) {
                setFormState({exists: true, token: data.token});
            } else {
                setFormState({exists: false});
                setEdit(true);
            }
        })
    }, []);
    const handleForm = (data) => {
        if (data === 'edit') {
            http.post(`tree/${directory}/form`,
                {disclaimer})
                .then(({data}) => {
                    setFormState({exists: true, token: data.token});
                    setEdit(false);
                })
        } else if(data === 'del') {
            setDeleting(true);
        } else {
            onClose(false);
        }
    };
    const handleClose = (data) => {
        if (data) {
            if (!formState.exists) {
                return;
            }
            http.get(`forms/${formState.token}/`)
                .then(({data}) => {
                    setDisclaimer(data.disclaimer);
                    setEdit(true);
                })
        } else {
            onClose(false);
        }
    };
    const handleDelete = (data) => {
        if (data) {
            http.post(`tree/${directory}/form`, {remove: true})
                .then(() => {
                    onClose(false);
                })
        } else {
            setDeleting(false);
        }
    };
    if (deleting){
        return <Dialog title="Удаление формы" shown={true}
                onClose={handleDelete}
                buttons={[{text: 'Да', data: true, autofocus: true}, {text: 'Нет'}]}>
            <p>
                Вы уверены, что хотите удалить форму? Ссылка на нее станет недействительной
            </p>
        </Dialog>
    }
    if (edit) {
        return <Dialog title={formState.exists ? "Редактирование формы" : "Создание формы"} shown={true}
                onClose={handleForm}
                buttons={[formState.exists ? {text: 'Удалить', data: 'del'} : null,
                    {text: formState.exists ? 'Сохранить' : 'Создать', data: 'edit'}, {text: 'Отмена'}]}>
            <p>
                Дисклеймер:
                <textarea className={styles.dialogTextarea} value={disclaimer}
                   onChange={e => setDisclaimer(e.target.value)}/>
            </p>
        </Dialog>
    }
    else {
        return <Dialog title="Форма" shown={true}
                onClose={handleClose}
                buttons={[{text: 'Редактировать', data: 'edit'}, {text: 'Закрыть'}]}>
            {formState.exists === true ? <p>Форма для этой папки: <br/>
                <input className={styles.formLink} readOnly={true} autoFocus={true}
                       value={window.location.origin + '/form/' + formState.token}/>
            </p> : <p>Загрузка...</p>}
        </Dialog>
    }
};


export const DirectoryNumerationDialog = ({directory, onClose}) => {
    const [numeration, setNumeration] = useState(null);
    const [title, setTitle] = useState(null);
    const [titleError, setTitleError] = useState(null);
    const [number, setNumber] = useState(null);
    useEffect(() => {
        http.get(`tree/${directory}/numeration`).then(({data}) => {
            setNumeration(data.numeration);
            setTitle(data.title_template);
            setNumber(data.next_problem_number);
        });
    }, []);
    const handleNumeration = (data) => {
        if (data) {
            setTitleError(null);
            http.put(`tree/${directory}/numeration`,
                {numeration, title_template: title, next_problem_number: number})
                .then(() => onClose(true))
                .catch((err) => {
                    if (err.response.data.title_template) {
                        setTitleError(err.response.data.title_template[0]);
                    }
                });
        } else {
            onClose(false);
        }
    };
    return <Dialog title="Нумерация" shown={true}
                onClose={handleNumeration}
                buttons={[{text: 'Сохранить', data: true}, {text: 'Закрыть'}]}>
            {numeration === null ? <span>Загрузка...</span> : <>
            <label>
                <Checkbox className={styles.checkBox} onChange={e => setNumeration(e.target.checked)} checked={numeration}/>
                <span className={styles.checkboxLabel}>Включить нумерацию задач</span>
            </label>
            <p className={styles.inputGroup}>
                Шаблон названия задачи (вместо %num подставляется номер) <br/>
                <input className={styles.field} onChange={e => setTitle(e.target.value)} value={title} disabled={!numeration}/>
                {titleError && <small className={styles.error}>{titleError}</small>}
            </p>
            <p className={styles.inputGroup}>
                Номер следующей задачи <br/>
                <input className={styles.field} type="number" min={0} onChange={e => setNumber(e.target.value)} value={number} disabled={!numeration}/>
            </p>
            </>}
        </Dialog>
};

export const DirectoryMoveDialog = ({directory, onClose}) => {
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const handleMove = (data) => {
        if (data) {
            setError("");
            moveDirectory(dispatch, directory, data)
                .then(() => {
                    onClose(true);
                })
                .catch(() => {
                    setError("В эту папку переместить нельзя")
                });
        } else {
            onClose(false);
        }
    };
    return <MoveDialog startId={directory} onClose={handleMove} error={error}
        onChangeDir={() => setError("")}/>
};

const SET_SOURCE = 'set_source';
const REF = 'ref';

export const SelectionSaveDialog = ({onClose}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const startId = useSelector(state => state.main.tree ? state.main.tree.my : null);
    useEffect(() => {
        if (!startId) {
            loadTree(dispatch, '');
        }
    }, [startId]);
    const selection = useSelector(state => state.selectionBar.items);
    const [error, setError] = useState('');
    const [confirmNumerationDir, setConfirmNumerationDir] = useState(null);

    const [mode, setMode] = useState(SET_SOURCE);
    const handleMove = async (directory, numerationConfirmed) => {
        if (!directory) {
            onClose(false);
        }
        setError("");
        try {
            if (mode !== 'ref' && !confirmNumerationDir) {
                const {data} = await http.get(`tree/${directory}/shouldRenumerate/`);
                if (data.renumerate) {
                    setConfirmNumerationDir(directory);
                    return;
                }
            }
            await saveSelection(dispatch, directory, selection, mode, !!numerationConfirmed);
            clearNeighborCache(dispatch);
            history.push('/q/' + directory);
            onClose(true);
        } catch (e) {
            setError("В эту папку сохранить нельзя");
        }
    };

    if (confirmNumerationDir !== null) {
        return <Dialog title="Сохранение подборки" shown={true}
                onClose={data => handleMove(confirmNumerationDir, data)}
                buttons={[{text: 'Да', data: true, autofocus: true}, {text: 'Нет'}]}>
            В этой папке используется автоматическая нумерация задач. Перенумеровать сохраняемые задачи в соответствии с ней?
        </Dialog>;
    }

    const extra = <div className={`${styles.checkBoxGroup} ${styles.bordered}`}>
        <label>
            <Checkbox className={styles.checkBox} onChange={e => setMode(e.target.checked ? SET_SOURCE : '')}
                      checked={mode === SET_SOURCE}/>
            <span className={styles.checkboxLabel}>Поместить ссылку на оригинал в поле "источник" копии</span>
        </label>
        <label>
            <Checkbox className={styles.checkBox} onChange={e => setMode(e.target.checked ? REF : '')}
                      checked={mode === REF}/>
            <span className={styles.checkboxLabel}>Создавать ссылки на задачи вместо копий</span>
        </label>
    </div>;

    return startId && <MoveDialog startId={startId} onClose={handleMove} error={error}
                                  onChangeDir={() => setError("")} title="Сохранение подборки"
                                  buttonText="Сохранить сюда" allowStart={true}
                                  extraMarkup={extra}/>
};

export const ProblemMoveDialog = ({problemId, startId, onClose, isMainRef}) => {
    const [confirmNumerationDir, setConfirmNumerationDir] = useState(null);
    const handleMove = async (destination, numerationConfirmed) => {
        if(!destination) {
            onClose(false);
        }
        if(isMainRef && !confirmNumerationDir) {
            const {data} = await http.get(`tree/${destination}/shouldRenumerate/${problemId}`);
            if (data.renumerate) {
                setConfirmNumerationDir(destination);
                return;
            }
        }
        await http.post(`problems/${problemId}/move`, {to: destination, renumerate: !!numerationConfirmed});
        onClose(true);
    };

    if (confirmNumerationDir !== null) {
        return <Dialog title="Перемещение" shown={true}
                onClose={data => handleMove(confirmNumerationDir, data)}
                buttons={[{text: 'Да', data: true, autofocus: true}, {text: 'Нет'}]}>
            В этой папке используется автоматическая нумерация задач. Переименовать задачу в соответствии с ней?
        </Dialog>;
    }
    return <MoveDialog startId={startId} onClose={handleMove}/>
};


export const DirectoryAttributeSuggestionDialog = ({directory, onClose}) => {
    const [attributeSuggestion, setAttributeSuggestion] = useState(null);
    const [problemSuggestion, setProblemSuggestion] = useState(null);
    useEffect(() => {
        http.get(`tree/${directory}/attributeSuggestion`).then(({data}) => {
            setAttributeSuggestion(data.attribute_suggestion);
            setProblemSuggestion(data.problem_suggestion);
        });
    }, []);
    const handleAttributeSuggestion = (data) => {
        if (data) {
            http.put(`tree/${directory}/attributeSuggestion`,
                {attribute_suggestion: attributeSuggestion, problem_suggestion: problemSuggestion})
                .then(() => onClose(true));
        } else {
            onClose(false);
        }
    };
    return <Dialog title="Предложение атрибутов" shown={true}
                onClose={handleAttributeSuggestion}
                buttons={[{text: 'Сохранить', data: true}, {text: 'Закрыть'}]}>
            {(attributeSuggestion === null || problemSuggestion === null) ? <span>Загрузка...</span> :
            <div className={styles.checkBoxGroup}>
            <label>
                <Checkbox className={styles.checkBox} onChange={e => setAttributeSuggestion(e.target.checked)} checked={attributeSuggestion}/>
                <span className={styles.checkboxLabel}>Разрешить пользователям предлагать атрибуты для задач в папке</span>
            </label>
            <label>
                <Checkbox className={styles.checkBox} onChange={e => setProblemSuggestion(e.target.checked)} checked={problemSuggestion}/>
                <span className={styles.checkboxLabel}>Разрешить пользователям предлагать задачи в эту папку</span>
            </label>
            </div>}
        </Dialog>
};

const EditEnum = ({name, tags, onSave, onCancel}) => {
    const [curName, setCurName] = useState(name);
    const [curTags, setCurTags] = useState(tags);
    return <div>
        <p className={styles.groupName}>
        <input value={curName} placeholder="Название группы" autoFocus={true} onChange={e => setCurName(e.target.value)}/>
        <img src={confirmEditingIconImage} className={styles.groupEditIcon}
                         onClick={() => onSave(curName, curTags.filter(x => x))} alt="Сохранить"/>
        <img src={rejectEditingIconImage} className={styles.groupEditIcon}
             onClick={onCancel} alt="Отменить"/>
        </p>
        <ul className={styles.groupLabels}>
        {curTags.map((x, i) => <li key={i}>
            <input value={x} placeholder="Метка" onChange={e => {
                const value = e.target.value;
                setCurTags(tags =>
                    tags.map((cx, ci) => ci === i ? value : cx)
                );
            }}/><span className={styles.deleteTag} onClick={() => setCurTags(tags =>
                tags.filter((cx, ci) => ci !== i)
        )}>×</span>
        </li>)}
        <li><a href="" onClick={e => {
            e.preventDefault();
            setCurTags(t => t.concat([""]));
        }}>Добавить метку</a></li>
        </ul>
    </div>
};

const Enum = ({name, tags, onEdit, onDelete}) => {
    return <div>
        <p className={styles.groupName}>{name}
        <img src={editIconImage} className={styles.groupEditIcon} alt="Редактировать" onClick={onEdit}/>
        <img src={trashCanImage} title="Удалить" alt="Удалить"
                                                className={styles.groupEditIcon + ' ' + styles.groupDeleteIcon}
                                                height={16} onClick={onDelete}/>
        </p>
        <ul className={styles.groupLabels}>
        {tags.map(x => <li className={tagStyles.tag + ' ' + styles.label}>{x}</li>)}
        {tags.length === 0 && <i>меток нет</i>}
        </ul>
    </div>
}

export const DirectoryEnumDialog = ({directory, onClose}) => {
    const [enums, setEnums] = useState([]);
    const [newEnum, setNewEnum] = useState(false);
    const [editingEnum, setEditingEnum] = useState(null);
    useEffect(() => {
        http.get(`tree/${directory}/enums/`)
            .then(({data}) => setEnums(data));
    }, []);
    const deleteEnum = id => {
        setEditingEnum(null);
        http.delete(`tree/${directory}/enums/${id}`)
            .then(() => {
                setEnums(enums => enums.filter(x => x.id !== id));
            })
    };
    const createEnum = (name, tags) => {
        http.post(`tree/${directory}/enums/`, {name, tags})
            .then(({data}) => {
                setNewEnum(false);
                setEnums(data);
            })
    };
    const updateEnum = id => (name, tags) => {
        http.put(`tree/${directory}/enums/${id}`, {name, tags})
            .then(({data}) => {
                setEnums(enums => enums.map(x => x.id === id ? data : x));
                setEditingEnum(null);
            })
    };
    return <Dialog title="Фиксированные метки" shown={true}
                onClose={onClose}
                buttons={[{text: 'Закрыть'}]}>
        {enums.map(x =>
            x.id === editingEnum ?
                <EditEnum name={x.name} tags={x.tags} key={x.id} onCancel={() => setEditingEnum(null)}
                          onSave={updateEnum(x.id)}/> :
                <Enum name={x.name} tags={x.tags} key={x.id} onEdit={() => setEditingEnum(x.id)}
                      onDelete={() => deleteEnum(x.id)}/>)
        }
        {newEnum && editingEnum === null && <EditEnum name="" tags={["", ""]} onCancel={() => setNewEnum(false)}
            onSave={createEnum}/>}
        {!newEnum && editingEnum === null && <p><a href="" onClick={(e) => {
            e.preventDefault();
            setNewEnum(true);
        }}>Добавить группу</a></p>}
        </Dialog>
}

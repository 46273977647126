import React, {useEffect, useState} from "react";
import columnStyles from "../../columns.module.scss";
import {Link} from "react-router-dom";
import styles from "./styles.module.scss";
import tableStyles from "../table.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {loadGroup, loadGroups, saveGroup} from "../../../../reducers/contest";
import listStyles from "../../problemlist.module.scss";
import {CreateEditContestDialog, CreateGroupDialog} from "../dialogs";
import bookmarkUnset from "assets/media/bookmark_unset.svg";
import bookmarkSet from "assets/media/bookmark_set.svg";
import lockIcon from "assets/media/lock.svg";
import GroupActions from "./GroupActions";

const ERRORS = {
    404: "Такой группы соревнований не существует",
};


const ContestList = ({title, group, contests}) => (
    <>
        <p className={styles.sectionTitle}>{title}</p>
        <table className={tableStyles.table}>
            <thead><tr><th>Соревнование</th><th>Начало</th></tr></thead>
            <tbody>
            {contests.map(c => <tr key={c.number}>
                <td className={tableStyles.alignLeft}><Link to={`/contests/${group}/${c.number}/`}>{c.name}{!c.visible && " (скрыто)"}
                    {c.is_closed && <img src={lockIcon} alt="закрыто" className={styles.lockIcon} title="Ограниченний список участников"/>}</Link></td>
                <td className={c.visible ? "" : tableStyles.gray}>{c.start_time === null ? <i>неизвестно</i> : new Date(c.start_time).toLocaleString()}</td>
            </tr>)}</tbody>
        </table>
    </>
);

const GroupsList = ({selectedId}) => {
    const dispatch = useDispatch();
    const groups = useSelector(state => state.contest.groups);
    useEffect(() => {
        if (!groups.loaded) {
            loadGroups(dispatch);
        }
    }, [groups.loaded]);
    const [createDialogShown, setCreateDialogShown] = useState(false);

    return <div className={listStyles.neighborsContainer}>
        <p><b>Мои группы:</b></p>
        <ul className={listStyles.neighborsList}>
            {groups.my.map(p => <li key={p.id} className={p.id === selectedId ? listStyles.selected : ""}>
                <Link to={`/contests/${p.id}`}>{p.name}</Link></li>)
            }
            <li className={listStyles.bordered}>
                <a href="" onClick={e => {
                    e.preventDefault();
                    setCreateDialogShown(true);
                }}>Создать группу</a></li>
        </ul>
        {groups.saved.length > 0 && <>
        <p><b>Сохраненные группы:</b></p>
        <ul className={listStyles.neighborsList}>
            {groups.saved.map(p => <li key={p.id} className={p.id === selectedId ? listStyles.selected : ""}>
                <Link to={`/contests/${p.id}`}>{p.name}</Link></li>)
            }
        </ul>
        </>}
        {createDialogShown && <CreateGroupDialog onClose={() => setCreateDialogShown(false)}/>}
        </div>;
};

const ContestGroupView = ({match}) => {
    const {group} = match.params;
    const data = useSelector(state => state.contest.group);
    const [createDialogShown, setCreateDialogShown] = useState(false);
    const dispatch = useDispatch();
    const authenticated = useSelector(state => !!state.auth.authorized);
    const isSaved = useSelector(state => state.contest.groups.saved.some(g => g.id === group));
    useEffect(() => {
        loadGroup(dispatch, group);
    }, [group]);

    const past = data.contests?.filter(c => c.status === "past") || [];
    const present = data.contests?.filter(c => c.status === "present") || [];
    const future = data.contests?.filter(c => c.status === "future") || [];

    return (
        <div className={columnStyles.mainWrapper}>
            <div className={columnStyles.leftColumn}>
                {authenticated && <GroupsList selectedId={group}/>}
            </div>
            <div className={columnStyles.centralWrapper}>
                {data.error ? <p>{ERRORS[data.error] || "Ошибка загрузки группы соревнований"}</p> :
                 data.loading ? <p>Загрузка...</p> : <>
                    <div className={styles.titleContainer}>
                        <h1 className={styles.groupTitle}>{authenticated && group !== 'public' && (
                        <img src={isSaved ? bookmarkSet : bookmarkUnset} width={24} className={styles.bookmark}
                             title={isSaved ? "Удалить из сохраненных" : "Сохранить"}
                             alt={isSaved ? "Удалить из сохраненных" : "Сохранить"}
                             onClick={() => saveGroup(dispatch, group, isSaved)}/>
                    )}{data.name}</h1>
                        {!!data.can_manage && <GroupActions groupId={group}/>}
                    </div>
                    {!!data.can_manage && <a href="" className={styles.createContestLink} onClick={e => {
                        e.preventDefault();
                        setCreateDialogShown(true);
                    }}>Создать соревнование</a>}
                    {present.length > 0 && <ContestList title="Сейчас идут" group={group} contests={present}/>}
                    {future.length > 0 && <ContestList title="Будущие соревнования" group={group} contests={future}/>}
                    <ContestList title="Прошедшие соревнования" group={group} contests={past}/>
                </>}
            </div>
            <div className={columnStyles.rightColumn}/>
            {createDialogShown && <CreateEditContestDialog onClose={() => setCreateDialogShown(false)}
                                                           groupId={group}/>}
        </div>);
};

export default ContestGroupView;

import columnStyles from '../columns.module.scss';
import React, {memo} from 'react';
import styles from './styles.module.scss';
import {connect, useDispatch, useSelector} from "react-redux";
import {TaskList} from '../../taskList';
import TrackVisibility from 'react-on-screen';
import {Loader} from 'utils/Loader'
import {loadMoreProblems, updateLocationAndSearch, updateOrder, updateSelection} from "../../../reducers/main";
import Search from "./search";
import AttributeSearch from "./attributeSearch";
import DirectoryTree from './directoryTree';
import Navigation, {DirPath} from "./navigation";
import SearchOrder from "./order";
import DirectoryActions from "./directoryActions";
import {getDirectoryError} from "./directoryErrors";
import {addManyToSelection} from "../../../reducers/selectionBar";


const SelectAll = () => {
    const tasks = useSelector(state => state.main.tasks || []);
    const dispatch = useDispatch();
    const selectAll = (e) => {
        e.preventDefault();
        addManyToSelection(dispatch, tasks.map(x => ({id: x.id, title: x.title})));
    };
    return <a href="" onClick={selectAll} title="Добавить в подборку все отображаемые задачи">Выбрать все</a>
};

const MainContent = memo(({tasks, searchMode, isLoading, path, tasksError,
                              updateLocationAndSearch, updateOrder}) => {
    return <>
        <DirPath data={path}>
            {searchMode || <DirectoryActions/>}
        </DirPath>
        <Search updateLocationAndSearch={updateLocationAndSearch}/>
        {searchMode === true && <div className={styles.underBar}>
            <SelectAll/>
            <SearchOrder updateOrder={updateOrder}/>
        </div>}
        {searchMode === true && (tasksError ? <p>{getDirectoryError(tasksError)}</p> :
            ((isLoading || tasks.length) ? <TaskList data={tasks} error={tasksError}/> : <p>Ничего не найдено</p>))}
        {searchMode === false && <Navigation isLoading={isLoading} />}
    </>;
});

@connect(
    state => state.main,
)
class Main extends React.Component {

    loadExtraProblems = () => {
        if (!this.props.isLoading && !this.props.allPagesLoaded) {
            loadMoreProblems(this.props.dispatch, this.props.loadingType, this.props.query, this.props.lastPageLoaded);
        }
    };

    selectDir = dir => {
        updateSelection(this.props.dispatch, dir, this.props);
    };

    updateOrder = order => {
        updateOrder(this.props.dispatch, order, this.props);
    };

    updateLocationAndSearch = (searchMode) => {
        updateLocationAndSearch(this.props.dispatch, this.props, searchMode);
    };

    render() {
        return <div className={columnStyles.mainWrapper}>
            <div className={columnStyles.leftColumn}>
                <DirectoryTree selectDir={this.selectDir}/>
            </div>
            <div className={columnStyles.centralWrapper}>
                <MainContent tasks={this.props.tasks} searchMode={this.props.searchMode}
                             isLoading={this.props.isLoading} path={this.props.path}
                             tasksError={this.props.tasksError}
                             updateLocationAndSearch={this.updateLocationAndSearch} updateOrder={this.updateOrder}/>
                <TrackVisibility>
                    <Loader onLoad={this.loadExtraProblems} allPagesLoaded={this.props.allPagesLoaded}/>
                </TrackVisibility>
            </div>
            <div className={columnStyles.rightColumn}>
                <AttributeSearch updateLocationAndSearch={this.updateLocationAndSearch}/>
            </div>
        </div>
    }
}

export default Main;

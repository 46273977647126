import React, {useState} from 'react';
import styles from './styles.module.scss';
import arrowIcon from "../../../assets/media/header_arrow_icon.svg";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {deleteFromSelection} from "../../../reducers/selectionBar";
import {SelectionSaveDialog} from "../../dialogs";


const SelectionItem = ({name, id, selected, onDelete}) => (
    <li className={selected ? styles.current : ""}>
        <Link className={styles.link} to={"/problem/" + id}>{name}</Link>
        <a className={styles.delete} onClick={onDelete}>&#10005;</a>
    </li>
);

const Selection = ({visible, onClose})=> {
    const [showDialog, setShowDialog] = useState(false);
    const selectionBar = useSelector(state => state.selectionBar);
    const authenticated = useSelector(state => state.auth.authorized);
    const dispatch = useDispatch();
    const location = useLocation();
    return <div className={styles.dropdown} style={{display: visible ? "block" : "none"}}>
        <div className={styles.barHeader}>Текущая подборка</div>
        <ul>
            {selectionBar.items.length ?
                selectionBar.items.map(({id, title}) =>
                    <SelectionItem key={id} name={title} id={id} selected={location.pathname.startsWith('/problem/' + id)}
                                   onDelete={() => deleteFromSelection(dispatch, id)}/>)
                : <p className={styles.emptySelection}>Пока подборка пуста</p>}
        </ul>
        {!!selectionBar.items.length && <>
        <Link to='/sheet' className={styles.selectionAction + ' ' + styles.goToProblemsheet} onClick={onClose}>
            Перейти в подборку
        </Link>
        {authenticated && <div className={styles.selectionAction + ' ' + styles.save} onClick={() => setShowDialog(true)}>
            Сохранить в папку
        </div>}
        </>}
        {showDialog && <SelectionSaveDialog onClose={done => {
            setShowDialog(false);
            if (done) {
                onClose();
            }
        }}/>}
    </div>;
};


const SelectionDropdown = () => {
    const [show, setShow] = useState(false);
    return <>
        <div onClick={() => setShow(!show)} className={(show ? styles.selected + " " : "") + styles.selectionIcon}>
            Подборка <img src={arrowIcon} alt="" width={12}/>
        </div>
        <Selection visible={show} onClose={() => setShow(false)}/>
    </>
};

export default SelectionDropdown;

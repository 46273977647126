import React from "react";
import styles from './styles.module.scss';
import {connect} from "react-redux";
import {updateSearchQuery} from "../../../../reducers/main";
import {withRouter} from "react-router";
import {ListEditor} from "../../../listEditor";
import http from "../../../../utils/axios";
import {LabelAutosuggest} from "../../../labelAutosuggest";


const PrefixedInput = ({prefix, value, onChange, onKeyPress, type = 'number'}) => {
    return <div className={styles.inputBox}>
        <input type={type} value={value} onChange={onChange} onKeyPress={onKeyPress}/>
        <span>{prefix}</span>
    </div>;
};

@connect(
    state => ({...state.main.selectedQuery,
               query: null,
               searchMode: state.main.searchMode}),
    dispatch => ({
        dispatch,
        updateValue(data) {
            dispatch(updateSearchQuery(data));
        },
    })
)
class AttributeSearch extends React.Component {

    state = {
        suggestions: [],
        prefix: null,
    };


    componentDidUpdate(prevProps, prevState) {
        if (this.props.directory !== prevProps.directory) {
            this.fetchSuggestions(this.state.prefix);
        }
    }

    fetchSuggestions(prefix) {
        if (prefix === null) {
            return;
        }
        http.get(`tree/${this.props.directory || 'default'}/tags?prefix=${encodeURIComponent(prefix)}`)
            .then(({data}) => this.setState({suggestions: data}));
    }

    cancelSearch = () => {
        this.props.updateLocationAndSearch(false);
    };

    doSearch = () => {
        this.props.updateLocationAndSearch(true);
    };

    render() {
        return <div className={styles.attributeSearch}>
            <p className={styles.attrName}>Класс</p>
            <PrefixedInput prefix={"От"} value={this.props.grade_from}
                           onKeyPress={this.handleEnterPress}
                           onChange={event => {
                               if (event.target.value === "" || (event.target.value <= 15 && event.target.value >= 1)) {
                                   this.props.updateValue({grade_from: event.target.value});
                               }
                           }}/>
            <PrefixedInput prefix={"До"} value={this.props.grade_to}
                           onKeyPress={this.handleEnterPress}
                           onChange={event => {
                               if (event.target.value === "" || (event.target.value <= 15 && event.target.value >= 1)) {
                                   this.props.updateValue({grade_to: event.target.value})
                               }
                           }}/>
            <p className={styles.attrName}>Сложность</p>
            <PrefixedInput prefix={"От"} value={this.props.difficulty_from}
                           onKeyPress={this.handleEnterPress}
                           onChange={event => {
                               if (event.target.value === "" || (event.target.value <= 10 && event.target.value >= 1)) {
                                   this.props.updateValue({difficulty_from: event.target.value})
                               }
                           }}/>
            <PrefixedInput prefix={"До"} value={this.props.difficulty_to}
                           onKeyPress={this.handleEnterPress}
                           onChange={event => {
                               if (event.target.value === "" || (event.target.value <= 10 && event.target.value >= 1)) {
                                   this.props.updateValue({difficulty_to: event.target.value})
                               }
                           }}/>
            <p className={styles.attrName}>Год</p>
            <PrefixedInput prefix={"От"} value={this.props.year_from}
                           onKeyPress={this.handleEnterPress}
                           onChange={event => {
                               if (event.target.value === "" || (event.target.value <= 2100 && event.target.value >= 1)) {
                                   this.props.updateValue({year_from: event.target.value})
                               }
                           }}/>
            <PrefixedInput prefix={"До"} value={this.props.year_to}
                           onKeyPress={this.handleEnterPress}
                           onChange={event => {
                               if (event.target.value === "" || (event.target.value <= 2100 && event.target.value >= 1)) {
                                   this.props.updateValue({year_to: event.target.value})
                               }
                           }}/>
            <p className={styles.attrName}>Метки</p>
            <ListEditor objects={this.props.labels.split(',').filter(x => x)}
                        onAdd={x => this.changeSelection('labels', x, true)}
                        onDelete={x => this.changeSelection('labels', x, false)}
                        renderInput={this.renderInput} />
            <label className={styles.labelSearchType}><input type="checkbox" name="labelMode" value="or"
                                                             checked={!!this.props.labels_or}
                                                             onChange={this.changeLabelMode}
                                                             disabled={this.props.labels.split(',').length < 2}/> хотя бы одна</label>

            <label className={styles.labelSearchType}><input type="checkbox"
                                                             checked={!!this.props.with_answer}
                                                             onChange={this.changeWithAnswer}/> Только с ответом</label>
            <div className={styles.searchButton + ' ' + styles.blueButton} onClick={this.doSearch}>
                Найти
            </div>
            {this.props.searchMode &&
            <div className={styles.searchButton + ' ' + styles.yellowButton} onClick={this.cancelSearch}>
                Сброс
            </div>}
        </div>;
    }

    suggestionsFetchRequested = (value) => {
        this.setState({prefix: value.value});
        this.fetchSuggestions(value.value);
    };

    suggestionsClearRequested = () => {
        this.setState({prefix: null, suggestions: []});
    };

    renderInput = (props) => {
        return <LabelAutosuggest inputProps={{...props, className: styles.tagsInput}}
                                 suggestions={this.state.suggestions}
                                 fetchRequested={this.suggestionsFetchRequested}
                                 clearRequested={this.suggestionsClearRequested} />
    };

    handleEnterPress = (e) => {
        if (e.key === 'Enter') {
            this.doSearch();
        }
    };

    changeSelection = (type, item, checked) => {
        let cur = this.props[type].split(',').filter(x => x);
        if (checked && !cur.find(x => x === item)) {
            cur.push(item);
        } else if(!checked) {
            cur = cur.filter(x => x !== item);
        }
        let update = {};
        update[type] = cur.join(',');
        this.props.updateValue(update);
        setTimeout(this.doSearch, 0);
    };

    changeLabelMode = (event) => {
        const labels_or = event.target.checked ? 1 : '';
        this.props.updateValue({labels_or});
        setTimeout(this.doSearch, 0);
    };

    changeWithAnswer = (event) => {
        const with_answer = event.target.checked ? 1 : '';
        this.props.updateValue({with_answer});
        setTimeout(this.doSearch, 0);
    };

    getSelection = (type) => {
        return this.props[type].split(',');
    }
}

export default withRouter(AttributeSearch);

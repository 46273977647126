import React, {useEffect} from "react";
import listStyles from '../../problemlist.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {expectProblemSameDir, loadProblemNeighbors} from "../../../../reducers/task";
import {Link} from "react-router-dom";
import {buildTitle} from "../../../../utils/buildTitle";


const Item = ({name, id, selected, onClick}) => (
    <li>
        <Link className={selected ? listStyles.selected : ""} to={"/problem/" + id} onClick={() => onClick(id)}>{name}</Link>
    </li>
);


const ProblemNeighbors = ({id}) => {
    const dispatch = useDispatch();
    const directory = useSelector(state => state.task.directory);
    const neighbors = useSelector(state => state.task.neighbors);
    useEffect(() => {
        if (directory && (!neighbors || directory !== neighbors.directory)) {
            loadProblemNeighbors(dispatch, directory);
        }
        },
        [directory, neighbors]);
    return <div className={listStyles.neighborsContainer}>
        <p><b>Задачи в этой папке:</b></p>
        <ul className={listStyles.neighborsList}>{neighbors && directory === neighbors.directory &&
        neighbors.problems.map(p => <Item key={p.id} name={buildTitle(p) + (p.is_suggested ? " (предложено)" : "")} id={p.id} selected={p.id === id} onClick={id => {
            expectProblemSameDir(dispatch, id, directory);
        }
        }/>)
        }
        {neighbors && directory === neighbors.directory && neighbors.canEdit && <li className={listStyles.bordered}>
            <Link to={"/createProblem/" + directory}>Создать задачу</Link>
        </li> }
        </ul></div>
};


export default ProblemNeighbors;

import {storeFactory} from "./reducers";
import http from "./utils/axios";
import {checkSessionExpiration} from "./reducers/auth";

export const store = storeFactory({});

http.interceptors.response.use(response => response, error => {
    if (error.response?.status === 403) {
        checkSessionExpiration(store.dispatch);
    }
    return Promise.reject(error);
});

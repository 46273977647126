import React from 'react';
import styles from './styles.module.scss';
import ShowSolution from "./showSolution";
import HideSolution from "./hideSolution";
import EditableTextArea from '../../../editableTextArea';
import {connect} from "react-redux";
import {
    clearNeighborCache,
    deleteProblem,
    deleteProblemAttachment,
    loadProblemPage,
    updateProblemPage,
    uploadProblemAttachment
} from "../../../../reducers/task";
import AddToSelectionButton from "../../../addToSelectionButton";
import trashCanImage from 'assets/media/trash_can.svg'
import moveIconImage from 'assets/media/move.svg'
import keyImage from 'assets/media/key.svg'
import Dialog from "../../../dialog";
import {withLastLocation} from 'react-router-last-location';
import {AclDialog} from "../../main/aclDialog";
import TextWithLinks from "../../../../utils/TextWithLinks";
import AttachmentList from "./attachmentList";
import http from "../../../../utils/axios";
import {DirPath} from "../../main/navigation";
import {Link} from "react-router-dom";
import UserCard from "../../../userCard";
import {ProblemMoveDialog} from "../../../dialogs";

const ERRORS = {
    404: "Такой задачи не существует",
    403: "У вас нет доступа к этой задаче",
};

@connect(
    state => state.task,
    dispatch => ({
        dispatch,
        loadProblem(id) {
            loadProblemPage(dispatch, id);
        },
        updateProblem(id, prop, value, callback) {
            let data = {};
            data[prop] = value;
            const update = updateProblemPage(dispatch, id, data, callback);
            if (prop === 'title') {
                update.then(() => clearNeighborCache(dispatch));
            }
        },
        deleteProblem(id) {
            return deleteProblem(dispatch, id);
        },
    }),
)
class TaskContent extends React.Component {
    state = {
        isOpenSolution: false,
        deleteDialogOpen: false,
        aclDialogOpen: false,
        moveDialogOpen: false,
    };

    handleClickDeleteProblem = () => {
        this.setState({deleteDialogOpen: true});
    };

    handleDeleteProblem = (approved) => {
        this.setState({deleteDialogOpen: false});
        if (!approved) {
            return;
        }
        this.props.deleteProblem(this.props.id)
            .then(() => {
                this.props.history.push(this.props.directory ? `/q/${this.props.directory}` : '/');
            })
            .catch(({data}) => {
                console.log('Error during deleting problem: ', data);
            });
    };

    handleClickSolutionButton = () => {
        this.setState({
            isOpenSolution: !this.state.isOpenSolution
        })
    };

    handleClickMove = () => {
        this.setState({moveDialogOpen: true});
    };

    handleMoveClose = (success) => {
        this.setState({moveDialogOpen: false});
        if (success) {
            this.props.loadProblem(this.props.id);
        }
    };

    handleFieldEdit = field => (value, callback) => {
        this.props.updateProblem(this.props.id, field, value, callback);
    };

    handleShowAcl = () => {
        this.setState({aclDialogOpen: true});
    };

    handleAclClose = () => {
        this.setState({aclDialogOpen: false});
    };

    componentDidMount() {
        this.props.loadProblem(this.props.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id !== this.props.id) {
            this.props.loadProblem(this.props.id);
        }
    }

    deleteAttachment = (file) => {
        deleteProblemAttachment(this.props.dispatch, this.props.id, file);
    };

    uploadFile = (file) => {
        uploadProblemAttachment(this.props.dispatch, this.props.id, file);
    };

    deleteSolutionAttachment = (file) => {
        deleteProblemAttachment(this.props.dispatch, this.props.id, file, true);
    };

    uploadSolutionFile = (file) => {
        uploadProblemAttachment(this.props.dispatch, this.props.id, file, true);
    };

    handleRejectSuggestion = () => {
        this.handleDeleteProblem(true);
    };

    handleAcceptSuggestion = () => {
         http.post(`problems/${this.props.id}/acceptSuggestion`, {})
                .then(() => {
                    this.props.loadProblem(this.props.id);
                    clearNeighborCache(this.props.dispatch);
                });
    };

    render() {
        const message =
            <div className={styles.messageContainer}>
                Ваша задача сохранена.<br/>На данной странице Вы можете ее редактировать.
                <div className={styles.addAnotherProblemButton} onClick={this.props.history.goBack}>
                    Загрузить еще одну задачу
                </div>
            </div>;

        const path = <DirPath data={this.props.path || []}/>;

        const title =
            <div className={styles.titleContainer}>
                <EditableTextArea value={this.props.title}
                                  onEdit={this.handleFieldEdit('title')}
                                  canEdit={this.props.canEditProblem && !this.props.restricted}
                                  useMathJax={false}
                                  areaStyle={{
                                      fontSize: '1.5em', fontWeight: 'bolder', display: 'flex',
                                      flexDirection: 'row', alignItems: 'center'
                                  }}
                                  editingAreaStyle={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                                  editingAreaInputFieldStyle={{fontSize: '1.5em'}}/>

                <div className={styles.iconContainer}>
                    {this.props.canAdmin &&
                    <img src={keyImage} title={"Права доступа"} alt={"Права"} className={styles.titleProblemButton}
                         onClick={this.handleShowAcl}/>}
                    {this.props.canEdit && this.props.directory && !this.props.restricted &&
                    <img src={moveIconImage} title={"Переместить задачу"} alt={"Переместить"}
                         className={styles.titleProblemButton} onClick={this.handleClickMove}/>}
                    {this.props.canEdit && <img src={trashCanImage} title={"Удалить задачу"} alt={"Удалить"}
                                                className={styles.titleProblemButton}
                                                onClick={this.handleClickDeleteProblem}/>}
                    <AddToSelectionButton id={this.props.id} title={this.props.title}/>
                </div>
            </div>;

        const text =
            <div>
                <EditableTextArea value={this.props.text} copyable={true}
                                  onEdit={this.handleFieldEdit('text')}
                                  canEdit={this.props.canEditProblem}
                                  useMathJax={true}
                                  areaStyle={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      paddingTop: '1em',
                                      paddingBottom: '0.5em'
                                  }}
                                  editingAreaStyle={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                                  editingAreaInputFieldStyle={{height: '10em', width: '100%', fontSize: '1em'}}/>
            </div>;

        const attachments = this.props.files && <AttachmentList files={this.props.files}
                                                                canEdit={this.props.canEditProblem}
                                                                onDelete={this.deleteAttachment}
                                                                onUpload={this.uploadFile}/>;

        const source = (this.props.canEditProblem || !!this.props.source) &&
            <div className={styles.externalSourceContainer}>
                <div>
                    Источник:
                </div>
                <div className={styles.sourceContainer}>
                    <EditableTextArea value={this.props.source}
                                      onEdit={this.handleFieldEdit('source')}
                                      canEdit={this.props.canEditProblem}
                                      small={true}
                                      areaStyle={{display: 'flex', flexDirection: 'row'}}
                                      editingAreaStyle={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                      }}
                                      editingAreaInputFieldStyle={{height: '10em', width: '100%', fontSize: '1em'}}/>
                </div>
            </div>;

        const author = (this.props.canEditProblem || !!this.props.author) &&
            <div>
                Автор: <div className={styles.sourceContainer}>
                <EditableTextArea value={this.props.author}
                                  onEdit={this.handleFieldEdit('author')}
                                  canEdit={this.props.canEditProblem}
                                  small={true}
                                  areaStyle={{display: 'flex', flexDirection: 'row'}}
                                  editingAreaStyle={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                  }}
                                  editingAreaInputFieldStyle={{height: '10em', width: '100%', fontSize: '1em'}}/>
            </div>
            </div>;

        const email = this.props.email &&
            <div>
                Email для связи: <TextWithLinks>{this.props.email}</TextWithLinks>
            </div>;

        const solutionButton = (this.props.canEditProblem || this.props.solution !== null) &&
            <div className={styles.solutionButton} onClick={this.handleClickSolutionButton}>
                {this.state.isOpenSolution ? <HideSolution/> : <ShowSolution/>}
            </div>;

        const solution = (this.props.canEditProblem || this.props.solution !== null) &&
            <div>
                {this.state.isOpenSolution
                    ? <>{(this.props.canEditProblem || !!this.props.answer) &&
                    <div><b>Ответ: </b><div className={styles.sourceContainer}><EditableTextArea
                                        value={this.props.answer}
                                        onEdit={this.handleFieldEdit('answer')}
                                        canEdit={this.props.canEditProblem}
                                        small={true}
                                        areaStyle={{display: 'flex', flexDirection: 'row'}}/>
                    </div></div>}
                        <EditableTextArea value={this.props.solution} copyable={true}
                                          onEdit={this.handleFieldEdit('solution')}
                                          canEdit={this.props.canEditProblem}
                                          useMathJax={true}
                                          areaStyle={{display: 'flex', flexDirection: 'row', paddingTop: '0.5em'}}
                                          editingAreaStyle={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              alignItems: 'center'
                                          }}
                                          editingAreaInputFieldStyle={{
                                              height: '10em',
                                              width: '100%',
                                              fontSize: '1em'
                                          }}/>
                        {this.props.solutionFiles && <AttachmentList files={this.props.solutionFiles}
                                                                     canEdit={this.props.canEditProblem}
                                                                     onDelete={this.deleteSolutionAttachment}
                                                                     onUpload={this.uploadSolutionFile}/>
                        }
                    </>
                    : <div/>}
            </div>;

        const verdict = this.props.verdict !== undefined && (!!this.props.verdict || this.props.canEditProblem) && <div>
            <br/><b>Вердикт: </b><div className={styles.sourceContainer}><EditableTextArea
                                    value={this.props.verdict}
                                    onEdit={this.handleFieldEdit('verdict')}
                                    canEdit={this.props.canEditProblem}
                                    small={true}
                                    areaStyle={{display: 'flex', flexDirection: 'row'}}/>
            </div></div>

        const mainRef = this.props.mainRef !== undefined && <small>
            {this.props.mainRef ?
                <Link to={`/problem/${this.props.mainRef}`}>Оригинал задачи</Link> :
                <span>Оригинал задачи недоступен</span>}
        </small>;

        const suggested = this.props.suggestedBy &&
            <div>Предложил <UserCard data={this.props.suggestedBy}/>
                {this.props.neighbors && this.props.neighbors.canEdit &&
                <div className={styles.suggestionButtons}><button className={styles.suggestionButton + ' ' + styles.suggestionAccept}
                        onClick={this.handleAcceptSuggestion}>Принять</button>
                     <button className={styles.suggestionButton + ' ' + styles.suggestionReject}
                        onClick={this.handleRejectSuggestion}>Отклонить</button></div>}
            </div>;

        return <div className={styles.taskContent}>
            {this.props.isLoading ? <>{path}<p>Загрузка...</p></> :
                this.props.error ? <p>{ERRORS[this.props.error] || "Ошибка загрузки задачи"}</p> :
                    <>
                        {this.props.lastLocation !== null
                        && this.props.lastLocation["pathname"].split("/")[1] === "form"
                        && message
                        }
                        {path}
                        {mainRef}
                        {suggested}
                        {title}
                        {text}
                        {attachments}
                        {source}
                        {author}
                        {email}
                        {solutionButton}
                        {solution}
                        {verdict}
                        {this.state.deleteDialogOpen && <Dialog title={"Удаление задачи"} shown={true}
                                onClose={this.handleDeleteProblem}
                                buttons={[{text: 'Да', data: true, autofocus: true}, {text: 'Нет'}]}>
                            Вы уверены, что хотите удалить задачу?
                        </Dialog>}
                        {this.state.aclDialogOpen &&
                        <AclDialog id={this.props.id} forProblem={true} name={this.props.title || 'Задача'}
                                   onClose={this.handleAclClose}/>}
                        {this.state.moveDialogOpen && <ProblemMoveDialog startId={this.props.directory}
                                    onClose={this.handleMoveClose} problemId={this.props.id}
                                    isMainRef={this.props.mainRef === undefined}/>}
                    </>
            }
        </div>
    }
}

export default withLastLocation(TaskContent);

import React from 'react';
import {Loading} from '../Loading';


export class Loader extends React.Component {
    componentDidUpdate(prevProps, prevState) {
        if (this.props.isVisible && !prevProps.isVisible) {
            this.props.onLoad();
        }
    }

    render() {
        return this.props.isVisible && !this.props.allPagesLoaded ?
            <Loading/> :
            <div></div>
    }
}

import React from 'react';
import styles from './styles.module.scss';
import {Link} from 'react-router-dom';
import {MathText} from "../../utils/MathText";
import AddToSelectionButton from "../addToSelectionButton";
import {DirPath} from "../views/main/navigation";
import tagStyles from "../views/tags.module.scss";
import {buildTitle} from "../../utils/buildTitle";
import CopyButton from "../copyButton";
import CommentIcon from "assets/media/comments.svg";

const Attributes = ({data}) => {
    return <div className={styles.attributes}>
        {(data.grade_low && data.grade_high) && <span><b>Класс:</b> {
        data.grade_low === data.grade_high ? data.grade_low : <>{data.grade_low} &mdash; {data.grade_high}</>}</span>}
        {(data.difficulty_low && data.difficulty_high) && <span><b>Сложность:</b> {
        data.difficulty_low === data.difficulty_high ? data.difficulty_low :
            <>{data.difficulty_low} &mdash; {data.difficulty_high}</>}</span>}
        {data.year && <span><b>Год:</b> {data.year}</span>}
        {data.with_answer && <span><b>С ответом</b></span>}
        {!!data.comment_count && <span className={styles.comments}>
            {data.comment_count} <img src={CommentIcon} alt={"comments"} height={16}/> </span>}
    </div>;
};

class Task extends React.Component {

    render() {
        return <div className={styles.taskContainer} data-suggested={this.props.data.is_suggested}>
                {this.props.data.path && <DirPath small={true} data={this.props.data.path}/>}
            <div className={styles.topBar}>
                 <Link className={styles.taskContainer__titleText}
                      to={'/problem/' + this.props.id}>{buildTitle(this.props.data)}</Link>
                 {this.props.data.is_suggested && <span className={styles.suggested}> (предложено)</span>}
                 <AddToSelectionButton id={this.props.id} title={this.props.data.title}/>
            </div>
                <div className={styles.taskContainer__bodyElement}>
                    <div className={tagStyles.tagList + ' ' + tagStyles.green}>
                        {this.props.data.labels && this.props.data.labels.map(text => <span key={text} className={tagStyles.tag}>{text}</span>)}
                    </div>
                    <div className={styles.taskText}>
                        <div>{this.props.data.text && <MathText text={this.props.data.text}/>}</div>
                        {this.props.data.text && <CopyButton text={this.props.data.text} small={true}/>}
                    </div>
                    <Attributes data={this.props.data}/>
                </div>
        </div>
    }
}

export default Task;

import React from 'react';
import {connect} from "react-redux";
import styles from './styles.module.scss';
import searchIconImage from 'assets/media/search_icon.svg';
import {
    doSearch,
    updateSearchQuery
} from "../../../../reducers/main";
import {withRouter} from "react-router";


@connect(
    state => ({
        query: state.main.query,
        selectedQuery: state.main.selectedQuery,
        searchMode: state.main.searchMode,
        forceOpenNodes: state.main.forceOpenNodes,
        dirs: (state.main.tree || {}).dirs || {}}),
    dispatch => ({
        dispatch,
        performSearch(props, force) {
            doSearch(dispatch, props, force);
        },
        updateQuery(query) {
            dispatch(updateSearchQuery(query));
        },
    })
)
class Search extends React.Component {

    componentDidMount() {
        this.props.performSearch(this.props, true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location !== this.props.location)
            this.props.performSearch(this.props);
    }

    handleEnterPress = (e) => {
        if (e.key === 'Enter') {
            this.doSearch();
        }
    };

    updateSearchQuery = (event) => {
        this.props.updateQuery({query: event.target.value});
    };

    cancelSearch = () => {
        this.props.updateLocationAndSearch(false);
    };

    doSearch = () => {
        this.props.updateLocationAndSearch(true);
    };

    render() {
        return <div className={styles.search}>
            <input className={styles.searchForm} placeholder='Начните набирать' value={this.props.selectedQuery.query || ''}
                   onChange={this.updateSearchQuery} onKeyPress={this.handleEnterPress}/>
            {this.props.searchMode && <div className={styles.searchReset} onClick={this.cancelSearch}/>}
            <div className={styles.searchIconImage}>
                <img src={searchIconImage} className={styles.searchIcon} alt='search_icon' onClick={this.doSearch}/>
            </div>
        </div>
    }
}

export default withRouter(Search);

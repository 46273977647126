import React from 'react';
import http from '../../utils/axios';
import {Redirect, withRouter} from 'react-router';
import {connect} from "react-redux";
import TemplateForm from "../templateForm";
import {loadTemplatePage} from "../../reducers/template";
import styles from './styles.module.scss'

const ERRORS = {
    404: "Такого шаблона не существует",
    403: "У вас нет доступа к этому шаблону",
};

const sheetTemplateCheatsheet = (
    <div>
        Используется язык шаблонов, описанный на <a href="https://golang.org/pkg/text/template">данной странице</a>.
        Доступные плейсхолдеры:
        <ul>
            <li>[[title]] - название листочка</li>
            <li>[[header]] - верхний колонтитул</li>
            <li>[[subtitle]] - подзаголовок</li>
            <li>[[cells]] - задачи, полученные из соответствующего шаблона</li>

            <li>[[with_solutions]] - бинарный флаг, соответствующий галочке "С решениями" на странице подборки</li>
        </ul>
    </div>
)

const cellTemplateCheatsheet = (
    <div>
        Используется язык шаблонов, описанный на <a href="https://golang.org/pkg/text/template">данной странице</a>.
        Доступные плейсхолдеры:
        <ul>
            <li>[[title]] - название задачи</li>
            <li>[[text]] - условие задачи</li>
            <li>[[solution]] - решение задачи</li>
            <li>[[images]] - картинки из условия</li>
            <li>[[solution_images]] - картинки из решения</li>
            <li>[[grade_low]] - класс, "от". "?", если отсутсвует</li>
            <li>[[grade_high]] - класс, "до". "?", если отсутсвует</li>
            <li>[[difficulty_low]] - сложность, "от". "?", если отсутсвует</li>
            <li>[[difficulty_high]] - сложность, "до". "?", если отсутсвует</li>
            <li>[[author]] - автор задачи</li>
            <li>[[labels]] - список тегов, перечисленных через запятую</li>
            <li>[[labels_list]] - список тегов в формате <i>слайса</i> (подробнее на странице с описанием языка)</li>
            <li>[[i]] - номер текущей задачи.</li>
            <li>[[with_solutions]] - бинарный флаг, соответствующий галочке "С решениями" на странице подборки</li>
        </ul>
    </div>
)

@connect(
    store => ({
        ...store.template,
        isLogged: store.auth.authorized
    }),
    dispatch => ({
        dispatch,
        loadTemplate(id, type) {
            loadTemplatePage(dispatch, id, type)
        }
    })
)

class Template extends React.Component {
    state = {
        error: {}
    }

    handleCreateClick = (formData) => {
        this.setState({error: {}})
        http.patch(`/templates/${this.props.type}/${this.props.id}`,
            {...formData})
            .catch((error) => {
                let errorStatus = error.response.status;
                if (errorStatus === 400) {
                    this.setState({error: error.response.data});
                }
            })
    }

    handleDeleteClick = () => {
        http.delete(`templates/${this.props.type}/${this.props.id}`)
            .then(() => {
                this.props.history.push(`/templates/${this.props.type}`);
            })
            .catch((error) => {
                console.log("Error deleting template: " + error)
            })
    }

    componentDidMount() {
        this.props.loadTemplate(this.props.id, this.props.type);
    }

    render() {
        if (this.props.isLogged === false) {
            return <Redirect to={'/login'}/>;
        }

        return (
            <div className={styles.mainWrapper}>
                <div className={styles.centralWrapper}>
                    {this.props.isLoading === false ?
                        this.props.error ? <p>{ERRORS[this.props.error] || "Ошибка загрузки шаблона"}</p> :
                            <TemplateForm onSubmit={this.handleCreateClick} initialState={{
                                text: this.props.data.text,
                                title: this.props.data.title,
                                preamble: this.props.data.preamble,
                                cell_template: this.props.data.cell_template,
                                raw: this.props.data.raw,
                            }} type={this.props.type}
                            error={this.state.error}/>
                        : <><p>Загрузка...</p></>}
                </div>
                <div className={styles.rightColumn}>
                    <div className={styles.deleteButton} onClick={this.handleDeleteClick}>
                        Удалить шаблон
                    </div>
                    {this.props.type === "sheet" ? sheetTemplateCheatsheet : cellTemplateCheatsheet}
                </div>
            </div>
        )
    }
}

export default withRouter(Template);

import React, {useEffect, useState} from "react";
import columnStyles from "../../columns.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {editContest, loadContest} from "../../../../reducers/contest";
import styles from './styles.module.scss';
import listStyles from "../../problemlist.module.scss";
import {Link, useHistory} from "react-router-dom";
import ContestProblem from "./ContestProblem";
import ContestStandings from "./ContestStandings";
import {useCountdown} from "../../../../utils/useCountdown";
import {ContestRestrictionsDialog, CreateEditContestDialog, PublishContestDialog} from "../dialogs";
import EditProblems from "./EditProblems";
import ContestSubmissions from "./ContestSubmissions";
import {MathText} from "../../../../utils/MathText";


const CONTEST_STATUS = {
    past: 'Соревнование завершено',
    present: 'Соревнование идет',
};

const ERRORS = {
    404: "Такого соревнования нет",
    403: "У вас нет доступа к этому соревнованию",
};

const RemainingCountdown = ({endTime, onZero}) => {
    const remaining = useCountdown(endTime, onZero);
    let seconds = Math.max(0, Math.floor(remaining));
    let minutes = Math.floor(seconds / 60);
    seconds %= 60;
    let hours = Math.floor(minutes / 60);
    minutes %= 60;
    let days = Math.floor(hours / 24);
    hours %= 24;
    return (days ? days + ' д. ' : '') + ((days || hours) ? hours + ' ч. ' : '') +
        ((days || hours || minutes) ? minutes + ' мин. ' : '') + (seconds + ' с.');
};

const ProblemList = ({problems, selectedNumber, urlPrefix, showStandings, showEdit}) => {
    return <div className={listStyles.neighborsContainer}>
        <p><b>Задачи:</b></p>
        <ul className={listStyles.neighborsList}>
            {problems.map(p => <li className={p.number === selectedNumber ? listStyles.selected : ""}>
                <Link to={urlPrefix + p.number}>{p.number}. {p.title} <b>({p.points !== undefined && (p.points || 0) + "/"}{p.max_points})</b></Link></li>)
        }
            {showStandings && <li className={listStyles.bordered + (selectedNumber === 'standings' ? ' ' + listStyles.selected : "")}>
                <Link to={urlPrefix + "standings"}>Положение</Link></li>}
            {showEdit && <li className={listStyles.bordered + (selectedNumber === 'edit' ? ' ' + listStyles.selected : "")}>
                <Link to={urlPrefix + "edit"}>Редактировать</Link></li>}
            {showEdit && <li className={listStyles.bordered + (selectedNumber === 'submissions' ? ' ' + listStyles.selected : "")}>
                <Link to={urlPrefix + "submissions"}>Посылки</Link></li>}
        </ul></div>
};

const ContestSettings = ({group, id, contest}) => {
    const dispatch = useDispatch();
    const [dialogShown, setDialogShown] = useState(null);
    return <div className={styles.settings}>
        <p><a href="" className={styles.settingsLink} onClick={e => {
            e.preventDefault();
            setDialogShown("edit");
        }}>Редактировать</a></p>
        <p><a href="" className={styles.settingsLink} onClick={e => {
            e.preventDefault();
            setDialogShown("restrictions");
        }}>Ограничения</a></p>
        <p><a href="" className={styles.settingsLink} onClick={e => {
            e.preventDefault();
            if (contest.visible) {
                editContest(dispatch, group, id, {visible: false});
            } else {
                setDialogShown("publish");
            }
        }}>{contest.visible ? "Скрыть" : "Опубликовать"}</a></p>
        <br/>
        {dialogShown === "publish" && <PublishContestDialog groupId={group} contestId={id}
                                                            onClose={() => setDialogShown(null)}/>}
        {dialogShown === "edit" && <CreateEditContestDialog groupId={group} contestId={id} defaults={contest}
                                                            onClose={() => setDialogShown(null)}/>}
        {dialogShown === "restrictions" && <ContestRestrictionsDialog groupId={group} contestId={id}
                                                                      onClose={() => setDialogShown(null)}/> }
    </div>;
};

const ContestDescription = ({contest}) => {
    if (contest.status === "future" && !contest.description) {
        return <p>Соревнование еще не началось</p>;
    }
    return <p><MathText text={contest.description} splitBy={'\n'}/></p>
};

const ContestView = ({match}) => {
    const dispatch = useDispatch();
    const reloadContest = () => {
        loadContest(dispatch, match.params.group, match.params.contest);
    };
    const reloadContestDelayed = () => {
        setTimeout(reloadContest, 1000);
    };
    useEffect(reloadContest, [match.params.group, match.params.contest]);
    const contest = useSelector(state => state.contest.contest);
    const history = useHistory();
    const urlPrefix = `/contests/${match.params.group}/${match.params.contest}/`;
    useEffect(() => {
        if (match.params.group === contest.group && match.params.contest === contest.id && !match.params.problem &&
            contest.problems && contest.problems.length && !contest.description) {
            history.replace(urlPrefix + contest.problems[0].number);
        }
    }, [match.params, contest]);
    return <div className={columnStyles.mainWrapper}>
            <div className={columnStyles.leftColumn}>
                {contest.problems &&
                <ProblemList problems={contest.problems}
                             selectedNumber={(match.params.problem || '').match(/\d+/) ?
                                 Number(match.params.problem) :
                                 match.params.problem}
                             urlPrefix={urlPrefix}
                             showStandings={contest.standings_available && contest.status !== 'future'}
                             showEdit={!!contest.can_manage}/>}
            </div>
            <div className={columnStyles.centralWrapper}>
                {contest.error ? <p>{ERRORS[contest.error] || "Ошибка загрузки соревнования"}</p> :
                (contest.loading || match.params.group !== contest.group || match.params.contest !== contest.id) ? <p>Загрузка...</p> :
                !match.params.problem ? <ContestDescription contest={contest}/> :
                match.params.problem === 'standings' ? <ContestStandings group={match.params.group}
                                                                         contest={match.params.contest}/> :
                match.params.problem === 'edit' ? <EditProblems groupId={match.params.group}
                                                                contestId={match.params.contest}/> :
                match.params.problem === 'submissions' ? <ContestSubmissions group={match.params.group}
                                                                             contest={match.params.contest}/> :
                <ContestProblem {...match.params} allowedToSubmit={contest.can_submit}/>
                }
            </div>
            <div className={columnStyles.rightColumn}>
                <div className={styles.contestInfoContainer}>
                    {(!contest.loading && !contest.error) && <>
                    {contest.group_name && <p className={styles.contestGroup}>
                        <Link to={`/contests/${match.params.group}/`}>{contest.group_name}</Link>
                    </p> }
                    <p className={styles.contestTitle}>
                        {!!contest.description ?
                            <Link to={`/contests/${match.params.group}/${match.params.contest}/`}>{contest.name}</Link> :
                            contest.name}
                    </p>
                    <p className={styles.contestStatus}>{CONTEST_STATUS[contest.status]}</p>
                    {contest.status === 'future' && (
                        contest.can_submit === true ? <p>Ваше участие подтверждено организатором соревнования</p> :
                        contest.can_submit === false ? <p><u>Организатор соревнования не подтвердил ваше участие</u></p> : ""
                    )}
                    <br/>
                    <div className={styles.contestInfo}>
                    {!contest.visible && <p>Соревнование скрыто</p>}
                    <p><b>Начало: </b>{contest.start_time ? new Date(contest.start_time).toLocaleString() : <i>неизвестно</i>}</p>
                    <p><b>Длительность: </b>{contest.duration === null ? "не ограничена" : `${contest.duration} мин.`}</p>
                    {contest.status === 'present' && contest.end_time !== null &&
                    <p><br/><b>Осталось: </b> <RemainingCountdown endTime={contest.end_time} onZero={reloadContestDelayed}/></p>}
                    {contest.status === 'future' && contest.end_time !== null &&
                    <p><br/><b>До начала: </b> <RemainingCountdown endTime={contest.end_time} onZero={reloadContestDelayed}/></p>}
                    {!!contest.can_manage && <ContestSettings group={match.params.group} id={match.params.contest}
                                                            contest={contest}/>}
                    </div>
                    </>}
                </div>

            </div>
        </div>
};

export default ContestView;

import React from 'react';
import styles from './styles.module.scss';

class Username extends React.Component {
    render() {
        return <div className={styles.username}>
            {this.props.username}
        </div>
    }
}

export default Username;
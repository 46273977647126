import React from 'react';
import styles from './styles.module.scss';
import {FirmLogoIcon} from "./firmLogoIcon";
import {LoginButton} from "./loginButton";
import {connect} from "react-redux";
import {fetchCurrentUser, performLogout} from "../../reducers/auth";
import UserIndicator from "./userIndicator";
import SelectionDropdown from "./selectionDropdown";
import {Link} from "react-router-dom";

@connect(
    state => state.auth,
    dispatch => ({
        loadUser() {
            fetchCurrentUser(dispatch);
        },
        logout() {
            performLogout(dispatch);
        }
    })
)
class Header extends React.PureComponent {

    componentDidMount() {
        this.props.loadUser();
    }

    render() {
        return <span className={styles.header}>
            <FirmLogoIcon/>
            <div className={styles.headerButton}>
                <Link className={styles.headerLink} to="/contests/public/">Соревнования</Link>
            </div>
            <div className={styles.headerButton}>
                <SelectionDropdown/>
            </div>
            <div className={styles.userBar}>
                {this.props.username ?
                    <UserIndicator username={this.props.username} avatar={this.props.avatar} onLogout={this.props.logout}/> :
                    this.props.username === null ? <LoginButton/> : <div/>}
            </div>
        </span>
    }
}

export default Header;

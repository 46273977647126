import React from 'react';
import Header from "../../header";
import {Footer} from "../../footer";
import MainRouter from "../../../routes/";
import styles from './styles.module.scss';
import {AuthWatcher} from "./authWatcher";


export class Page extends React.PureComponent {
    render() {
        return <>
            <div className={styles.fullPageContainer}>
			    <Header/>
                <MainRouter/>
                <Footer/>
                <AuthWatcher/>
            </div>
        </>
    }
}

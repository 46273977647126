import http from "../utils/axios";
import {deleteFromSelection} from "./selectionBar";
import {uploadFile} from "../utils/uploadFile";
import {ON_USER_CHANGED} from "./auth";

const START_LOADING_PROBLEM_PAGE = 'task/START_LOADING_PROBLEM_PAGE';
const EXPECT_PROBLEM_SAME_DIR = 'task/EXPECT_PROBLEM_SAME_DIR';
const LOAD_PROBLEM_PAGE = 'task/LOAD_PROBLEM_PAGE';
const UPDATE_PROBLEM_PAGE = 'task/UPDATE_PROBLEM_PAGE';
const START_LOADING_COMMENTS = 'task/START_LOADING_COMMENTS';
const LOAD_COMMENTS = 'task/LOAD_COMMENTS';
const UPDATE_FILES = 'task/UPDATE_FILES';
const START_LOADING_SUGGESTIONS = 'task/START_LOADING_SUGGESTIONS';
const LOAD_SUGGESTIONS = 'task/LOAD_SUGGESTIONS';
const ADD_NEW_SUGGESTION = 'task/ADD_NEW_SUGESTION';


const transformProblemData = data => (
    {title: data.title, text: data.text, solution: data.solution, source: data.source, files: data.files,
     solutionFiles: data.solution_files, directory: data.directory, path: data.path, number: data.number,
     author: data.author, email: data.email, restricted: data.restricted, canAdmin: data.can_admin,
     canEdit: data.can_edit, canEditProblem: data.can_edit_problem, attributes:
        {gradeLow: data.grade_low, gradeHigh: data.grade_high, difficultyLow: data.difficulty_low, difficultyHigh: data.difficulty_high,
         year: data.year, authors: data.authors, sources: data.sources, labels: data.labels},
     mainRef: data.main_ref, suggestedBy: data.suggested_by, answer: data.answer, verdict: data.verdict,
     enums: data.enums || []});


export const clearNeighborCache = dispatch => dispatch({type: UPDATE_PROBLEM_PAGE, payload: {neighbors: undefined}});

export const loadProblemPage = (dispatch, id) => {
    dispatch({type: START_LOADING_PROBLEM_PAGE, payload: {id}});
    http.get('problems/' + id)
            .then(({data}) => {
                dispatch({type: LOAD_PROBLEM_PAGE, payload: transformProblemData(data)})
            })
            .catch(error => {
                dispatch({type: LOAD_PROBLEM_PAGE, payload: {error: error.response.status}})
            });
};

export const expectProblemSameDir = (dispatch, problem, dir) => {
    dispatch({type: EXPECT_PROBLEM_SAME_DIR, payload: {problem, dir}});
};

export const updateProblemPage = (dispatch, id, data, callback) => {
    return http.patch('problems/' + id, data)
            .then(({data}) => dispatch({type: UPDATE_PROBLEM_PAGE, payload: transformProblemData(data)}))
            .then(callback);
};

export const setProblemTags = (dispatch, id, tags) => {
    const data = {};
    for(const tag in tags) {
        if(tags[tag] !== undefined) {
            data[tag] = tags[tag].join(',');
        }
    }
    return http.post(`problems/${id}/setTags`, data)
        .then(({data}) => dispatch({type: UPDATE_PROBLEM_PAGE, payload: transformProblemData(data)}));
};

export const deleteProblem = (dispatch, id) => {
    return http.delete('/problems/' + id).then(() => {
        deleteFromSelection(dispatch, id);
        clearNeighborCache(dispatch);
    });
};

export const deleteProblemAttachment = (dispatch, id, file, isSolution) => {
    return http.post('/problems/' + id + '/deleteFile', {file, solution: isSolution}).then(({data}) => {
        dispatch({type: UPDATE_FILES, payload: data});
    });
};

export const uploadProblemAttachment = (dispatch, id, file, isSolution) => {
    return uploadFile(file).then(({data}) => {
        return http.post('/problems/' + id + '/addFile', {file: data.file, solution: isSolution});
    }).then(({data}) => {
        dispatch({type: UPDATE_FILES, payload: data});
    });
};

export const loadProblemComments = (dispatch, id, update=false) => {
    if (!update) {
        dispatch({type: START_LOADING_COMMENTS});
    }
    return http.get(`/problems/${id}/comments`).then(({data}) => {
        dispatch({type: LOAD_COMMENTS, payload: {comments: data}});
    }).catch(error => dispatch({type: LOAD_COMMENTS,
        payload: {comments: [], commentsError: error.response.status}}));
};

export const deleteProblemComment = (dispatch, id, problemId) => {
    return http.post(`/problems/${problemId}/deleteComment`, {id})
        .then(() => loadProblemComments(dispatch, problemId, true))
};

export const sendProblemComment = (dispatch, id, text, file) => {
    return http.post(`/problems/${id}/createComment`, {text, file})
        .then(() => loadProblemComments(dispatch, id, true));
};

export const editProblemComment = (dispatch, id, problemId, text, file) => {
    return http.post(`/problems/${problemId}/editComment`, {id, text, file})
        .then(() => loadProblemComments(dispatch, problemId, true));
};

export const loadProblemNeighbors = (dispatch, directory) => {
    return http.get(`/tree/${directory}/problems?short`)
        .then(({data}) => dispatch({type: UPDATE_PROBLEM_PAGE, payload: {neighbors:
                    {directory, problems: data.problems, canEdit: data.can_edit}}}));
};

export const loadAttributeSuggestions = (dispatch, problem) => {
    dispatch({type: START_LOADING_SUGGESTIONS});
    return http.get(`/problems/${problem}/attributeSuggestions`)
        .then(({data}) => dispatch({type: LOAD_SUGGESTIONS, payload: data}));
};

export const saveAttributeSuggestions = (dispatch, problem, data) => {
    return http.post(`/problems/${problem}/attributeSuggestions`, data)
        .then(({data}) => dispatch({type: LOAD_SUGGESTIONS, payload: data}));
};

export const addAttributeSuggestion = (dispatch, add) => {
    dispatch({type: ADD_NEW_SUGGESTION, payload: {add}});
};

export const deleteAttributeSuggestions = (dispatch, problem, author) => {
    return http.delete(`/problems/${problem}/attributeSuggestions`, {data: {author}})
        .then(({data}) => dispatch({type: LOAD_SUGGESTIONS, payload: data}));
};

const taskReducer = (state = {}, action) => {
    switch (action.type) {
        case START_LOADING_PROBLEM_PAGE:
            if (action.payload.id === state.problemSameDir) {
                return {
                    ...state,
                    isLoading: true,
                    problemSameDir: null,
                }
            }
            return {
                ...state,
                path: null,
                directory: null,
                isLoading: true,
                problemSameDir: null,
            };
        case EXPECT_PROBLEM_SAME_DIR:
            if (action.payload.dir === state.directory) {
                return {
                    ...state,
                    problemSameDir: action.payload.problem,
                }
            }
            return state;
        case LOAD_PROBLEM_PAGE:
            return {
                ...state,
                ...action.payload,
                isLoading: false,
                error: action.payload.error,
            };
        case UPDATE_PROBLEM_PAGE:
            return {
                ...state,
                ...action.payload,
            };
        case START_LOADING_COMMENTS:
            return {
                ...state,
                comments: null,
            };
        case LOAD_COMMENTS:
            return {
                ...state,
                comments: action.payload.comments,
                commentsError: action.payload.commentsError,
            };
        case UPDATE_FILES:
            return {
                ...state,
                files: action.payload.files,
                solutionFiles: action.payload.solution_files,
            };
        case START_LOADING_SUGGESTIONS:
            return {
                ...state,
                suggestions: {suggestions: []},
            };
        case LOAD_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    canAdd: action.payload.can_add,
                    suggestions: action.payload.suggestions
                },
            };
        case ADD_NEW_SUGGESTION:
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    adding: action.payload.add,
                }
            };
        case ON_USER_CHANGED:
            return {
                ...state,
                neighbors: null,
            };
        default:
            return state;
    }
};

export default taskReducer;

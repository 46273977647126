const DELETE_ITEM = 'selectionBar/DELETE_ITEM';
const ADD_ITEM = 'selectionBar/ADD_ITEM';
const ADD_MANY = 'selectionBar/ADD_MANY';
const MOVE_ITEM = 'selectionBar/MOVE_ITEM';
const SELECTION_KEY = "selection";


export const getInitialSelection = () => {
    const items = sessionStorage.getItem(SELECTION_KEY);
    if (items === null) {
        return {items: []};
    }
    return {items: JSON.parse(items)};
};

export const saveSelection = (selection) => {
    sessionStorage.setItem(SELECTION_KEY, JSON.stringify(selection.items));
};

    export const deleteFromSelection = (dispatch, id) => {
    dispatch({type: DELETE_ITEM, payload: {id}});
};

export const addToSelection = (dispatch, id, title) => {
    dispatch({type: ADD_ITEM, payload: {id, title}});
};

export const addManyToSelection = (dispatch, problems) => {
    dispatch({type: ADD_MANY, payload: {problems}});
};

export const moveSelectionItem = (dispatch, src, trg) => {
    dispatch({type: MOVE_ITEM, payload: {src, trg}});
};

export const selectionBarReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_ITEM:
            return {
                items: state.items.filter(x => x.id !== action.payload.id),
            };
        case ADD_ITEM:
            return {
                items: state.items.concat([action.payload]),
            };
        case ADD_MANY:
            const present = new Set(state.items.map(x => x.id));
            return {
                items: state.items.concat(action.payload.problems.filter(x => !present.has(x.id))),
            };
        case MOVE_ITEM:
            const moved = state.items[action.payload.src];
            let items;
            if (action.payload.src < action.payload.trg) {
                items = state.items.slice(0, action.payload.src)
                    .concat(state.items.slice(action.payload.src + 1, action.payload.trg + 1))
                    .concat([moved])
                    .concat(state.items.slice(action.payload.trg + 1));
            } else {
                items = state.items.slice(0, action.payload.trg)
                    .concat([moved])
                    .concat(state.items.slice(action.payload.trg, action.payload.src))
                    .concat(state.items.slice(action.payload.src + 1));
            }
            return {items};
        default:
            return state;
    }
};

import React from 'react';
import UserPhoto from './userPhoto';
import UserInfo from './userInfo';
import styles from './styles.module.scss';

import {connect} from "react-redux";
import {loadUserPage} from "../../../reducers/user";

@connect(
    state => ({...state.user, auth: state.auth}),
    dispatch => ({
        loadUser(username) {
            loadUserPage(dispatch, username);
        }
    })
)

class User extends React.Component {
    state = {
        isEditing: false,
    };

    componentDidMount() {
        this.props.loadUser(this.props.match.params.username);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.username !== this.props.match.params.username) {
            this.props.loadUser(this.props.match.params.username);
        }
    }

    render() {
        if (this.props.notFound) {
            return <div className={styles.wrapper}>
                <h2>Нет такого пользователя</h2>
            </div>;
        }
        if (this.props.isLoading) {
            return <div className={styles.wrapper}>
                <p>Загрузка...</p>
            </div>;
        }
        const isMe = this.props.match.params.username === this.props.auth.username;
        return <div className={styles.wrapper}>
            <UserPhoto isLoading={this.props.isLoading} avatar={this.props.profile.avatar} isMe={isMe}/>
            <UserInfo isLoading={this.props.isLoading} isEditing={this.state.isEditing} profile={{...this.props.profile, username: this.props.match.params.username}}/>
            <div className={styles.rightColumn}>
                {isMe && <>{this.state.isEditing
                    ? <div className={styles.topButton}
                           onClick={() => {
                               this.setState({isEditing: false});
                           }}>
                        Принять изменения
                    </div>
                    : <div className={styles.topButton}
                           onClick={() => {this.setState({isEditing: true})}}>
                        Редактировать профиль
                    </div>}

                <div className={styles.button} onClick={() => {this.props.history.push('/editPassword')}}>
                    Сменить пароль
                </div>
                <div className={styles.button} onClick={() => {this.props.history.push('/templates/sheet')}}>
                    Шаблоны листочков
                </div>
                <div className={styles.button} onClick={() => {this.props.history.push('/templates/cell')}}>
                    Шаблоны задач
                </div>
                </>}
            </div>
        </div>
    }
}

export default User;

import React from 'react';
import styles from './styles.module.scss';
import CopyIcon from 'assets/media/copy.svg';
import copy from 'copy-to-clipboard';

const CopyButton = ({small, text}) => {
    return <img src={CopyIcon} alt="копировать" title="Копировать текст"
                className={small ? styles.copyButtonSmall : styles.copyButton}
    onClick={() => copy(text)}/>
};

export default CopyButton;

import http from "../utils/axios";
import {timeIn} from "../utils/timeIn";
import {combineReducers} from "redux";
import {ON_USER_CHANGED} from "./auth";

const START_LOADING_CONTEST = 'contest/START_LOADING_CONTEST';
const LOAD_CONTEST = 'contest/LOAD_CONTEST';
const LOAD_CONTEST_PROBLEM = 'contest/LOAD_CONTEST_PROBLEM';
const ADD_SUBMISSION = 'contest/ADD_SUBMISSION';
const LOAD_GROUPS = 'contest/LOAD_GROUPS';
const UPDATE_GROUPS = 'contest/UPDATE_GROUPS';
const START_LOADING_GROUP = 'contest/START_LOADING_GROUP';
const LOAD_GROUP = 'contest/LOAD_GROUP';
const UPDATE_GROUP = 'contest/UPDATE_GROUP';

const contestPayload = (data, group, id) => ({...data, id, group, end_time: timeIn(data.remaining)});

export const loadContest = (dispatch, group, id, skipLoading) => {
    if (!skipLoading) {
        dispatch({type: START_LOADING_CONTEST});
    }
    return http.get(`contests/groups/${group}/contests/${id}/`)
        .then(({data}) => dispatch({type: LOAD_CONTEST, payload: contestPayload(data, group, id)}))
        .catch(error => dispatch({type: LOAD_CONTEST, payload: {id, group, error: error.response.status}}));
};

export const loadContestProblem = (dispatch, group, id, problem, isAuthenticated) => {
    const promises = [
        http.get(`contests/groups/${group}/contests/${id}/problems/${problem}/`)
            .then(({data}) => dispatch({type: LOAD_CONTEST_PROBLEM, payload: {number: problem, data}}))];
    if (isAuthenticated) {
        promises.push(
            http.get(`contests/groups/${group}/contests/${id}/problems/${problem}/submissions`)
            .then(({data}) => dispatch({type: LOAD_CONTEST_PROBLEM, payload:
                    {number: problem, data: {submissions: data.submissions,
                            nextSubmission: timeIn(data.next_submission_in)}}})));
    }
    return Promise.all(promises);
};

export const makeSubmission = (dispatch, group, id, problem, answer) => {
    return http.post(`contests/groups/${group}/contests/${id}/problems/${problem}/submit`, {text: answer})
        .then(({data}) => dispatch({type: ADD_SUBMISSION, payload: {submission: data.submission, number: problem,
            nextSubmission: timeIn(data.next_submission_in)}}));
};

export const loadGroups = (dispatch) => {
    return http.get('contests/groups/')
        .then(({data}) => dispatch({type: LOAD_GROUPS, payload: data}));
};

export const createGroup = (dispatch, name) => {
    return http.post('contests/groups/', {name})
        .then(({data}) => {
            dispatch({type: UPDATE_GROUPS, payload: {my: data.my}});
            return data.id;
        });
};

export const saveGroup = (dispatch, id, remove) => {
    return http.post(`contests/groups/${id}/save`, remove ? {remove: true} : {})
        .then(({data}) => dispatch({type: UPDATE_GROUPS, payload: {saved: data.saved}}));
};

export const editContest = (dispatch, group, id, data) => {
    return http.patch(`contests/groups/${group}/contests/${id}/`, data)
        .then(({data}) => dispatch({type: LOAD_CONTEST, payload: contestPayload(data, group, id)}));
};

export const addProblems = (dispatch, group, id, problems) => {
    return http.post(`contests/groups/${group}/contests/${id}/problems/add`, {problems})
        .then(({data}) => dispatch({type: LOAD_CONTEST, payload: contestPayload(data, group, id)}));
};

export const deleteProblem = (dispatch, group, id, number) => {
    return http.post(`contests/groups/${group}/contests/${id}/problems/${number}/delete`, {})
        .then(({data}) => dispatch({type: LOAD_CONTEST, payload: contestPayload(data, group, id)}));
};

export const loadGroup = (dispatch, group) => {
    dispatch({type: START_LOADING_GROUP});
    return http.get(`contests/groups/${group}/`)
        .then(({data}) => dispatch({type: LOAD_GROUP, payload: data}))
        .catch(error => dispatch({type: LOAD_GROUP, data: {error: error.response.status}}));
};

export const renameGroup = (dispatch, group, name) => {
    return http.patch(`contests/groups/${group}/`, {name})
        .then(({data}) => {
            loadGroups(dispatch);
            dispatch({type: UPDATE_GROUP, payload: {name: data.name}})
        });
};

const contestReducer = (state = {}, action) => {
    switch (action.type) {
        case START_LOADING_CONTEST:
            return {
                loading: true,
            };
        case LOAD_CONTEST:
            return {
                ...action.payload,
                loading: false,
            };
        case LOAD_CONTEST_PROBLEM:
            if (!state.problems) {
                return state;
            }
            return {
                ...state,
                problems: state.problems.map(x =>
                    x.number === action.payload.number ? {...x, ...action.payload.data} : x),
            };
        case ADD_SUBMISSION:
            return {
                ...state,
                problems: state.problems.map(x =>
                    x.number === action.payload.number ?
                        {...x, submissions: [action.payload.submission, ...x.submissions],
                            nextSubmission: action.payload.nextSubmission,
                            points: Math.max(x.points || 0, action.payload.submission.points || 0),
                        }
                        : x),
            };
        default:
            return state;
    }
};

const defaultGroups = {my: [], saved: []};

const groupsReducer = (state = defaultGroups, action) => {
    switch (action.type) {
        case LOAD_GROUPS:
            return {
                ...action.payload,
                loaded: true,
            };
        case UPDATE_GROUPS:
            return {
                ...state,
                ...action.payload,
            };
        case ON_USER_CHANGED:
            return defaultGroups;
        default:
            return state;
    }
};


const groupReducer = (state = {}, action) => {
    switch (action.type) {
        case START_LOADING_GROUP:
            return {
                loading: true,
            };
        case LOAD_GROUP:
            return action.payload;
        case UPDATE_GROUP:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({
    contest: contestReducer,
    groups: groupsReducer,
    group: groupReducer,
});

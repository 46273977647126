import React, {memo} from 'react';
import * as MathJax from "@nteract/mathjax";
import TextWithLinks from "../TextWithLinks";

export const MathText = memo(({text, splitBy}) => {
    const chunks = (text || '').split(splitBy || '\n\n');
    return chunks.map((item, key) => {
        return <MathJax.Text key={Math.random()}><TextWithLinks>{item}</TextWithLinks>{key + 1 !== chunks.length && <br/>}</MathJax.Text>
    });
});

import React from 'react';
import Task from '../task';
import styles from './styles.module.scss';

export class TaskList extends React.Component {
	render() {
		const data = this.props.data;
		const taskList = data.map(task_data =>
			<Task key={task_data.id} id={task_data.id} data={task_data}/>
		);
		return <div className={styles.taskListContainer}>
			{taskList}
		</div>
	}
}

import React, {useCallback, useState} from "react";
import styles from './styles.module.scss';
import tagStyles from '../../../tags.module.scss';
import {connect, useSelector} from "react-redux";
import editIconImage from 'assets/media/edit_icon.svg';
import confirmEditingIconImage from "../../../../../assets/media/confirm_editing_icon.svg";
import rejectEditingIconImage from "../../../../../assets/media/reject_editing_icon.svg";
import {
    addAttributeSuggestion, deleteAttributeSuggestions,
    loadAttributeSuggestions,
    saveAttributeSuggestions,
    setProblemTags,
    updateProblemPage
} from "../../../../../reducers/task";
import {ListEditor} from "../../../../listEditor";
import {LabelAutosuggest} from "../../../../labelAutosuggest";
import http from "../../../../../utils/axios";
import trashCanImage from "../../../../../assets/media/trash_can.svg";
import UserCard from "../../../../userCard";


const TaskAttribute = ({name, objects}) => {
    return <>
        <p className={styles.attrName}>{name}</p>
        <div className={tagStyles.tagList}>{objects.map(text => <span className={tagStyles.tag}>{text}</span>)}</div>
    </>;
};

const TaskAttributeSmall = ({name, data}) => {
    return <p className={styles.attrCompact}><span className={styles.attrName}>{name}:</span> {data}</p>
};

const TaskAttributeSmallList = ({name, objects}) => {
    return <p className={styles.attrCompact + ' ' + tagStyles.tagList}><span className={styles.attrNameInline}>{name}: </span>
        {objects.map(text => <span className={tagStyles.tag + ' ' + tagStyles.small}>{text}</span>)}
        </p>
};

const prettifyValue = (value, min_value, max_value) => {
    if (value === "") {
        return value;
    }
    if (value < min_value) {
        value = min_value;
    }
    while (value > max_value) {
        value = Math.floor(value / 10);
    }
    return value;
};

const GradeEditor = ({gradeLow, gradeHigh, onChange, error}) => {
    return <div className={styles.gradeEditor}>
        От <input type={"number"} min={1} max={15} value={gradeLow || ""} onChange={(event) => {
            onChange({gradeLow: prettifyValue(event.target.value, 1, 15)});
        }}/>
        до <input type={"number"} min={1} max={15} value={gradeHigh || ""} onChange={(event) => {
            onChange({gradeHigh: prettifyValue(event.target.value, 1, 15)});
        }}/>
        <ErrorDisplay errors={error.grade_low}/>
        <ErrorDisplay errors={error.grade_high}/>
    </div>
};

const DifficultyEditor = ({difficultyLow, difficultyHigh, onChange, error}) => {
    return <div className={styles.gradeEditor}>
        От <input type={"number"} min={1} max={10} value={difficultyLow || ""} onChange={(event) => {
            onChange({difficultyLow: prettifyValue(event.target.value, 1, 10)});
        }}/>
        до <input type={"number"} min={1} max={10} value={difficultyHigh || ""} onChange={(event) => {
            onChange({difficultyHigh: prettifyValue(event.target.value, 1, 10)});
        }}/>
        <ErrorDisplay errors={error.difficulty_low}/>
        <ErrorDisplay errors={error.difficulty_high}/>
    </div>
};

const ErrorDisplay = ({errors}) => {
    return errors ? <small className={styles.validationError}><br/>{errors[0]}</small> : null;
};

function range(a, b) {
    let arr = [];
    for(let i = 0; i < b - a + 1; ++i) {
        arr.push(i + a);
    }
    return arr;
}

const ProblemLabelAutosuggest = ({inputProps}) => {
    const [suggestions, setSuggestions] = useState([]);
    const path = useSelector(state => state.task.path);
    const dir = (path && path.length) ? path[0].id : null;
    const clearSuggestions = useCallback(() => setSuggestions([]), []);
    const fetchSuggestions = useCallback(value => {
        if (!dir) {
            return;
        }
        http.get(`tree/${dir}/tags?prefix=${encodeURIComponent(value.value)}`)
            .then(({data}) => setSuggestions(data));
    }, [dir]);
    return <LabelAutosuggest inputProps={inputProps}
                             suggestions={suggestions}
                             clearRequested={clearSuggestions}
                             fetchRequested={fetchSuggestions}/>
};


const ATTRIBUTES = [
    {
        singular: 'Класс',
        plural: 'Классы',
        displayList: ({gradeLow, gradeHigh}) => {
            if (!gradeLow || !gradeHigh) {
                return [];
            }
            return range(gradeLow, gradeHigh).map(x => x + ' класс');
        },
        displayEditor: (data, onChange, error) => {
            return <GradeEditor gradeLow={data.gradeLow} gradeHigh={data.gradeHigh}
                                onChange={onChange} error={error}/>
        },
        displayString: ({gradeLow, gradeHigh}) => {
            if (!gradeLow || !gradeHigh) {
                return null;
            }
            if (gradeLow === gradeHigh) {
                return gradeLow;
            }
            return <>{gradeLow} &mdash; {gradeHigh}</>;
        },
        showInCompact: true,
    },
    {
        singular: 'Сложность',
        plural: 'Сложность',
        displayList: ({difficultyLow, difficultyHigh}) => {
            if (!difficultyLow || !difficultyHigh) {
                return [];
            }
            return range(difficultyLow, difficultyHigh);
        },
        displayEditor: (data, onChange, error) => {
            return <DifficultyEditor difficultyLow={data.difficultyLow} difficultyHigh={data.difficultyHigh}
                                     onChange={onChange} error={error}/>
        },
        displayString: ({difficultyLow, difficultyHigh}) => {
            if (!difficultyLow || !difficultyHigh) {
                return null;
            }
            if (difficultyLow === difficultyHigh) {
                return difficultyLow;
            }
            return <>{difficultyLow} &mdash; {difficultyHigh}</>;
        },
        showInCompact: true,
    },
    {
        singular: 'Год',
        plural: 'Год',
        displayList: ({year}) => year ? [year] : [],
        displayEditor: (data, onChange, error) => {
            return <>
                <input type={"number"} min={1600} max={2100} value={data.year}
                       onChange={(event) => onChange({year: prettifyValue(event.target.value, 1, 2100)})}/>
                <ErrorDisplay errors={error.year}/>
            </>
        },
        displayString: ({year}) => year,
    },
    {
        singular: 'Метки',
        plural: 'Метки',
        displayList: ({labels}) => labels || [],
        displayEditor: (data, onChange) => {
            const labels = data.labels || [];
            return <ListEditor objects={labels}
                        onDelete={(tag) => onChange({labels: labels.filter(x => x !== tag)})}
                        onAdd={(tag) => onChange({labels: labels.concat([tag])})}
                        renderInput={props => <ProblemLabelAutosuggest inputProps={props}/>}/>
        },
        showInCompact: true,
    },
];

const defaultNull = (s) => s === "" ? null : s;


const TaskAttributesDisplay = ({header, canEdit, isLoading, attrs, compact, onApplyChanges, enums,
                                   onlyEdit, onCancelEdit, canDelete, onDelete}) => {
    const [editing, setEditing] = useState(!!onlyEdit);
    const [error, setError] = useState({});
    const [changes, setChanges] = useState({});
    const newAttrs = {...attrs, ...extractEnums(changes.labels || attrs.labels || [], enums || [])};
    const saveChanges = useCallback(() => {
        setError({});
        onApplyChanges(changes)
            .then(() => {setEditing(false); setChanges({})})
            .catch(e => setError(e.response.data));
    }, [changes]);
    const rollbackChanges = useCallback(() => {
        setEditing(false);
        setChanges({});
        setError({});
        if (onCancelEdit) {
            onCancelEdit();
        }
    }, []);
    const startEditing = useCallback(() => {
        setEditing(true);
    }, []);
    const editEnum = (group, value) => {
        let newLabels = (changes.labels || attrs.labels || []).filter(x => {
                const curGroup = newAttrs.enums.find(e => e.group.name === group);
                if (!curGroup) {
                    return true;
                }
                return !curGroup.group.tags.map(t => curGroup.group.name + "." + t).includes(x);
            });
        if(value) {
            newLabels = newLabels.concat(value);
        }
        setChanges({...changes, labels: newLabels});
    };
    const enumAttributes = (newAttrs.enums || []).map(e => ({
        singular: e.group.name,
        plural: e.group.name,
        displayList: () => e.value ? [e.value] : [],
        displayEditor: () =>
            <select value={e.value} onChange={ev => editEnum(e.group.name, ev.target.value)}>
                <option value="">-</option>
                {e.group.tags.map(x => <option value={e.group.name + "." + x}>{x}</option>)}
            </select>,
        showInCompact: true,
    }));
    let manualEnumAttributes = [];
    if (!editing) {
        const {enums, labels} = extractManualEnums(newAttrs.labels);
        newAttrs.labels = labels;
        manualEnumAttributes = enums.map(e => ({
            singular: e.name,
            plural: e.name,
            displayList: () => e.values,
            showInCompact: true,
        }));
    }
    const setChangesWithEnums = changes => {
        const newChanges = changes.labels ?
            {...changes, labels: changes.labels.concat(newAttrs.enums.map(x => x.value).filter(x => x))} :
            changes;
        setChanges(newChanges);
    };
    return <div className={styles.taskAttributes}>
            <h4>
            {header}
            {canEdit && (editing ?
                <><img src={confirmEditingIconImage} className={styles.editIcon}
                         onClick={saveChanges} alt='Сохранить'/>
                 <img src={rejectEditingIconImage} className={styles.editIcon}
                         onClick={rollbackChanges} alt='Отменить'/></>
                :<img src={editIconImage} className={styles.editIcon}
                         onClick={startEditing} alt='Редактировать'/>)}
            {canDelete && !editing && <img src={trashCanImage} title="Удалить" alt="Удалить"
                                                className={styles.editIcon + ' ' + styles.deleteIcon}
                                                height={16} onClick={onDelete}/>}
            </h4>
            {isLoading ? <p>Загрузка...</p> : ATTRIBUTES.concat(enumAttributes).concat(manualEnumAttributes).map(item => {
                if (editing) {
                    if (compact && !item.showInCompact) {
                        return null;
                    }
                    return <>
                        <p className={styles.attrName}>{item.plural}</p>
                        {item.displayEditor(({...newAttrs, ...changes, labels: newAttrs.labels}),
                            data => setChangesWithEnums({...changes, ...data}),
                            error)}
                    </>
                } else if (compact && item.displayString) {
                    const str = item.displayString(newAttrs);
                    return str && <TaskAttributeSmall key={item.singular} name={item.plural} data={str}/>
                } else {
                    const arr = item.displayList(newAttrs);
                    return arr.length === 0 ? null : (compact ?
                        <TaskAttributeSmallList key={item.singular} name={arr.length === 1 ? item.singular : item.plural} objects={arr}/> :
                        <TaskAttribute key={item.singular} name={arr.length === 1 ? item.singular : item.plural} objects={arr}/>
                    );
                }
            })}
        </div>;
};

const EMPTY = {};

const extractManualEnums = (labels) => {
    const enums = {};
    const newLabels = [];
    for(const l of labels) {
        if (l.includes('.')) {
            const group = l.split(".")[0];
            if (!enums[group]) {
                enums[group] = [];
            }
            enums[group].push(l);
        } else {
            newLabels.push(l);
        }
    }
    return {labels: newLabels, enums: Object.entries(enums).map(x => ({name: x[0], values: x[1]}))};
}

const extractEnums = (labels, enums) => {
    const newLabels = [];
    const newEnums = {};
    for(const l of labels) {
        let used = false;
        for(const e of enums) {
            if(e.tags.map(x => e.name + "." + x).includes(l)) {
                used = true;
                if(newEnums[e.name]) {
                    newEnums[e.name].group.tags = [...new Set(newEnums[e.name].group.tags.concat(e.tags))];
                } else {
                    newEnums[e.name] = {group: e, value: l};
                }
                break;
            }
        }
        if(!used) {
            newLabels.push(l);
        }
    }
    for(const e of enums) {
        if(newEnums[e.name]) {
            newEnums[e.name].group.tags = [...new Set(newEnums[e.name].group.tags.concat(e.tags))];
        } else {
            newEnums[e.name] = {group: e, value: ""};
        }
    }
    return {labels: newLabels, enums: Object.values(newEnums).sort(
        (a, b) => a.group.name < b.group.name ? -1 : a.group.name > b.group.name ? 1 : 0)};
};

const transformAttributes = data => (
    {labels: data.labels, gradeLow: data.grade_low, gradeHigh: data.grade_high,
        difficultyLow: data.difficulty_low, difficultyHigh: data.difficulty_high});

@connect(
    state => ({...state.task, username: state.auth.username}),
)
class TaskAttributes extends React.Component {

    componentDidMount() {
        loadAttributeSuggestions(this.props.dispatch, this.props.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id !== this.props.id) {
            loadAttributeSuggestions(this.props.dispatch, this.props.id);
        }
    }

    applyChangesMain = (changes) => {
        const update = {grade_low: defaultNull(changes.gradeLow),
                        grade_high: defaultNull(changes.gradeHigh),
                        difficulty_low: defaultNull(changes.difficultyLow),
                        difficulty_high: defaultNull(changes.difficultyHigh),
                        year: defaultNull(changes.year)
        };
        return updateProblemPage(this.props.dispatch, this.props.id, update).then((resp) => {
            if (changes.labels) {
                return setProblemTags(this.props.dispatch, this.props.id, {labels: changes.labels});
            }
            return resp;
        });
    };

    applyChangesSuggested = changes => {
        const update = {grade_low: defaultNull(changes.gradeLow),
                        grade_high: defaultNull(changes.gradeHigh),
                        difficulty_low: defaultNull(changes.difficultyLow),
                        difficulty_high: defaultNull(changes.difficultyHigh),
                        labels: changes.labels,
        };
        return saveAttributeSuggestions(this.props.dispatch, this.props.id, update);
    };

    addSuggestion = () => {
        addAttributeSuggestion(this.props.dispatch, true);
    };

    cancelAddingSuggestion = () => {
        addAttributeSuggestion(this.props.dispatch, false);
    };

    deleteSuggestion = author => {
        deleteAttributeSuggestions(this.props.dispatch, this.props.id, author);
    };

    render() {
        return <><TaskAttributesDisplay
            header={"Информация"}
            canEdit={this.props.canEditProblem}
            isLoading={this.props.isLoading}
            attrs={this.props.attributes || EMPTY}
            enums={this.props.enums}
            onApplyChanges={this.applyChangesMain}/>

            {this.props.suggestions && this.props.suggestions.suggestions.map(s => {
                return <TaskAttributesDisplay
                    header={<UserCard data={s.author}/>}
                    canEdit={this.props.username && this.props.username === s.author.username}
                    compact={true}
                    attrs={transformAttributes(s)}
                    enums={this.props.enums}
                    onApplyChanges={this.applyChangesSuggested}
                    canDelete={this.props.canEditProblem || (this.props.username && this.props.username === s.author.username)}
                    onDelete={() => this.deleteSuggestion(s.author.username)}
                    />
            })}
            {this.props.suggestions && this.props.suggestions.canAdd && !this.props.suggestions.adding &&
                <button className={styles.suggestButton} onClick={this.addSuggestion}>Предложить атрибуты</button>}
            {this.props.suggestions && this.props.suggestions.adding &&
                <TaskAttributesDisplay
                    header={null}
                    canEdit={true}
                    compact={true}
                    enums={this.props.enums}
                    attrs={EMPTY}
                    onApplyChanges={this.applyChangesSuggested}
                    onlyEdit={true}
                    onCancelEdit={this.cancelAddingSuggestion}
                    />
            }
        </>
    }
}

export default TaskAttributes;

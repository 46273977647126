import http from "../utils/axios";

const START_LOADING_TEMPLATE_PAGE = "template/START_LOADING_TEMPLATE_PAGE"
const LOAD_TEMPLATE_PAGE = "template/LOAD_TEMPLATE_PAGE"

export const loadTemplatePage = (dispatch, id, type) => {
    dispatch({type: START_LOADING_TEMPLATE_PAGE, payload: {id}})
    http.get(`templates/${type}/${id}`)
        .then(({data}) => {
            dispatch({type: LOAD_TEMPLATE_PAGE, payload: data})
        })
        .catch(error => {
            dispatch({type: LOAD_TEMPLATE_PAGE, payload: {error: error.response.status}})
        });
}

const templateReducer = (state = {}, action) => {
    switch (action.type) {
        case START_LOADING_TEMPLATE_PAGE:
            return {
                ...state,
                isLoading: true,
            }
        case LOAD_TEMPLATE_PAGE:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }
        default:
            return state
    }
}

export default templateReducer;
import React from 'react';
import styles from './styles.module.scss';
import TextWithLinks from "../../../../../utils/TextWithLinks";


class InfoItem extends React.Component {
    render() {
        return <div className={styles.wrapper}>
            <div className={styles.key}>
                {this.props.itemKey}:
            </div>
            <div className={styles.value}>
                <TextWithLinks>
                {this.props.itemValue}
                </TextWithLinks>
            </div>
        </div>
    }
}

export default InfoItem;

import React from 'react';
import styles from '../../template/styles.module.scss';
import http from '../../../utils/axios';
import {Redirect, withRouter} from 'react-router';
import {connect} from "react-redux";
import TemplateForm from "../../templateForm";

const cellTemplateCheatsheet = (
    <div>
        Используется язык шаблонов, описанный на <a href="https://golang.org/pkg/text/template">данной странице</a>.
        Доступные плейсхолдеры:
        <ul>
            <li>[[title]] - название задачи</li>
            <li>[[text]] - условие задачи</li>
            <li>[[solution]] - решение задачи</li>
            <li>[[images]] - картинки из условия</li>
            <li>[[solution_images]] - картинки из решения</li>
            <li>[[grade_low]] - класс, "от". "?", если отсутсвует</li>
            <li>[[grade_high]] - класс, "до". "?", если отсутсвует</li>
            <li>[[difficulty_low]] - сложность, "от". "?", если отсутсвует</li>
            <li>[[difficulty_high]] - сложность, "до". "?", если отсутсвует</li>
            <li>[[author]] - автор задачи</li>
            <li>[[labels]] - список тегов, перечисленных через запятую</li>
            <li>[[labels_list]] - список тегов в формате <i>слайса</i> (подробнее на странице с описанием языка)</li>
            <li>[[i]] - номер текущей задачи.</li>
            <li>[[with_solutions]] - бинарный флаг, соответствующий галочке "С решениями" на странице подборки</li>
        </ul>
    </div>
)

@connect(
    store => ({isLogged: store.auth.authorized})
)

class CreateSheetTemplate extends React.Component {
    state = {
        error: {}
    }

    handleCreateClick = (formData) => {
        this.setState({error: {}})
        http.post(`/templates/cell`,
            {...formData})
            .then(({data}) => {
                this.props.history.push('/templates/cell/' + data['id']);
            })
            .catch((error) => {
                let errorStatus = error.response.status;
                if (errorStatus === 400) {
                    this.setState({error: error.response.data});
                }
            })
    };

    render() {
        // const isAuthenticated = useSelector(state => state.auth.authorized);
        if (this.props.isLogged === false) {
            return <Redirect to={'/login'}/>;
        }

        return (
            <div className={styles.mainWrapper}>
                <div className={styles.centralWrapper}>
                    <TemplateForm onSubmit={this.handleCreateClick} type={'cell'} error={this.state.error}/>
                </div>
                <div className={styles.rightColumn}>
                    {cellTemplateCheatsheet}
                </div>
            </div>);
    }
}

export default withRouter(CreateSheetTemplate);

import React, {useState} from "react";
import styles from './styles.module.scss';
import {connect} from "react-redux";
import {
    deleteProblemComment,
    editProblemComment,
    loadProblemComments,
    sendProblemComment
} from "../../../../reducers/task";
import userEmptyIconImage from "../../../../assets/media/user_empty_icon.png";
import editIconImage from 'assets/media/edit_icon.svg';
import {Link} from "react-router-dom";
import {MathText} from "../../../../utils/MathText";
import TextAreaAutoSize from 'react-textarea-autosize';
import AttachmentList from "../taskContent/attachmentList";
import {uploadFile} from "../../../../utils/uploadFile";
import CopyButton from "../../../copyButton";


const SPOILER_RE = /\[spoiler](.*?)\[\/spoiler]/gs;

const Spoiler = ({children}) => {
    const [shown, setShown] = useState(false);
    return <div className={styles.spoiler}>
        {shown ? children : <span className={styles.spoilerAlert} onClick={() => setShown(true)}>[Показать спойлер]</span>}
    </div>
};

const extractSpoilers = (text, renderText) => {
    const result = [];
    let pos = 0;
    let match;
    while((match = SPOILER_RE.exec(text))) {
        const prefix = text.substring(pos, match.index);
        if (prefix !== "") {
            result.push(renderText(prefix));
        }
        result.push(<Spoiler>{renderText(match[1])}</Spoiler>);
        pos = match.index + match[0].length;
    }
    const suffix = text.substring(pos);
    if (suffix !== "") {
        result.push(renderText(suffix));
    }
    return result;
};

const Comment = ({author, time, text, file, canDelete, onDelete, canEdit, onEdit}) => {
    const [editing, setEditing] = useState(false);
    if (author === null) {
        return <div className={styles.comment}>
        <img src={userEmptyIconImage} className={styles.avatar} width={64} height={64} alt={""}/>
        <div className={styles.commentTextContainer}><div className={styles.commentHeader}>
            Аноним
            <span><span className={styles.commentTime}>{new Date(time).toLocaleString()}</span>
                {canDelete && <span className={styles.deleteComment} onClick={onDelete} title={"Удалить комментарий"}>✕</span>}
            </span>
        </div>
            <div className={styles.commentText}><div className={styles.commentContent}>
                {extractSpoilers(text, text => <MathText text={text} splitBy={'\n'}/>)}
            </div><CopyButton small={true} text={text}/></div>
            {!!file && <AttachmentList files={[file]} canEdit={false}/>}
        </div>
    </div>
    }
    return <div className={styles.comment}>
        <Link to={'/user/' + author.username}><img src={author.avatar_small || userEmptyIconImage} className={styles.avatar} width={64} height={64} alt={""}/></Link>
        <div className={styles.commentTextContainer}><div className={styles.commentHeader}>
            <Link to={'/user/' + author.username}>{author.first_name} {author.last_name}</Link>
            <span><span className={styles.commentTime}>{new Date(time).toLocaleString()}</span>
                {canEdit && <img className={styles.editComment} title="Редактировать комментарий" alt="Редактировать" src={editIconImage}
                    onClick={() => setEditing(!editing)}/>}
                {canDelete && <span className={editing ? styles.deleteCommentHidden : styles.deleteComment} onClick={onDelete} title={"Удалить комментарий"}>✕</span>}
            </span>
        </div>
            {editing ?
                <CommentField initialText={text} initialFiles={file ? [file] : []} editMode={true} onSend={(text, files) => {
                    return onEdit(text, files).then(() => setEditing(false));
                }}/>
                :
                <>
                <div className={styles.commentText}><div className={styles.commentContent}>
                    {extractSpoilers(text, text => <MathText text={text} splitBy={'\n'}/>)}
                </div><CopyButton small={true} text={text}/></div>
                {!!file && <AttachmentList files={[file]} canEdit={false}/>}
                </>
            }
        </div>
    </div>
};

class CommentField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {text: props.initialText || '', files: props.initialFiles || [], somethingSelected: false};
        this.textareaRef = React.createRef();
    }

    handleClick = () => {
        if (this.state.text.trim() || this.state.files.length) {
            this.props.onSend(this.state.text, this.state.files).then(() => {
                if (!this.props.editMode) {
                    this.setState({text: "", files: []});
                }
            });
        }
    };

    handleCtrlEnter = (event) => {
        if (event.key === 'Enter' && event.ctrlKey) {
            this.handleClick();
        }
    };

    deleteAttachment = () => {
        this.setState({files: []});
    };

    uploadFile = file => {
        uploadFile(file).then(({data}) => this.setState({files: [data]}));
    };

    changeSelection = e => {
        this.setState({somethingSelected: e.target.selectionStart !== e.target.selectionEnd});
    };

    markAsSpoiler = e => {
        const textarea = this.textareaRef.current;
        const selection = '[spoiler]' + textarea.value.substring(textarea.selectionStart, textarea.selectionEnd) + '[/spoiler]';
        textarea.focus();
        document.execCommand("insertText", false, selection);
    };

    render() {
        return <div className={styles.commentField}>
            <div className={styles.commentAttachments}>
            <button disabled={!this.state.somethingSelected} className={styles.commentSpoiler}
                    title="Выделите текст, чтобы пометить его как спойлер" onClick={this.markAsSpoiler}>Пометить как спойлер</button>
            <AttachmentList files={this.state.files}
                            maxFiles={1}
                            canEdit={true}
                            onDelete={this.deleteAttachment}
                            onUpload={this.uploadFile}/>
            </div>
            <TextAreaAutoSize value={this.state.text} onChange={(event) => {this.setState({text: event.target.value})}}
            onKeyPress={this.handleCtrlEnter} onSelect={this.changeSelection} inputRef={this.textareaRef}/>
            <button className={styles.sendComment} onClick={this.handleClick}>{this.props.editMode ? "Сохранить" : "Отправить комментарий"}</button>
        </div>
    }
}

@connect(
    state => ({comments: state.task.comments, commentsError: state.task.commentsError,
        canWrite: state.auth.authorized || state.task.restricted}),
    dispatch => ({
        loadComments(id, update=false) {
            loadProblemComments(dispatch, id, update);
        },
        deleteComment(id, problemId) {
            deleteProblemComment(dispatch, id, problemId);
        },
        sendComment(id, text, files) {
            return sendProblemComment(dispatch, id, text, files.length ? files[0].file : null);
        },
        editComment(id, problemId, text, files) {
            return editProblemComment(dispatch, id, problemId, text, files.length ? files[0].file : null);
        }
    })
)
class TaskComments extends React.Component {

    render() {
        if (this.props.commentsError) {
            return null;
        }
        return <div className={styles.commentsSection}>
            <p><b>Комментарии</b></p>
            {this.props.canWrite && <CommentField onSend={(text, files) => this.props.sendComment(this.props.id, text, files)}/>}
            {this.props.comments === null ? <p>Загрузка комментариев...</p> :
                this.props.comments.map(comment => <Comment key={comment.id} text={comment.text} file={comment.file} author={comment.author}
                                                            time={comment.time} canDelete={comment.can_delete} canEdit = {comment.can_edit}
                                                            onDelete={() => this.props.deleteComment(comment.id, this.props.id)}
                                                            onEdit={(text, files) => this.props.editComment(comment.id, this.props.id, text, files)}
                />)}
        </div>
    }

    componentDidMount() {
        this.props.loadComments(this.props.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id !== this.props.id) {
            this.props.loadComments(this.props.id);
        }
    }

}

export default TaskComments;

import React, {useRef} from 'react';
import {withRouter} from "react-router";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {moveSelectionItem} from "../../../reducers/selectionBar";
import http from '../../../utils/axios';
import {Checkbox} from '@material-ui/core';
import {Link} from "react-router-dom";
import AddToSelectionButton from "../../addToSelectionButton";
import ReorderIcon from 'assets/media/reorder.svg';
import {DndProvider, useDrag, useDrop} from "react-dnd";
import Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import {useDispatch} from "react-redux";
import Select from "react-select"
import {loadDefaultTemplateList, loadTemplateList} from "../../../reducers/templateList";
import templateStyles from "../../templateForm/styles.module.scss";
import chroma from "chroma-js"

const firstItem = s => s.length ? s[0] : null;

const isTouchDevice = 'ontouchstart' in document.documentElement;

const SheetTask = ({id, title, error, index}) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const [{isDragging}, drag, preview] = useDrag({
        item: {type: 'TASK', id, index},
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });
    preview(ref);
    const [, drop] = useDrop({
        accept: 'TASK',
        hover(item, monitor) {
            if (!ref.current || id === item.id) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (item.index < index && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (item.index > index && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveSelectionItem(dispatch, item.index, index);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = index
        },
    });
    drop(ref);
    return <div
        className={(error && (error.id === id) ? styles.sheetErrorTask : styles.sheetTask) + (isDragging ? ' ' + (isTouchDevice ? styles.taskDraggingTouch : styles.taskDragging) : '')}
        ref={ref}>
        <div className={styles.sheetTaskTitleRow}>
            <img src={ReorderIcon} className={styles.taskReorder} alt="&#8597;" ref={drag}/>
            <Link to={'/problem/' + id}>{title}</Link>
            <AddToSelectionButton id={id} title={title}/><br/>
        </div>
        {error && (error.id === id) ?
            <div className={styles.compilationError}>
                Ошибка в строке {error.line}: {error.message}<br/>
                Сниппет: {error.snippet}
            </div>
            : ''}
    </div>
};

const SheetTaskList = ({items, error}) => {
    return <div>
        <DndProvider backend={isTouchDevice ? TouchBackend : Backend}>
            {items.map((item, index) => <SheetTask key={item.id} id={item.id} title={item.title} error={error}
                                                   index={index}/>)}
        </DndProvider>
    </div>
};


@connect(
    store => ({...store.selectionBar, isLogged: store.auth.authorized, ...store.templateList}),
    dispatch => ({
        loadTemplates(type) {
            loadTemplateList(dispatch, type)
        },
        loadDefaultTemplates(type) {
            loadDefaultTemplateList(dispatch, type)
        },
    })
)
class ProblemSheet extends React.Component {
    state = {
        solutions: false,
        problemInfo: false,
        useTemplates: false,
        header: '',
        title: '',
        subtitle: '',
        pending: false,
        generating: false,
        compiling: false,
        generationFailed: false,
        compilationFailed: false,
        completed: false,
        pdf: null,
        tex: null,
        zip: null,
        log: null,
        sheetTemplate: null,
        cellTemplate: null,
    };

    resetStatuses = (action) => {
        this.setState({
            pending: false, generating: false, compiling: false,
            generationFailed: false, compilationFailed: false, completed: false
        }, action);
    };

    saveResponse = (url, type, name, format) => {
        http.get(url, {responseType: 'arraybuffer'})
            .then((response) => {
                let file = new Blob([response.data], {
                    type: type,
                });
                let fileURL = URL.createObjectURL(file);
                let a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = name;
                document.body.appendChild(a);
                let params = {};
                params[format] = a;
                this.setState(params);
            })
            .catch((error) => {
                console.log('Error during saving response:', error);
            })
    };

    getCompilationError = (id) => {
        http.get('/sheets/get-error/' + id)
            .then((rsp) => {
                let error = {};
                Object.assign(error, rsp.data.error);
                if (error) {
                    if (error.cell_index >= 0) {
                        error.id = this.props.items[error.cell_index].id;
                    }
                }
                this.setState({error: error});
            })
    };

    checkComplilationStatus = (id) => {
        http.get('/sheets/check/' + id)
            .then((data) => {
                if (data.data.state === 'completed') {
                    this.saveResponse('/sheets/dl/' + id + '/pdf', 'application/pdf', 'sheet.pdf', 'pdf');
                    this.saveResponse('/sheets/dl/' + id + '/tex', 'text/plain', 'sheet.tex', 'tex');
                    this.saveResponse('/sheets/dl/' + id + '/zip', 'application/zip', 'sheet.zip', 'zip');
                    this.saveResponse('/sheets/dl/' + id + '/log', 'text/plain', 'sheet.txt', 'log');
                    this.resetStatuses(() => {
                        this.setState({completed: true})
                    });
                } else if (data.data.state === 'compilation_failed') {
                    this.saveResponse('/sheets/dl/' + id + '/tex', 'text/plain', 'sheet.tex', 'tex');
                    this.saveResponse('/sheets/dl/' + id + '/zip', 'application/zip', 'sheet.zip', 'zip');
                    this.saveResponse('/sheets/dl/' + id + '/log', 'text/plain', 'sheet.txt', 'log');
                    this.getCompilationError(id);
                    this.resetStatuses(() => {
                        this.setState({compilationFailed: true})
                    });
                } else if (data.data.state === 'generation_failed') {
                    this.resetStatuses(() => {
                        this.setState({generationFailed: true})
                    });
                } else if (data.data.state === 'compiling') {
                    this.resetStatuses(() => {
                        this.setState({compiling: true})
                    });
                    let self = this;
                    setTimeout(function () {
                        self.checkComplilationStatus(id)
                    }, 1000);
                } else if (data.data.state === 'generating') {
                    this.resetStatuses(() => {
                        this.setState({generating: true})
                    });
                    let self = this;
                    setTimeout(function () {
                        self.checkComplilationStatus(id)
                    }, 1000);
                } else {
                    let self = this;
                    setTimeout(function () {
                        self.checkComplilationStatus(id)
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log('Error in check:', error);
            })
    };

    sendCompileRequest = () => {
        const uuids = this.props.items.map((item) => {
            return item['id']
        });
        this.setState({
            pdf: null,
            tex: null,
            zip: null,
            log: null
        })
        http.post('/sheets/create', {
            header: this.state.header, title: this.state.title,
            title2: this.state.subtitle, uuids: uuids,
            solutions: this.state.solutions,
            problem_info: this.state.problemInfo,
            sheet_template: this.state.sheetTemplate,
            cell_template: this.state.cellTemplate,
        })
            .then(({data}) => {
                this.checkComplilationStatus(data['id']);
            })
            .catch((error) => {
                console.log('Error during sending compile request:', error);
            })
    };

    onFieldChange = (field, checked) => event => {
        let data = {};
        data[field] = checked ? !!event.target.checked : event.target.value;
        this.setState(data);
    };

    onUseTemplatesChange = event => {
        this.setState({
            useTemplates: !!event.target.checked,
            sheetTemplate: null,
            cellTemplate: null,
        })
    }


    currentCellTemplateValues = () => {
        return this.props.cells.filter(cell => cell.id === this.state.cellTemplate)
    }

    onSheetTemplateSelect = (value) => {
        const item = value ? value.value : null
        this.setState({
            sheetTemplate: item ? {
                id: item.id,
                default_template_name: item.default_template_name ? item.default_template_name : null,
            } : null,
            cellTemplate: item ? item.cell_template ? {id: item.cell_template, default_template_name: null} : null : null,
        })
    }

    onCellTemplateSelect = (value) => {
        const item = value ? value.value : null

        this.setState({
            cellTemplate: item ? {
                id: item.id,
                default_template_name: item.default_template_name ? item.default_template_name : null,
            } : null,
        })
    }

    handleClickCompileButton = () => {
        this.resetStatuses(() => {
            this.setState({pending: true}, () => {
                this.sendCompileRequest()
            })
        });
    };

    handleClickDownloadButton = (format) => () => {
        if (this.state[format]) {
            this.state[format].click();
        }
    };

    componentDidMount() {
        this.props.loadTemplates('sheet')
        this.props.loadTemplates('cell')
        this.props.loadDefaultTemplates('sheet')
        this.props.loadDefaultTemplates('cell')
    }

    dropdownItemRenderer = (type) => ({ item, itemIndex, props, state, methods }) => {
        return <div className={state.values.filter(selected => selected.id === item.id).length === 1
                        ? templateStyles.dropdownItemSelected
                        : templateStyles.dropdownItem}
                    onClick={() => {
                        methods.clearAll()
                        methods.addItem(item)
                    }}
        >
            <Link to={`/templates/${type}/${item.id}`}>{item.title}</Link>
        </div>
    }

    selectColorStyles = {
        option: (styles, {isFocused, isSelected}) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? chroma(143, 251, 107).alpha(0.7).css()
                    : isFocused
                    ? chroma(143, 251, 107).alpha(0.2).css()
                    : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: chroma(143, 251, 107).alpha(0.4).css(),
                },
                color: '#000'
            }
        }
    }

    formatOptionLabel = type => ({value, label}) => {
        return value.id ? (
            <Link to={`/templates/${type}/${value.id}`}>{label}</Link>
        ) : (
            <div>{label}</div>
        )
    }

    buildSheetSelector = () => {
        console.log('Building sheet selector. State: ', this.state)
        const sheetOptions = [
            {
                label: "Пользовательские",
                options: this.props.sheets.map(item => ({label: item.title, value: item}))
            },
            {
                label: "Встроенные",
                options: this.props.defaultSheets.map(item => ({label: item.title, value: item}))
            },
        ]

        return (<Select options={sheetOptions}
                        isClearable={true}
                        onChange={(value) => this.onSheetTemplateSelect(value)}
                        formatOptionLabel={this.formatOptionLabel('sheet')}
                        styles={this.selectColorStyles}
                        placeholder="Выберите шаблон..."
        />)
    }

    buildCellSelector = () => {
        console.log('Building cell selector. State: ', this.state)
        const cellOptions = [
            {
                label: "Встроенные",
                options: this.props.defaultCells.map(item => ({label: item.title, value: item}))
            },
            {
                label: "Пользовательские",
                options: this.props.cells.map(item => ({label: item.title, value: item}))
            },
        ]

        let value = null

        if (this.state.cellTemplate) {
            if (this.state.cellTemplate.id) {
                value = firstItem(cellOptions[1].options.filter(item => item.value.id === this.state.cellTemplate.id))
            } else {
                value = firstItem(cellOptions[0].options.filter(
                    item => item.value.default_template_name === this.state.cellTemplate.default_template_name
                ))
            }
        }
        console.log('Building cell selector. State: ', this.state, '\nValue: ', value)

        return (<Select options={cellOptions}
                        isClearable={true}
                        onChange={(value) => this.onCellTemplateSelect(value)}
                        formatOptionLabel={this.formatOptionLabel('cell')}
                        styles={this.selectColorStyles}
                        placeholder="Выберите шаблон..."
                        value={value}
        />)
    }

    render() {
        console.log('State: ', this.state)

        return <div className={styles.mainWrapper}>
            <div className={styles.centralWrapper}>
                <input className={styles.inputField} placeholder={'Верхний колонтитул'}
                       onChange={this.onFieldChange('header')}/>
                <input className={styles.inputField} placeholder={'Заголовок'}
                       onChange={this.onFieldChange('title')}/>
                <input className={styles.inputField} placeholder={'Подзаголовок'}
                       onChange={this.onFieldChange('subtitle')}/>
                <label>
                    <Checkbox onChange={this.onFieldChange('solutions', true)} checked={this.state.solutions}/>
                    Добавить решения
                </label><br/>
                <label>
                    <Checkbox onChange={this.onFieldChange('problemInfo', true)} checked={this.state.problemInfo}/>
                    Добавить информацию о задачах
                </label><br/>
                {this.props.isLogged &&
                <label>
                    <Checkbox onChange={this.onUseTemplatesChange} checked={this.state.useTemplates}/>
                    Использовать свои шаблоны
                </label>}
                {this.state.useTemplates && this.props.isLogged &&
                 this.props.areSheetsLoading === false &&
                 this.props.areCellsLoading === false &&
                 this.props.areDefaultSheetsLoading === false &&
                 this.props.areDefaultCellsLoading === false &&
                    <div>
                        Основной шаблон:
                        {this.buildSheetSelector()}
                        <br/>
                        Шаблон задач:
                        {this.buildCellSelector()}
                        <br/>
                    </div>}
                <SheetTaskList items={this.props.items} error={this.state.error}/>
            </div>
            <div className={styles.rightColumn}>
                {this.state.pending ?
                    <div className={styles.dummyButton}>
                        В очереди...
                    </div>
                    : this.state.generating ?
                        <div className={styles.dummyButton}>
                            Генерируем...
                        </div>
                        : this.state.compiling ?
                            <div className={styles.dummyButton}>
                                Компилируем...
                            </div>
                            : <div className={styles.downloadButton} onClick={this.handleClickCompileButton}>
                                Скомпилировать
                            </div>
                }
                <div>
                    {this.state.completed ?
                        <div className={this.state.pdf ? styles.downloadButton : styles.dummyButton} onClick={this.handleClickDownloadButton('pdf')}>
                            Скачать PDF
                        </div>
                        : <div/>}
                    {this.state.completed || this.state.compilationFailed ?
                        <div>
                            <div className={this.state.tex ? styles.downloadButton : styles.dummyButton} onClick={this.handleClickDownloadButton('tex')}>
                                Скачать исходник (.tex)
                            </div>
                            <div className={this.state.zip ? styles.downloadButton : styles.dummyButton} onClick={this.handleClickDownloadButton('zip')}>
                                Скачать архив (.zip)
                            </div>
                            <div className={this.state.log ? styles.downloadButton : styles.dummyButton} onClick={this.handleClickDownloadButton('log')}>
                                Скачать лог
                            </div>
                        </div>
                        : <div/>}
                    {this.state.compilationFailed ?
                        <div className={styles.compilationErrorMessage}>
                            Ошибка компиляции!
                        </div>
                        : <div/>}
                </div>
            </div>
        </div>
    }
}


export default withRouter(ProblemSheet);

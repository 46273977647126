import React from 'react';
import http from '../../../utils/axios';
import {Redirect, withRouter} from 'react-router';
import {connect} from "react-redux";
import TemplateForm from "../../templateForm";
import styles from "../../template/styles.module.scss";

const sheetTemplateCheatsheet = (
    <div>
        Используется язык шаблонов, описанный на <a href="https://golang.org/pkg/text/template">данной странице</a>.
        Доступные плейсхолдеры:
        <ul>
            <li>[[title]] - название листочка</li>
            <li>[[header]] - верхний колонтитул</li>
            <li>[[subtitle]] - подзаголовок</li>
            <li>[[cells]] - задачи, полученные из соответствующего шаблона</li>

            <li>[[with_solutions]] - бинарный флаг, соответствующий галочке "С решениями" на странице подборки</li>
        </ul>
    </div>
)

@connect(
    store => ({isLogged: store.auth.authorized})
)

class CreateSheetTemplate extends React.Component {
    state = {
        error: {}
    }

    handleCreateClick = (formData) => {
        this.setState({error: {}})
        http.post(`/templates/sheet`,
            {...formData})
            .then(({data}) => {
                this.props.history.push('/templates/sheet/' + data['id']);
            })
            .catch((error) => {
                let errorStatus = error.response.status;
                if (errorStatus === 400) {
                    this.setState({error: error.response.data});
                }
            })
    };

    render() {
        // const isAuthenticated = useSelector(state => state.auth.authorized);
        if (this.props.isLogged === false) {
            return <Redirect to={'/login'}/>;
        }

        return (
            <div className={styles.mainWrapper}>
                <div className={styles.centralWrapper}>
                    <TemplateForm onSubmit={this.handleCreateClick} type={'sheet'} error={this.state.error}/>
                </div>
                <div className={styles.rightColumn}>
                    {sheetTemplateCheatsheet}
                </div>
            </div>);
    }
}

export default withRouter(CreateSheetTemplate);

import React from 'react';
import styles from './styles.module.scss';
import FileIcon from 'assets/media/file_icon.svg';
import PaperclipIcon from 'assets/media/paperclip_icon.svg';
import {useRef} from 'react';


const getName = s => s.split('/').pop();

const Attachment = ({file, canEdit, onDelete}) => <div className={styles.attachment}>
    {file.preview ?
        <>
            <a href={file.file} target="_blank" rel="noopener">
                <img src={file.preview} alt={getName(file.file)}/>
            </a>
            {canEdit && <span className={styles.deleteButtonImage} onClick={() => onDelete(file.file)}>✕</span>}
        </>
        :
        <div className={styles.attachmentFile}>
            <a href={file.file} download><img src={FileIcon} alt={""}/> {file.display_name}</a>
            {canEdit && <span className={styles.deleteButtonFile} onClick={() => onDelete(file.file)}>✕</span>}
        </div>
    }
</div>;

const AttachmentUpload = ({uploadFile}) => {
    const ref = useRef(null);
    return <>
        <img className={styles.addAttachment} src={PaperclipIcon} title="Добавить файл" alt="Добавить файл" onClick={() => ref.current.click()}/>
        <input type="file" hidden ref={ref} onChange={e => {
            if(e.target.files[0]) {
                uploadFile(e.target.files[0]);
                e.target.value = "";
            }
        }
        }/>
    </>;
};


const AttachmentList = ({files, canEdit, onDelete, onUpload, maxFiles}) => <div className={styles.attachmentList}>
    {(files.filter(x => x.preview).concat(files.filter(x => !x.preview)))
        .map(f => <Attachment key={f.file} file={f} canEdit={canEdit} onDelete={onDelete}/>)}
    {canEdit && (!maxFiles || files.length < maxFiles) && <AttachmentUpload uploadFile={onUpload}/>}
</div>;


export default AttachmentList;

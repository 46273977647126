import React from 'react';
import styles from "./styles.module.scss";
import {buildTree, isSelectionReachable} from "../../../tree";
import {connect} from "react-redux";
import {
    loadTree,
    updateTree
} from "../../../../reducers/main";
import {withRouter} from "react-router";
import {parseSearchParams} from "../../../../utils/searchParams";
import 'react-contexify/dist/ReactContexify.min.css';



@connect(
    state => ({selectedDirectory: state.main.selectedQuery.directory,
               query: state.main.query,
               tree: state.main.tree,
               searchMode: state.main.searchMode,
               authorized: state.auth.authorized}),
    dispatch => ({
        loadTree(dir) {
            loadTree(dispatch, dir);
        },
        updateTree(id) {
            updateTree(dispatch, id);
        },
    })
)
class DirectoryTree extends React.Component {

    state = {
        treeLoadedForDir: null,
    };

    componentDidMount() {
        if (this.props.tree === undefined) {
            this.loadTree(parseSearchParams(this.props.location).directory);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const dir = parseSearchParams(this.props.location).directory;
        if ((prevProps.tree !== undefined && this.props.tree === undefined) ||
            (dir !== this.state.treeLoadedForDir && !isSelectionReachable(this.props.tree || {}, this.treeState()))
        ) {
            this.loadTree(dir);
        }
    }

    loadTree = dir => {
        this.setState({treeLoadedForDir: dir});
        this.props.loadTree(dir);
    }

    treeState = () => ({selected: this.props.selectedDirectory, authorized: this.props.authorized});

    render() {
        return <div className={styles.treeWrapper}>
                {buildTree(this.props.tree || {}, this.treeState(), this.props.updateTree, this.props.selectDir)}
            </div>
    }
}

export default withRouter(DirectoryTree);

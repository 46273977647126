import React from "react";
import {withRouter} from "react-router";
import {Link} from 'react-router-dom';
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {loadTemplateList} from "../../../reducers/templateList";
import columnStyles from "../columns.module.scss";

class TemplateListEntry extends React.Component {
    render() {
        return <div className={styles.templateContainer}>
                <Link className={styles.templateContainer__title} to={`/templates/${this.props.type}/${this.props.id}`}>
                    {this.props.title}
                </Link>
        </div>
    }
}

@connect(
    store => ({isLogged: store.auth.authorized, ...store.templateList}),
    dispatch => ({
        loadTemplates(type) {
            loadTemplateList(dispatch, type)
        }
    })
)

class TemplateList extends React.Component {

    componentDidMount() {
        this.props.loadTemplates(this.props.type)
    }

    render() {
        const data = (this.props.type === 'sheet' ? this.props.sheets : this.props.cells)
        const isLoading = (this.props.type === 'sheet' ? this.props.areSheetsLoading : this.props.areCellsLoading)
        const onCreateClick = () => {
            this.props.type === 'sheet'
                ? this.props.history.push('/templates/createSheet')
                : this.props.history.push('/templates/createCell')
        }
        return (
            <div className={columnStyles.mainWrapper}>
                <div className={columnStyles.leftColumn}/>
                <div className={columnStyles.centralWrapper}>
                    {isLoading === false ?
                    <div className={styles.templateListContainer}>
                        {data.map(sheet => <TemplateListEntry type={this.props.type} {...sheet}/>)}
                    </div>
                    : <div>Загружаем шаблоны...</div>}
                </div>
                <div className={columnStyles.rightColumn}>
                    <div className={styles.topButton} onClick={onCreateClick}>
                        Создать новый шаблон
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(TemplateList);
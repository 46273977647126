import React from "react";

import LoginBox from './loginBox';
import RegisterBox from './registerBox';
// import BackButton from './backButton';
// import FadeTransition from './fadeTransition';

import styles from './styles.module.scss';
import {Link} from "react-router-dom";
import ForgotPasswordBox from "./forgotPasswordBox";
import RecoverPasswordBox from "./recoverPasswordBox";


const LoginRegisterForms = ({openPage, params}) => {
      return(
        <div className={styles.rootContainer}>
          {/* <BackButton /> */}
          <div className={styles.boxController}>
            <Link to={"/login"} className={styles.controller && (openPage === "login" ? styles.selectedController : "")}>
              Вход
            </Link>
            <Link to={"/register"} className={styles.controller && (openPage === "register" ? styles.selectedController : "")}>
              Регистрация
            </Link>
          </div>
          <div className={styles.boxContainer}>
            {openPage === "login" && <LoginBox/>}
            {openPage === "register" && <RegisterBox/>}
            {openPage === "forgotPassword" && <ForgotPasswordBox/>}
            {openPage === "recoverPassword" && <RecoverPasswordBox token={params.token}/>}
          </div>
        </div>
      );
};

export const LoginFormPage = () => (<LoginRegisterForms openPage="login"/>);
export const RegisterFormPage = () => (<LoginRegisterForms openPage="register"/>);
export const ForgotPasswordFormPage = () => (<LoginRegisterForms openPage="forgotPassword"/>);
export const RecoverPasswordFormPage = ({match}) => (<LoginRegisterForms openPage="recoverPassword" params={match.params}/>);

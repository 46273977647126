import React, {memo, useEffect, useState} from 'react';
import styles from './styles.module.scss';
import tableStyles from '../../table.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {loadContestProblem, makeSubmission} from "../../../../../reducers/contest";
import {MathText} from "../../../../../utils/MathText";
import {useCountdown} from "../../../../../utils/useCountdown";
import AttachmentList from "../../../task/taskContent/attachmentList";

const SubmissionList = memo(({submissions}) => {
    return <table className={tableStyles.table + ' ' + tableStyles.compact}>
            <thead><tr><th>Ответ</th><th>Баллы</th><th>Время</th></tr></thead>
            <tbody>
            {submissions.map(c => <tr key={c.number}>
                <td>{c.text}</td>
                <td>{c.points}</td>
                <td>{new Date(c.time).toLocaleString()}</td>
            </tr>)}</tbody>
    </table>
});

const SubmitBox = ({nextSubmission, onSubmit, disabled}) => {
    const remaining = useCountdown(nextSubmission);
    const [answer, setAnswer] = useState("");
    const [sending, setSending] = useState(false);
    const doSubmit = () => {
        setSending(true);
        onSubmit(answer)
            .then(() => {
                setAnswer("");
            })
            .finally(() => {
                setSending(false);
            })
    };
    let nextDiv = null;
    if (!disabled && remaining > 0) {
        const m = Math.floor(remaining / 60);
        const s = (remaining - 60 * m);
        const ss = '' + Math.floor(s / 10) + s % 10;
        nextDiv = <div className={styles.nextSubmission}>Следующая попытка через {m}:{ss}</div>;
    }
    const enterHandler = e => {
        if (e.key === 'Enter' && e.ctrlKey) {
            doSubmit();
        }
    }
    return <div><input type="text" value={answer} onChange={e => setAnswer(e.target.value)}
                              disabled={disabled} onKeyPress={enterHandler}/>
    <button className={styles.submitButton} disabled={!answer.trim() || disabled || sending || remaining > 0}
            onClick={doSubmit}>Отправить</button>
    {nextDiv}
    </div>;

};

const ProblemSubmissions = ({submissions, onSubmit, canSubmit, maxSubmissions, nextSubmission}) => {
    const remainingNumber = (!submissions || maxSubmissions < 0 || !canSubmit) ? null :
        Math.max(0, maxSubmissions - submissions.length);
    const remaining = remainingNumber !== null && ` (осталось попыток: ${remainingNumber})`;
    return <div className={styles.submitContainer}>
        {canSubmit && <SubmitBox nextSubmission={nextSubmission} onSubmit={onSubmit} disabled={remainingNumber === 0}/>}
        {submissions && (
            submissions.length > 0 ? <>
            <p className={styles.submitHeader}>Посылки{remaining}</p>
            <div><SubmissionList submissions={submissions}/></div>
        </> : <p className={styles.submitHeader}>Посылок нет{remaining}</p>)}
    </div>
};

const ContestProblem = ({group, contest, problem, allowedToSubmit}) => {
    const problemNumber = Number(problem);
    const canSubmit = useSelector(state => state.contest.contest.status === 'present');
    const problemData = useSelector(state => (state.contest.contest.problems || []).find(x => x.number === problemNumber));
    const isAuthenticated = useSelector(state => state.auth.authorized);
    const dispatch = useDispatch();
    useEffect(() => {
        if (problemData && problemData.text === undefined && problemData.submissions === undefined) {
            loadContestProblem(dispatch, group, contest, problemNumber, isAuthenticated);
        }
    }, [problemData]);
    if (!problemData) {
        return <p>Такой задачи нет</p>;
    }
    const handleSubmit = answer => makeSubmission(dispatch, group, contest, problemNumber, answer);
    return <>
        <h2 className={styles.problemTitle}>{problemData.title}</h2>
        {problemData.text === undefined ? <p>Загрузка...</p> : <>
            <MathText text={problemData.text}/>
            <AttachmentList files={problemData.files}/>
        </>}
        {allowedToSubmit === false ? <p className={styles.submitHeader}>Отправлять решения могут только подтвержденные участники</p> :
        problemData.submissions && <ProblemSubmissions submissions={problemData.submissions} onSubmit={handleSubmit}
                                                        canSubmit={canSubmit}
                                                        maxSubmissions={problemData.max_submissions}
                                                        nextSubmission={problemData.nextSubmission}/>}
    </>;
};

export default ContestProblem;

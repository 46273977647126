import axios from 'axios';

const http = axios.create({
    baseURL: '/api/',
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
});

export default http;

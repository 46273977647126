import React from 'react';
import {Redirect, Route, Switch} from 'react-router';


import Main from 'components/views/main';
import Task from 'components/views/task';
import User from 'components/views/user';
import {ForgotPasswordFormPage, LoginFormPage, RegisterFormPage} from 'components/views/loginFormPage';
import About from "../components/views/about";
import CreateProblem from "../components/views/createProblem";
import EditProfileForm from '../components/views/user/editPassword';
import DirectoryForm from '../components/views/directoryForm';
import ProblemSheet from "../components/views/problemSheet";
import {RecoverPasswordFormPage} from "../components/views/loginFormPage";
import ContestGroupView from "../components/views/contests/groupview";
import ContestView from "../components/views/contests/contestview";
import TaskPreview from "../components/views/taskPreview";
import CreateSheetTemplate from "../components/views/createSheetTemplate";
import CreateCellTemplate from "../components/views/createCellTemplate"
import {SheetTemplate, CellTemplate} from "../components/views/template"
import TemplateList from "../components/views/templateList"

const MainRouter = () => (
	<Switch>
		<Route exact path={'/'} component={() => <Redirect to={'/q/'}/>}/>
		<Route path='/q' component={Main}/>
		<Route exact path='/about' component={About}/>
		<Route path='/login' component={LoginFormPage}/>
		<Route exact path='/editPassword' component={EditProfileForm}/>
		<Route exact path='/user/:username' component={User}/>
		<Route path='/register' component={RegisterFormPage}/>
		<Route path='/forgotPassword' component={ForgotPasswordFormPage}/>
		<Route path='/restorePassword/:token' component={RecoverPasswordFormPage}/>
		<Route exact path='/problem/:id' component={Task}/>
		<Route path='/createProblem' component={CreateProblem}/>
		<Route path='/suggestProblem' component={() => <CreateProblem suggest={true}/>}/>
		<Route path='/form/:token' component={DirectoryForm}/>
		<Route path='/sheet' component={ProblemSheet}/>
		<Route path='/preview/:id/:token' component={TaskPreview}/>

		<Route exact path="/contests/" component={() => <Redirect to="/contests/public/"/>}/>
		<Route exact path="/contests/:group/" component={ContestGroupView}/>
		<Route exact path="/contests/:group/:contest/" component={ContestView}/>
		<Route exact path="/contests/:group/:contest/:problem/" component={ContestView}/>

		<Route exact path="/templates/createSheet/" component={CreateSheetTemplate}/>
		<Route path='/templates/sheet/:id/' component={SheetTemplate}/>
		<Route exact path='/templates/sheet/' component={() => <TemplateList type='sheet'/>}/>
		<Route exact path="/templates/createCell/" component={CreateCellTemplate}/>
		<Route path='/templates/cell/:id/' component={CellTemplate}/>
		<Route exact path='/templates/cell/' component={() => <TemplateList type='cell'/>}/>
	</Switch>
);

export default MainRouter;

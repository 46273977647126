import React from 'react';
import styles from "./styles.module.scss";
import tagStyles from "../views/tags.module.scss";

const defaultInput = (props) => <input {...props}/>;

export class ListEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {inputValue: ""}
    }

    doAddTag = (tag) => {
        if (!tag.trim())
            return;
        this.props.onAdd(tag);
        this.setState({inputValue: ""});
    };

    addTag = () => {
        this.doAddTag(this.state.inputValue);
    };

    handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            this.addTag();
        }
        if (event.key === ',') {
            event.preventDefault();
        }
    };

    handleChange = (event, data) => {
        if (data && (data.method === 'click' || data.method === 'enter')) {
            this.doAddTag(data.newValue);
        } else if(data) {
            this.setState({inputValue: data.newValue});
        } else {
            this.setState({inputValue: event.target.value});
        }
    };

    render() {
        return <><div className={tagStyles.tagList}>
            {this.props.objects.map(text => <span key={text} className={tagStyles.tag}>{text}
                <span className={styles.tagDelete} onClick={() => this.props.onDelete(text)}>✕</span>
            </span>)}
        </div>
        <div className={styles.inputWrapper}>
        {(this.props.renderInput || defaultInput)({value: this.state.inputValue, onKeyPress: this.handleEnterPress,
            onChange: this.handleChange})}
        <span className={styles.tagAdd} onClick={this.addTag}>+</span>
        </div>
        </>
    }
}

import React from 'react';
import styles from './styles.module.scss';
import ShowIconImage from 'assets/media/show_solution_icon.png';


class ShowSolution extends React.Component {
    render() {
        return <div className={styles.showSolution}>
            <img src={ShowIconImage} className={styles.showIcon} alt='show icon'/>
            Показать решение
        </div>
    }
}

export default ShowSolution;
import React, {memo} from 'react';
import ReactDOM from 'react-dom';
import * as MathJax from "@nteract/mathjax";
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import {Page} from 'components/views/page';
import {MATHJAX_OPTIONS} from "./utils/mathjaxOptions";
import {store} from "./store";
import {LastLocationProvider} from 'react-router-last-location';

const RootComponent = memo(() =>
	<Provider store={store}>
		<MathJax.Provider options={MATHJAX_OPTIONS}>
			<BrowserRouter>
				<LastLocationProvider>
					<Page/>
				</LastLocationProvider>
			</BrowserRouter>
		</MathJax.Provider>
	</Provider>);

const render = () =>
	ReactDOM.render(<RootComponent/>, document.getElementById('root'));

store.subscribe(render);
render();

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

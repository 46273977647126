import { createStore, combineReducers, applyMiddleware } from 'redux';
import main from './main';
import task from './task';
import auth from './auth';
import {getInitialSelection, selectionBarReducer, saveSelection} from "./selectionBar";
import user from "./user";
import contest from "./contest";
import template from "./template";
import templateList from "./templateList"

const logger = store => next => action => {
	let result;
	console.groupCollapsed("dispatching", action.type);
	console.log('prev state', store.getState());
	console.log('action', action);
	result = next(action);
	console.log('next state', store.getState());
	console.groupEnd();
	return result;
};

const saver = store => next => action => {
	const oldState = store.getState();
	let result = next(action);
	const newState = store.getState();
	if (oldState.selectionBar !== newState.selectionBar) {
		saveSelection(newState.selectionBar);
	}
	return result;
};

const initStorage = (initialState = {}) => {
	return {
		...initialState,
		selectionBar: getInitialSelection(),
		task: {
			selectedProblems: {},
			comments: null,
		}
	};
};

let middleware = [saver];

if(process.env.NODE_ENV !== 'production') {
	middleware = [logger, ...middleware];
}

export const storeFactory = (initialState = {}) => (
	applyMiddleware(...middleware)(createStore)(
		combineReducers({
			main: main,
			task: task,
			user: user,
			auth: auth,
			selectionBar: selectionBarReducer,
			contest: contest,
			template: template,
			templateList: templateList,
		}), initStorage(initialState)
	)
);

import styles from "../styles.module.scss";
import http from "utils/axios";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";

const RecoverPasswordBox = ({token}) => {
    const [tokenFine, setTokenFine] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const history = useHistory();
    const newPassword = password => {
        return http.post('auth/recoverPassword/new', {token, password});
    };
    const setNewPassword = e => {
        e.preventDefault();
        newPassword(password).then(() => history.push('/login'));
    };
    useEffect(() => {
        newPassword(null).then(() => setTokenFine(true))
            .catch(() => setTokenFine(false))
        },
        []);
    return <div className={styles.innerContainer}>
      <div className={styles.header}>
      Восстановление пароля
      </div>
        {tokenFine === null && <p>Загрузка...</p>}
        {tokenFine === false && <p>Эта ссылка недействительна</p>}
        {tokenFine === true &&
            <form className={styles.box}>
                <div className={styles.inputGroup}>
                    <label>Новый пароль</label>
                    <input type="password" name="password" value={password} className={styles.loginInput}
                           onChange={e => setPassword(e.target.value)}/>
                </div>
                <div className={styles.inputGroup}>
                    <label>Подтверждение пароля</label>
                    <input type="password" name="passwordRepeat" value={passwordRepeat} className={styles.loginInput}
                           onChange={e => setPasswordRepeat(e.target.value)}/>
                    {password !== passwordRepeat && <small className={styles.dangerError}>Пароли не совпадают</small>}
                </div>
                <button disabled={password !== passwordRepeat} type="submit" className={styles.loginBtn}
                        onClick={setNewPassword}>Установить пароль</button>
            </form>}
    </div>
};

export default RecoverPasswordBox;

import styles from "./styles.module.scss";
import userEmptyIconImage from "../../assets/media/user_empty_icon.png";
import {Link} from "react-router-dom";
import React from "react";

const UserCard = ({data}) =>
    <Link className={styles.userCard} to={`/user/${data.username}`}>
        <img height={24} src={data.avatar_small || userEmptyIconImage} alt=""/> {data.first_name} {data.last_name}
    </Link>;

export default UserCard;
